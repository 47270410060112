import { Button, PageHeader } from "antd"

import { AppContent } from "@shared/layout"
import React from "react"
import SmsForceTry from "../components/SmsForceTry"
import { useNavigate } from "react-router-dom"

const SmsForceSuccessPage = () => {
	const navigator = useNavigate()
	return (
		<AppContent>
			<PageHeader
				onBack={() => navigator(-1)}
				title="Sms Force Success"
				extra={[
					<Button
						type="primary"
						onClick={() => navigator(window.location.pathname)}>
						Refresh
					</Button>,
				]}
			/>
			<SmsForceTry />
		</AppContent>
	)
}

export default SmsForceSuccessPage
