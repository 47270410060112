import { Button, Form, Input, Space } from "antd"

import React from "react"

interface IInitialValue {
	email?: string
	password?: string
}

interface IFProps {
	onFinish: (values: IInitialValue) => void
	initialValues?: IInitialValue
	loading?: boolean
}
const AuthFrom: React.FC<IFProps> = ({ onFinish, initialValues, loading }) => {
	return (
		<Form
			name="basic"
			initialValues={initialValues}
			layout="vertical"
			onFinish={onFinish}>
			<Form.Item
				label="Email"
				name="email"
				rules={[{ required: true, message: "Please input your email!" }]}>
				<Input size="large" type="email" placeholder="Enter Your Email" />
			</Form.Item>
			<Form.Item
				label="Password"
				name="password"
				rules={[{ required: true, message: "Please input your password!" }]}>
				<Input.Password
					size="large"
					type="password"
					placeholder="Enter Your Password"
				/>
			</Form.Item>
			<Form.Item>
				<Space style={{ display: "flex", justifyContent: "space-between" }}>
					<Button loading={loading} type="primary" htmlType="submit">
						Login
					</Button>
				</Space>
			</Form.Item>
		</Form>
	)
}
export default AuthFrom
