import { Button, Popconfirm, Select, Space, Table } from "antd"
import React, { useState } from "react"
import { useDelete, useUsers } from "@shared/services"

import { ColumnsType } from "antd/lib/table"
import { DebounceSearchInputField } from "@shared/components"
import { UserType } from "@shared/enums"

interface IFProps {
	onSelectId?: (id: string) => void
}
const Users: React.FC<IFProps> = ({ onSelectId }) => {
	const [options, setOptions] = useState<any>({
		page: 1,
		take: 10,
		searchTerm: "",
		type: "ALL",
	})
	const usersQuery = useUsers({
		options: {
			type: options.type,
			page: options.page,
			take: options.take,
			searchTerm: options.searchTerm,
		},
		config: {
			keepPreviousData: true,
		},
	})

	const tableData: any = usersQuery?.data?.data?.data?.map(
		(x: any, idx: number) => ({
			key: idx,
			fullName: (
				<u
					className="cursor-pointer text-indigo-500 font-medium"
					onClick={() => onSelectId(x.id)}>
					{x?.userInfo?.name}
				</u>
			),
			number: x?.userInfo?.number,
			balance: Number(x?.balance).toFixed(2),
			actions: <UserTableActions user={x} />,
		})
	)

	return (
		<>
			<Space
				wrap
				style={{
					display: "flex",
					justifyContent: "space-between",
					marginBottom: 15,
				}}>
				<Space wrap>
					<div style={{ maxWidth: 260 }}>
						<DebounceSearchInputField
							onChange={(term: string) => {
								setOptions({
									...options,
									searchTerm: term,
								})
							}}
							placeholder="Search..."
						/>
					</div>
					<Select
						size="large"
						defaultValue="ALL"
						onChange={(type: string) => {
							setOptions({
								...options,
								type: type,
							})
						}}
						style={{ width: 120 }}>
						<Select.Option value="ALL">ALL</Select.Option>
						<Select.Option value={UserType.INDIVIDUAL}>
							{UserType.INDIVIDUAL}
						</Select.Option>
						<Select.Option value={UserType.ADMIN}>
							{UserType.ADMIN}
						</Select.Option>
					</Select>
				</Space>
			</Space>

			<Table
				size="large"
				scroll={{ x: 700 }}
				sticky
				loading={usersQuery?.isLoading}
				columns={columns}
				dataSource={tableData}
				pagination={{
					onChange: (page: number) => {
						setOptions({ ...options, page })
					},
					pageSize: usersQuery?.data?.data?.take,
					total: usersQuery?.data?.data?.total,
					showSizeChanger: false,
				}}
			/>
		</>
	)
}
export default Users
const columns: ColumnsType = [
	{
		title: "Full Name",
		dataIndex: "fullName",
	},
	{
		title: "Number",
		dataIndex: "number",
	},
	{
		title: "Balance",
		dataIndex: "balance",
	},
	{
		title: "Actions",
		dataIndex: "actions",
		align: "right",
	},
]

interface IFActionProps {
	user: any
}
const UserTableActions: React.FC<IFActionProps> = ({ user }) => {
	const userDeleteMutation = useDelete({})
	return (
		<Space>
			<Popconfirm
				title="Are you sure?"
				okText="Yes"
				cancelText="No"
				onConfirm={() => {
					userDeleteMutation.mutate(user.id)
				}}>
				<Button loading={userDeleteMutation.isLoading} type="primary" danger>
					Delete
				</Button>
			</Popconfirm>
		</Space>
	)
}
