import { Card, Col, PageHeader, Row } from "antd"
import { Link, useNavigate } from "react-router-dom"

import { AppContent } from "@shared/layout"
import { Paths } from "@shared/constant"
import React from "react"
import { commonGridGutters } from "@shared/utils/util-function"

const HelpAndSupportPage = () => {
	const navigator = useNavigate()

	return (
		<>
			<AppContent>
				<PageHeader onBack={() => navigator(-1)} title="Help And Support" />
				<Row gutter={commonGridGutters}>
					<Col xxl={8} xl={8} sm={12} md={12} xs={24}>
						<Link to={Paths.InstantMessage}>
							<Card
								headStyle={{ fontWeight: "bold", color: "#7366ff" }}
								style={{ minHeight: 200 }}
								title="Send your first SMS"
								bordered={true}>
								<p>
									Send and Receive text messages through a single connection for
									timely and cost efficient communications.
								</p>
							</Card>
						</Link>
					</Col>
					<Col xxl={8} xl={8} sm={12} md={12} xs={24}>
						<Link to={Paths.UsersMe}>
							<Card
								headStyle={{ fontWeight: "bold", color: "#7366ff" }}
								style={{ minHeight: 200 }}
								title="Update Your Profile"
								bordered={false}>
								<p>Manage Profile info from your preference</p>{" "}
							</Card>
						</Link>
					</Col>
					<Col xxl={8} xl={8} sm={12} md={12} xs={24}>
						<Link to={Paths.ReportList}>
							<Card
								headStyle={{ fontWeight: "bold", color: "#7366ff" }}
								style={{ minHeight: 200 }}
								title="Generate Report"
								bordered={false}>
								<p>
									Select an automatic time span, or set specific start and end
									dates.
								</p>
							</Card>
						</Link>
					</Col>
				</Row>
			</AppContent>
		</>
	)
}
export default HelpAndSupportPage
