import React, { useState } from "react"

import { Drawer } from "antd"
import { FiEye } from "react-icons/fi"
import IndividualTransInvoice from "./IndividualTransInvoice"

interface IFProps {
	info: any
}

const UserBalanceLog: React.FC<IFProps> = ({ info }) => {
	const [uiState, setUiState] = useState({
		invoiceOpen: false,
	})
	const { invoiceOpen } = uiState

	return (
		<>
			<div className="contact-tbl-actions">
				<button
					className="action-icon"
					onClick={() => setUiState({ ...uiState, invoiceOpen: true })}>
					<FiEye />
				</button>
			</div>
			<Drawer
				title="Basic Drawer"
				placement="right"
				width="600px"
				closable={false}
				onClose={() => setUiState({ ...uiState, invoiceOpen: false })}
				visible={invoiceOpen}>
				<IndividualTransInvoice data={info} />
			</Drawer>
			{/* <!--===== completed invoice popup area =====--> */}
		</>
	)
}

export default UserBalanceLog
