import { Button, Form, Input, Select, Switch } from "antd"

import { DebouncePrefixSelectField } from "@shared/components/DebouncePrefixSelectField"
import { IOperator } from "@shared/interfaces"
import React from "react"
import { useOperators } from "@shared/services"

interface IInitialValue {
	name: string
	operator: string
	originatingNumber: string
	username: string
	password: string
	apiKey: string
	status: boolean
	prefixes: string
}
interface IFProps {
	onFinish?: (val: IInitialValue) => void
	initialValue?: IInitialValue
	loading?: boolean
}
const GatewayFrom: React.FC<IFProps> = ({
	initialValue,
	onFinish,
	loading,
}) => {
	const operatorsQuery = useOperators({})
	return (
		<>
			<Form
				name="basic"
				initialValues={initialValue}
				layout="vertical"
				onFinish={onFinish}>
				<Form.Item
					label="Name"
					name="name"
					rules={[{ required: true, message: "Please input your name!" }]}>
					<Input size="large" type="text" placeholder="Enter Your name" />
				</Form.Item>
				<Form.Item
					label="Operator"
					name="operator"
					rules={[{ required: true, message: "Please input your operator!" }]}>
					<Select placeholder="Select Operator" size="large">
						{operatorsQuery?.data?.data?.data?.map((x: IOperator) => (
							<Select.Option value={x.name}>{x.name}</Select.Option>
						))}
					</Select>
				</Form.Item>
				<Form.Item
					label="OriginatingNumber"
					name="originatingNumber"
					rules={[
						{ required: true, message: "Please input your originatingNumber!" },
					]}>
					<Input
						size="large"
						type="number"
						placeholder="Enter Your Originating Number"
					/>
				</Form.Item>
				<Form.Item
					label="Username"
					name="username"
					rules={[{ required: true, message: "Please input your username!" }]}>
					<Input size="large" type="text" placeholder="Enter Your username" />
				</Form.Item>
				<Form.Item label="Password" name="password">
					<Input.Password
						size="large"
						type="text"
						placeholder="Enter Your password"
					/>
				</Form.Item>
				<Form.Item label="ApiKey" name="apiKey">
					<Input.Password
						size="large"
						type="text"
						placeholder="Enter Your apiKey"
					/>
				</Form.Item>
				<Form.Item
					label="Prefixes"
					name="prefixes"
					rules={[{ required: true, message: "Please input your prefixes!" }]}>
					<DebouncePrefixSelectField mode="multiple" />
				</Form.Item>
				<Form.Item label="status" name="status">
					<Switch />
				</Form.Item>
				<Form.Item>
					<Button loading={loading} type="primary" htmlType="submit">
						Submit
					</Button>
				</Form.Item>
			</Form>
		</>
	)
}
export default GatewayFrom
