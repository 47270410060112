import { CountDownCircle } from "@shared/components"
import { IMAGES } from "@shared/assets"
import React from "react"

const ComingSoonPage = () => {
	var d = new Date()
	var year = d.getFullYear()
	var month = d.getMonth()
	var day = d.getDate()

	var coundown =
		new Date(year, month, day + 10).getTime() > new Date().getTime()
			? new Date(year, month, day + 10).getTime()
			: new Date().getTime()

	return (
		<div
			style={{
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				paddingTop: 70,
			}}>
			<div style={{ textAlign: "center" }}>
				<img style={{ height: 40 }} src={IMAGES.MaLogo} alt="" />
				<h5>COMING SOON</h5>
				<CountDownCircle endTime={coundown} />
			</div>
		</div>
	)
}

export default ComingSoonPage
