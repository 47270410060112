import { IBaseQuery, ICreateSmsGateway } from "@shared/interfaces"
import { MutationConfig, QueryConfig, queryClient } from "@shared/config"
import { useMutation, useQuery } from "react-query"

import { CoreAxiosInstanceV2 } from "@shared/config/service/core.axios.v2"
import { concatQuery } from "@shared/utils"

const END_POINT = "/smsGateways/"
export interface IFilterWithSMSMask extends IBaseQuery {
	mask?: string
}
export interface IFilterWithUser extends IBaseQuery {
	user?: string
}
export const SmsGatewaysServiceV2 = {
	NAME: "SmsGatewaysService",
	filter(options: IBaseQuery) {
		return CoreAxiosInstanceV2.get(`${END_POINT}filter?${concatQuery(options)}`)
	},
	filterWithSMSMask(options: IFilterWithSMSMask) {
		return CoreAxiosInstanceV2.get(
			`${END_POINT}filterWithSmsMask?${concatQuery(options)}`
		)
	},
	filterWithUser(options: IFilterWithUser) {
		return CoreAxiosInstanceV2.get(
			`${END_POINT}filterWithSmsMask?${concatQuery(options)}`
		)
	},
	create(payload: ICreateSmsGateway) {
		return CoreAxiosInstanceV2.post(END_POINT, payload)
	},
	delete(id: string) {
		return CoreAxiosInstanceV2.delete(`${END_POINT}${id}`)
	},
	defaultSet(id: string) {
		return CoreAxiosInstanceV2.put(`${END_POINT}defaultSet/${id}`)
	},
	getDefaultGateway() {
		return CoreAxiosInstanceV2.get(`${END_POINT}getDefaultGateway`)
	},
}
interface SmsGateways {
	config?: QueryConfig<typeof SmsGatewaysServiceV2.filter>
	options?: IBaseQuery
}
export const useSmsGateways = ({ config, options }: SmsGateways = {}) => {
	const KEY = `${SmsGatewaysServiceV2.NAME}${SmsGatewaysServiceV2.filter.name}`
	return useQuery({
		...config,
		queryKey: [KEY, Object.values(options)],
		queryFn: () => SmsGatewaysServiceV2.filter(options),
	})
}
interface FilterWithSMSMask {
	config?: QueryConfig<typeof SmsGatewaysServiceV2.filterWithSMSMask>
	options?: IFilterWithSMSMask
}
export const useSmsGatewaysFilterWithSMSMask = ({
	config,
	options,
}: FilterWithSMSMask = {}) => {
	const KEY = `${SmsGatewaysServiceV2.NAME}${SmsGatewaysServiceV2.filterWithSMSMask.name}`
	return useQuery({
		...config,
		queryKey: [KEY, Object.values(options)],
		queryFn: () => SmsGatewaysServiceV2.filterWithSMSMask(options),
	})
}
interface FilterWithUser {
	config?: QueryConfig<typeof SmsGatewaysServiceV2.filterWithUser>
	options?: IFilterWithUser
}
export const useSmsGatewaysFilerWithUser = ({
	config,
	options,
}: FilterWithUser = {}) => {
	const KEY = `${SmsGatewaysServiceV2.NAME}${SmsGatewaysServiceV2.filterWithUser.name}`
	return useQuery({
		...config,
		queryKey: [KEY, Object.values(options)],
		queryFn: () => SmsGatewaysServiceV2.filterWithUser(options),
	})
}
interface CreateSmsGateway {
	config?: MutationConfig<typeof SmsGatewaysServiceV2.create>
}
export const useCreateSMSGateway = ({ config }: CreateSmsGateway) => {
	const INVALID_QUERY_KEY = `${SmsGatewaysServiceV2.NAME}${SmsGatewaysServiceV2.filter.name}`
	return useMutation({
		...config,
		mutationFn: SmsGatewaysServiceV2.create,
		onSettled: () => {
			queryClient.invalidateQueries(INVALID_QUERY_KEY)
		},
	})
}
interface DeleteSMSGateway {
	config?: MutationConfig<typeof SmsGatewaysServiceV2.delete>
}
export const useDeleteSMSGateway = ({ config }: DeleteSMSGateway) => {
	const INVALID_QUERY_KEY = `${SmsGatewaysServiceV2.NAME}${SmsGatewaysServiceV2.filter.name}`
	return useMutation({
		...config,
		mutationFn: SmsGatewaysServiceV2.delete,
		onSettled: () => {
			queryClient.invalidateQueries(INVALID_QUERY_KEY)
		},
	})
}
interface DefaultSet {
	config?: MutationConfig<typeof SmsGatewaysServiceV2.defaultSet>
}
export const useSmsGatewayDefaultSet = (config: DefaultSet) => {
	const INVALID_QUERY_KEY = `${SmsGatewaysServiceV2.NAME}${SmsGatewaysServiceV2.filter.name}`
	return useMutation({
		...config,
		mutationFn: SmsGatewaysServiceV2.defaultSet,
		onSettled: () => {
			queryClient.invalidateQueries(INVALID_QUERY_KEY)
		},
	})
}
interface GetDefaultGateway {
	config?: QueryConfig<typeof SmsGatewaysServiceV2.getDefaultGateway>
}
export const useGetDefaultSmsGateway = ({ config }: GetDefaultGateway = {}) => {
	const KEY = `${SmsGatewaysServiceV2.NAME}${SmsGatewaysServiceV2.getDefaultGateway.name}`
	return useQuery({
		...config,
		queryKey: [KEY],
		queryFn: () => SmsGatewaysServiceV2.getDefaultGateway(),
	})
}
