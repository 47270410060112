import { AuthService } from '@shared/services'
import { storage } from '@shared/utils'
import { notification } from 'antd'
import { AxiosResponse } from 'axios'
import { useMutation } from 'react-query'
import { AuthAdminServiceV2 } from './../../../@shared/services/auth.admin.service'

export const useAuth = () => {
	const adminLoginFn = useMutation(AuthAdminServiceV2.login, {
		onSuccess: (val: any) => {
			storage.setToken(val?.data?.data?.accessToken?.token)
			storage.setRole(val?.data?.data?.user?.type)
			notification.success({ message: 'Login Success' })
			// window.location.pathname = "/"
		},
	})
	const logoutFn = () => {
		storage.clear()
		window.location.assign(window.location.origin as unknown as string)
	}
	const refreshTokenFn = useMutation(AuthService.refreshToken, {
		onSuccess: (val: AxiosResponse) => {
			storage.setToken(val?.data?.data.token)
		},
	})
	const isAuthenticated = storage.getToken() ? true : false
	return {
		adminLoginFn,
		logoutFn,
		isAuthenticated,
		refreshTokenFn,
	}
}
