import { ICostCountResponse } from "@shared/interfaces"
import { SmsCampaignRecipientSlide } from "@shared/enums"
import { SmsCampaignTypes } from "."

export const smsCampaignReducerCleanUpAction = () => (dispatch: any) => {
	dispatch({
		type: SmsCampaignTypes.SMS_CAMPAIGN_CLEAN_UP,
	})
}
export const smsCampaignStepperAction = (option: number) => (dispatch: any) => {
	dispatch({
		type: SmsCampaignTypes.SMS_CAMPAIGN_STRATEGY_STEPS_UPDATE,
		payload: option,
	})
}
export const smsCampaignTypeSliderAction = (type: string) => (
	dispatch: any
) => {
	dispatch({
		type: SmsCampaignTypes.SMS_CAMPAIGN_RECIPIENT_STEPS_UPDATE,
		payload: type,
	})
	if (type === SmsCampaignRecipientSlide.DEFAULT) {
		dispatch(smsCampaignReducerCleanUpAction())
		return
	} else if (type !== SmsCampaignRecipientSlide.OVERVIEW) {
		dispatch(smsCampaignInsertTypeAction(type))
	}
}

export const smsCampaignInsertTypeAction = (campaignType: string) => (
	dispatch: any
) => {
	dispatch({
		type: SmsCampaignTypes.SMS_CAMPAIGN_INSERT_TYPE,
		payload: campaignType,
	})
}

export const smsCampaignInsertUserAction = (user: any) => (dispatch: any) => {
	dispatch({
		type: SmsCampaignTypes.SMS_CAMPAIGN_INSERT_USER,
		payload: user,
	})
}
export const smsCampaignInsertRecipientAction = (number: string) => (
	dispatch: any
) => {
	dispatch({
		type: SmsCampaignTypes.SMS_CAMPAIGN_INSERT_RECIPIENT,
		payload: number,
	})
}

export const smsCampaignInsertRecipientsAction = (numbers: Array<any>) => (
	dispatch: any
) => {
	dispatch({
		type: SmsCampaignTypes.SMS_CAMPAIGN_INSERT_RECIPIENTS,
		payload: numbers,
	})
}

export const smsCampaignInsertContactGroupsAction = (
	contactGroup: Array<any>
) => (dispatch: any) => {
	dispatch({
		type: SmsCampaignTypes.SMS_CAMPAIGN_INSERT_SEGMENT,
		payload: contactGroup,
	})
}

export const smsCampaignInsertContactsAction = (contacts: Array<any>) => (
	dispatch: any
) => {
	dispatch({
		type: SmsCampaignTypes.SMS_CAMPAIGN_INSERT_CONTACTS,
		payload: contacts,
	})
}
export const smsCampaignInsertFileAction = (file: File) => (dispatch: any) => {
	dispatch({
		type: SmsCampaignTypes.SMS_CAMPAIGN_INSERT_FILE,
		payload: file,
	})
}
export const smsCampaignInsertFileHeadersAction = (headers: string[]) => (
	dispatch: any
) => {
	dispatch({
		type: SmsCampaignTypes.SMS_CAMPAIGN_INSERT_FILE_HEADER,
		payload: headers,
	})
}

export const smsCampaignInsertMaskAction = (mask: {} | null) => (
	dispatch: any
) => {
	dispatch({
		type: SmsCampaignTypes.SMS_CAMPAIGN_INSERT_MASK,
		payload: mask,
	})
}

export const smsCampaignInsertBodyAction = (text: string) => (
	dispatch: any
) => {
	dispatch({
		type: SmsCampaignTypes.SMS_CAMPAIGN_INSERT_BODY,
		payload: text,
	})
}

export const smsCampaignInsertScheduleAction = (schedule: any) => (
	dispatch: any
) => {
	dispatch({
		type: SmsCampaignTypes.SMS_CAMPAIGN_INSERT_DATE,
		payload: schedule,
	})
}

export const getSmsCampaignCostCount = (costCount: ICostCountResponse) => (
	dispatch: any
) => {
	dispatch({
		type: SmsCampaignTypes.SMS_CAMPAIGN_GET_COUNT_SUCCESS,
		payload: costCount,
	})
}
