import { IBaseQuery, ICreateTransaction } from "@shared/interfaces"
import {
	MutationConfig,
	QueryConfig,
	queryClient,
} from "@shared/config/react-query"
import { useMutation, useQuery } from "react-query"

import { CoreAxiosInstanceV2 } from "@shared/config/service/core.axios.v2"
import { concatQuery } from "@shared/utils"

export interface IFilterTransactionLogs extends IBaseQuery {
	user?: string
}

const END_POINT = `/transactionLogs/`

const TransactionLogServiceV2 = {
	NAME: "TransactionLogService",
	create(payload: ICreateTransaction): any {
		return CoreAxiosInstanceV2.post(`${END_POINT}`, payload)
	},
	deduct(payload: ICreateTransaction): any {
		return CoreAxiosInstanceV2.post(`${END_POINT}deductBalance`, payload)
	},
	filter(options: IFilterTransactionLogs): any {
		return CoreAxiosInstanceV2.get(`${END_POINT}filter?${concatQuery(options)}`)
	},
}

export const useCreateTransactionLog = (
	config: MutationConfig<typeof TransactionLogServiceV2.create>
) => {
	const invalidQuery = `${TransactionLogServiceV2.NAME}${TransactionLogServiceV2.filter.name}`
	return useMutation({
		...config,
		mutationFn: TransactionLogServiceV2.create,
		onSettled: () => {
			queryClient.invalidateQueries(invalidQuery)
		},
	})
}

export const useDeductTransactionLog = (
	config: MutationConfig<typeof TransactionLogServiceV2.deduct>
) => {
	const invalidQuery = `${TransactionLogServiceV2.NAME}${TransactionLogServiceV2.filter.name}`
	return useMutation({
		...config,
		mutationFn: TransactionLogServiceV2.deduct,
		onSettled: () => {
			queryClient.invalidateQueries(invalidQuery)
		},
	})
}

export const useTransactionLogs = ({
	config,
	options,
}: {
	config?: QueryConfig<typeof TransactionLogServiceV2.filter>
	options?: IFilterTransactionLogs
}) => {
	const queryName = `${TransactionLogServiceV2.NAME}${TransactionLogServiceV2.filter.name}`
	return useQuery({
		...config,
		queryKey: [queryName, Object.values(options)],
		queryFn: () => TransactionLogServiceV2.filter(options),
	})
}
