import { AppContent } from "@shared/layout"
import { PageHeader } from "antd"
import React from "react"
import SmsReRoute from "../components/SmsReRoute"
import { useNavigate } from "react-router-dom"

const SmsReRoutePage = () => {
	const navigator = useNavigate()
	return (
		<AppContent>
			<PageHeader onBack={() => navigator(-1)} title="Sms Re-Route" />
			<SmsReRoute />
		</AppContent>
	)
}

export default SmsReRoutePage
