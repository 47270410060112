import {
	ICreateSmsTemplate,
	IDeleteSmsTemplate,
	IFilterSmsTemplate,
} from "@shared/interfaces"
import { MutationConfig, QueryConfig } from "@shared/config/react-query"
import { useMutation, useQuery } from "react-query"

import { CoreAxiosInstanceV2 } from "@shared/config/service/core.axios.v2"
import { concatQuery } from "@shared/utils"

interface Update {
	name?: string
	body?: string
	id?: string
	status?: "ACTIVE" | "INACTIVE"
}
const END_POINT = "/smsTemplates/"

export const SmsTemplateServiceV2 = {
	NAME: "SmsTemplate",
	create(payload: ICreateSmsTemplate): any {
		return CoreAxiosInstanceV2.post(`${END_POINT}`, payload)
	},
	filter(options: IFilterSmsTemplate): any {
		return CoreAxiosInstanceV2.get(`${END_POINT}filter?${concatQuery(options)}`)
	},
	delete(payload: IDeleteSmsTemplate): any {
		const { id } = payload
		return CoreAxiosInstanceV2.delete(`${END_POINT}${id}`)
	},
	update(payload: Update): any {
		const { id, name, body, status } = payload
		return CoreAxiosInstanceV2.put(`${END_POINT}${id}`, {
			name,
			body,
			status,
		})
	},
}

interface CreateSmsTemplate {
	config?: MutationConfig<typeof SmsTemplateServiceV2.create>
}

export const useCreateSmsTemplate = ({ config }: CreateSmsTemplate) => {
	return useMutation({
		...config,
		mutationFn: SmsTemplateServiceV2.create,
	})
}

interface DeleteSmsTemplate {
	config?: MutationConfig<typeof SmsTemplateServiceV2.delete>
}

export const useDeleteSmsTemplate = ({ config }: DeleteSmsTemplate) => {
	return useMutation({
		...config,
		mutationFn: SmsTemplateServiceV2.delete,
	})
}
interface SmsTemplates {
	config?: QueryConfig<typeof SmsTemplateServiceV2.filter>
	options?: IFilterSmsTemplate
}

export const useSmsTemplates = ({ config, options }: SmsTemplates = {}) => {
	return useQuery({
		...config,
		queryKey: [
			`${SmsTemplateServiceV2.NAME}${SmsTemplateServiceV2.filter.name}`,
			`${Object.values(options)}`,
		],
		queryFn: () => SmsTemplateServiceV2.filter(options),
	})
}
