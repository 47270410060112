import { CoreAxiosInstanceV2 } from "@shared/config/service/core.axios.v2"
import { QueryConfig } from "@shared/config/react-query"
import { useQuery } from "react-query"

const END_POINT = "/cmps/"

export const CMPService = {
	NAME: "CMPService",
	balance() {
		return CoreAxiosInstanceV2.get(`${END_POINT}cmpBalance`)
	},
}

export const useCMPBalance = ({
	config,
}: {
	config?: QueryConfig<typeof CMPService.balance>
}) => {
	const queryName = `${CMPService.NAME}${CMPService.balance.name}`
	return useQuery({
		...config,
		queryKey: [queryName],
		queryFn: () => CMPService.balance(),
	})
}
