import { Navigate, RouteObject } from "react-router"

import OperatorPage from "./OperatorPage"
import { Paths } from "@shared/constant"
import React from "react"

export const OperatorsRoutes: RouteObject[] = [
	{ path: "", element: <Navigate to={Paths.OperatorsList} /> },
	{ path: "list", element: <OperatorPage /> },
]
