import {
	AiOutlineDeliveredProcedure,
	AiOutlineMessage,
	AiOutlineProfile,
} from "react-icons/ai"
import { Button, Card, List, Skeleton, Typography } from "antd"

import { Link } from "react-router-dom"
import { Paths } from "@shared/constant"
import React from "react"

const { Title } = Typography

interface IFProps {
	title?: string
}
const ActionsBoardCard: React.FC<IFProps> = ({ title }) => {
	return (
		<Card
			title={title ? <Title level={5}>{title}</Title> : ""}
			style={{ minHeight: 500 }}>
			<List
				loading={false}
				itemLayout="horizontal"
				dataSource={cDemo}
				renderItem={(item) => (
					<List.Item>
						<Skeleton avatar title={false} loading={false} active>
							<List.Item.Meta
								avatar={<span style={icnStyle}>{item.icon}</span>}
								title={
									//@ts-ignore
									<Title level={5} style={{ fontSize: 14, fontWeight: "500" }}>
										{item.title}
									</Title>
								}
								description={item.description}
							/>
							<Link to={item.link}>
								<Button type="primary" shape="round">
									Get Start
								</Button>
							</Link>
						</Skeleton>
					</List.Item>
				)}
			/>
		</Card>
	)
}

export default ActionsBoardCard

const cDemo = [
	{
		title: "Send your first SMS",
		description:
			"Send and Receive text messages through a single connection for timely and cost efficient communications.",
		link: Paths.InstantMessage,
		icon: <AiOutlineMessage />,
	},
	{
		title: "Update Your Profile",
		description: "Manage Profile info from your preference",
		link: Paths.UsersMe,
		icon: <AiOutlineProfile />,
	},
	{
		title: "Generate Report",
		description:
			"Select an automatic time span, or set specific start and end dates.",
		link: Paths.ReportList,
		icon: <AiOutlineDeliveredProcedure />,
	},
]
const icnStyle = {
	display: "flex",
	justifyContent: "center",
	alignItems: "center",
	height: 35,
	width: 35,
	minWidth: 35,
	borderRadius: 35,
	background: "#7366ff",
	color: "#ffff",
	fontSize: 16,
}
