import { AiOutlineAccountBook, AiOutlineClose } from "react-icons/ai"
import {
	CommonTitle,
	DebounceSearchInputField,
	Purify,
} from "@shared/components"
import { Modal, Space, Tag } from "antd"
import React, { useState } from "react"
import {
	useSMSMaskGetUsers,
	useSMSMaskUserAssign,
	useSMSMaskUserDelete,
	useUsersFilterWithMask,
} from "@shared/services"

import { IUser } from "@shared/interfaces"
import { PlusOutlined } from "@ant-design/icons"
import { isEmpty } from "lodash"

interface IFProps {
	maskId?: string
}
const SmsMaskUserAssign: React.FC<IFProps> = ({ maskId }) => {
	const smsMaskGetUsers = useSMSMaskGetUsers({
		id: maskId,
	})
	const [options, setOptions] = useState({
		page: 1,
		take: 10,
		searchTerm: "",
		type: "INDIVIDUAL",
		mask: maskId,
	})
	const usersFilterWithMaskQuery = useUsersFilterWithMask({
		options: {
			mask: options.mask,
			take: options.take,
			searchTerm: options.searchTerm,
			type: options.type,
		},
	})

	const smsMaskUserAssign = useSMSMaskUserAssign({})
	const onSubmitUserAssignAgainstAnMask = (userId: string) => {
		Modal.confirm({
			title: "Are You Sure ?",
			onOk: () =>
				smsMaskUserAssign.mutate({
					smsMask: maskId,
					user: [userId],
				}),
		})
	}
	const smsMaskUserDelete = useSMSMaskUserDelete({})
	const onSubmitUserUnAssignAgainstAnMask = (userId: string) => {
		Modal.confirm({
			title: "Are You Sure ?",
			onOk: () => smsMaskUserDelete.mutate([userId]),
		})
	}
	return (
		<>
			<CommonTitle title="User Assign" icon={<AiOutlineAccountBook />} />
			<Space
				style={{
					display: "flex",
					justifyContent: "center",
					marginTop: 30,
					marginBottom: 30,
				}}>
				<DebounceSearchInputField
					style={{ maxWidth: 270 }}
					onChange={(term: string) => {
						setOptions({ ...options, searchTerm: term })
					}}
				/>
			</Space>
			<Purify
				hide="empty"
				loading={usersFilterWithMaskQuery.isLoading}
				empty={isEmpty(usersFilterWithMaskQuery.data?.data?.data)}>
				{usersFilterWithMaskQuery.data?.data?.data?.map(
					(user: IUser, i: any) => (
						<Tag style={{ margin: 10 }} color="#2db7f5">
							<PlusOutlined
								onClick={() => onSubmitUserAssignAgainstAnMask(user.id)}
							/>
							{user?.userInfo.name}
						</Tag>
					)
				)}
			</Purify>
			<CommonTitle title="Users List " />
			<Purify
				hide="empty"
				loading={smsMaskGetUsers.isLoading}
				empty={isEmpty(smsMaskGetUsers?.data?.data?.data)}>
				{smsMaskGetUsers?.data?.data?.data?.map((user: any, i: any) => (
					<Tag
						style={{ margin: 10, display: "inline-flex", alignItems: "center" }}
						color="#108ee9">
						{user?.user?.userInfo?.name}
						<AiOutlineClose
							style={{ cursor: "pointer" }}
							onClick={() => onSubmitUserUnAssignAgainstAnMask(user.id)}
						/>
					</Tag>
				))}
			</Purify>
		</>
	)
}
export default SmsMaskUserAssign
