import { Button, Modal, PageHeader } from "antd"
import React, { useState } from "react"

import { AppContent } from "@shared/layout"
import GatewayFrom from "../components/elements/GatewayFrom"
import Gateways from "../components/Gateways"
import { boolToToggleUtil } from "@shared/utils"
import { useCreateSMSGateway } from "@shared/services"
import { useNavigate } from "react-router-dom"

const GatewaysPage = () => {
	const navigator = useNavigate()
	const [createUi, setCreateUi] = useState(false)
	const createSMSGatewayMutation = useCreateSMSGateway({
		config: {
			onSuccess: (val) => {
				console.log(
					"🚀 ~ file: GatewaysPage.tsx ~ line 17 ~ GatewaysPage ~ val",
					val
				)
				if (val.data.success) {
					setCreateUi(false)
				}
			},
		},
	})

	return (
		<AppContent>
			<PageHeader
				onBack={() => navigator(-1)}
				title="Sms Gateways"
				extra={[
					<Button size="large" onClick={() => setCreateUi(true)} type="primary">
						Create
					</Button>,
				]}
			/>
			<Gateways />
			<Modal
				footer={null}
				visible={createUi}
				onCancel={() => setCreateUi(false)}
				title="Create Gateway">
				<GatewayFrom
					initialValue={{
						name: "",
						operator: "",
						originatingNumber: "",
						username: "",
						password: "",
						apiKey: "",
						status: true,
						prefixes: "",
					}}
					loading={createSMSGatewayMutation.isLoading}
					onFinish={(val) => {
						createSMSGatewayMutation.mutate({
							...val,
							status: boolToToggleUtil(val.status),
						} as any)
					}}
				/>
			</Modal>
		</AppContent>
	)
}

export default GatewaysPage
