import { AccountType, TimeFormat, ToggleStatus } from "@shared/enums"

import { ENV } from "@ENV"
import { IUserMetaData } from "@shared/interfaces"
import jwt_decode from "jwt-decode"
import moment from "moment"
import { savePDF } from "@progress/kendo-react-pdf"

export const commonGridGutters = { xs: 5, sm: 10, md: 10, xl: 12, xxl: 15 }

export const getDurationUtil = (day: number) => {
	if (day < 7) {
		return 1
	} else if (day < 29) {
		return 1
	} else {
		return 30
	}
}

export const smsEncodedUtil = (x: string) => {
	const text: string = String(x)
	try {
		return decodeURIComponent(text).length > 0 ? decodeURIComponent(text) : text
	} catch (err) {
		return text
	}
}

export const isNonLatinBodyUtil = (s: string) => {
	// eslint-disable-next-line
	return /[^\u0000-\u007F]/.test(s)
}

export const genRandomNameUtil = (str: string) => {
	const date = timeFormatUtil(String(new Date()), TimeFormat.primary)
		.split("-")
		.join("")
	const mask = String(str).slice(0, 30).toUpperCase()
	const randomId = String(new Date().getTime()).slice(0, 3)

	return `${date}-${mask}-${randomId}`
}

export const textTrimUtil = (string: string) => {
	return string?.replace(/\s/g, "")
}

export const responsiveBreakPoint = {
	xs: 0,
	sm: 576,
	md: 768,
	lg: 992,
	xl: 1200,
	xxl: 1650,
}

type TimeFormatType = "DD-MM-YYYY hh:mm:ss A" | "DD-MM-YYYY" | "YYYY-MM-DD"

export const timeFormatUtil = (
	time: string,
	format: TimeFormat | TimeFormatType
) => {
	return moment(time).format(format)
}

export const boolToStatusUtil = (status: boolean) => {
	return status === true ? ToggleStatus.ACTIVE : ToggleStatus.INACTIVE
}
export const statusToBoolUtil = (status: "ACTIVE" | "INACTIVE" | string) => {
	return status === ToggleStatus.ACTIVE ? true : false
}
export const SMSCostCalculateUtil = (body: string, rate: number): number => {
	return parseFloat(
		(Math.ceil(body.length / ENV.SMS_CHARACTER_COUNT) * rate).toFixed(2)
	)
}

export const userBalanceUtil = (
	accountType: AccountType,
	balance: number,
	dueBalance: number
) => {
	if (accountType === AccountType.PostPaid) {
		return `${ENV.CURRENCY_SIGN}${Number(dueBalance).toFixed(2)}`
	} else if (accountType === AccountType.Prepaid) {
		return `${ENV.CURRENCY_SIGN}${Number(balance).toFixed(2)}`
	} else {
		return 0
	}
}

export const phoneNumberValidateUtil = (phoneNumber: any) => {
	try {
		let validNumber: any
		const number = phoneNumber.match(ENV.BD_PHONE_REGEX)
		if (number) {
			// eslint-disable-next-line
			number.map((number: any) => {
				validNumber = `0${number.slice(number.length - 10, number.length)}`
			})
			return validNumber
		} else {
			return false
		}
	} catch (error) {
		return false
	}
}

class DocService {
	createPdf = (html: any, fileName: any) => {
		savePDF(html, {
			paperSize: "A4",
			fileName: fileName,
			margin: 25,
			scale: 0.8,
		})
	}
}

export const utilsGeneratorPdf = new DocService()

export const storage = {
	getToken: (): string | false => {
		if (!window.localStorage || !window.JSON) {
			return false
		}
		const item = localStorage.getItem(`${ENV.SESSION_PREFIX}TOKEN`)
		if (!item) {
			return false
		}
		return JSON.parse(item) as string
	},
	getRole: (): string | false => {
		if (!window.localStorage || !window.JSON) {
			return false
		}
		var item = localStorage.getItem(`${ENV.SESSION_PREFIX}ROLE`)
		if (!item) {
			return false
		}
		return JSON.parse(item) as string
	},
	setToken: (token: string) => {
		if (token) {
			window.localStorage.setItem(
				`${ENV.SESSION_PREFIX}TOKEN`,
				JSON.stringify(token)
			)
		}
	},
	setRole: (role: string) => {
		if (role) {
			window.localStorage.setItem(
				`${ENV.SESSION_PREFIX}ROLE`,
				JSON.stringify(role)
			)
		}
	},
	clear: () => {
		localStorage.clear()
	},
	setData(data: any, key: string) {
		if (!window.localStorage || !window.JSON || !key) {
			return
		}
		localStorage.setItem(key, JSON.stringify(data))
	},
	getDate(key: string) {
		const item = JSON.stringify(localStorage.getItem(key))
		if (!item) {
			return false
		}
		return JSON.parse(item)
	},
	removeData(key: string) {
		if (!window.localStorage || !window.JSON || !key) {
			return
		}
		localStorage.removeItem(key)
	},
	setMeta: (meta: {}) => {
		if (meta) {
			window.localStorage.setItem(
				`${ENV.SESSION_PREFIX}META_DATA`,
				JSON.stringify(meta)
			)
		}
	},
	getMeta: (): IUserMetaData => {
		if (!window.localStorage || !window.JSON) {
			return
		}
		var item = localStorage.getItem(`${ENV.SESSION_PREFIX}META_DATA`)
		if (!item) {
			return
		}
		return JSON.parse(item) as IUserMetaData
	},
}

export const extractCMPData = (res: string) => {
	let t = res?.split(",")
	// eslint-disable-next-line
	let status = eval(t[0])
	let value = t[1]?.slice(0, t[1]?.length - 2)
	return {
		status,
		value,
	}
}
export const concatQuery = (options: any): string => {
	return Object.keys(options)
		.map((x) => {
			return `${x}=${options[x]}`
		})
		.join("&")
}
export const boolToToggleUtil = (status: boolean) => {
	return status === true ? ToggleStatus.ACTIVE : ToggleStatus.INACTIVE
}

export const getSession = (): any => {
	try {
		const token: any = storage.getToken()
		const decoded = jwt_decode(token)
		if (decoded) {
			return decoded
		} else {
			return false
		}
	} catch (error) {
		return false
	}
}
