import React, { useEffect } from "react"

import { BottomTabNavigation } from "./BottomTabNavigation"
import { Footer } from "antd/lib/layout/layout"
import { Layout } from "antd"
import { PureResponsive } from "@shared/components"
import { createStructuredSelector } from "reselect"
import { selectAppSidebarToggle } from "@shared/redux/layout/app-layout.selectors"
import { useResponsive } from "ahooks"
import { useSelector } from "react-redux"

const { Content } = Layout
interface IFProps {
	background?: "none" | "white"
	children: any
	rightSidebar?: boolean
	gutter?: number
	className?: string
}
export const AppContent: React.FC<IFProps> = ({
	children,
	background = "white",
	rightSidebar = false,
	gutter = 24,
	className,
}) => {
	const responsive = useResponsive()
	const isMobileView = responsive.lg === false
	const { appLayoutSidebar } = useSelector(
		createStructuredSelector({
			appLayoutSidebar: selectAppSidebarToggle,
		})
	)
	const { isOpenLeftSidebar, isOpenRightSidebar } = appLayoutSidebar
	useEffect(() => {
		window.scroll({ top: 0, behavior: "smooth" })
		return () => {}
	}, [])
	return (
		<>
			<Layout
				style={{
					marginLeft: isOpenLeftSidebar || isMobileView ? 0 : 100,
					marginRight: !rightSidebar
						? 0
						: isOpenRightSidebar || isMobileView
						? 0
						: 250,
				}}>
				<Content
					style={{
						background,
						padding: isMobileView ? 10 : gutter,
						marginLeft: isMobileView ? 0 : 10,
						marginRight: isMobileView ? 0 : 10,
					}}
					className={`app-content ${className}`}>
					{children}
				</Content>
				<PureResponsive show="mobile">
					<BottomTabNavigation />
				</PureResponsive>
				<Footer style={{ marginBottom: isMobileView ? 70 : 0 }}>
					<p>© 2021 MessageAnalytica. All rights reserved.</p>
				</Footer>
			</Layout>
		</>
	)
}
