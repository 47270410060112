import { FaUser, FaUsers } from "react-icons/fa"

import { AiOutlineHome } from "react-icons/ai"
import { HiDocumentReport } from "react-icons/hi"
import { Link } from "react-router-dom"
import { Paths } from "@shared/constant"
import React from "react"
import { SiCampaignmonitor } from "react-icons/si"

export const BottomTabNavigation = () => {
	const pathname = window?.location?.pathname

	return (
		<div className="bottom-navigation-blk">
			<div className="bottom-navs">
				<div
					className={`btm-nav-itm ${
						pathname === Paths.DefaultDashboard ? "active" : ""
					}`}>
					<Link to={Paths.DefaultDashboard}>
						<AiOutlineHome />
						<p>Dashboard</p>
					</Link>
				</div>
				<div
					className={`btm-nav-itm ${
						pathname === Paths.CreateSmsCampaign ? "active" : ""
					}`}>
					<Link to={Paths.CreateSmsCampaign}>
						<SiCampaignmonitor />
						<p>Campaign</p>
					</Link>
				</div>
				<div
					className={`btm-nav-itm ${
						pathname === Paths.ContactList ? "active" : ""
					}`}>
					<Link to={Paths.ContactList}>
						<FaUsers />
						<p>Contacts</p>
					</Link>
				</div>
				<div
					className={`btm-nav-itm ${
						pathname === Paths.ReportList ? "active" : ""
					}`}>
					<Link to={Paths.ReportList}>
						<HiDocumentReport />
						<p>Reports</p>
					</Link>
				</div>
				<div
					className={`btm-nav-itm ${
						pathname === Paths.UsersMe ? "active" : ""
					}`}>
					<Link to={Paths.UsersMe}>
						<FaUser />
						<p>Account</p>
					</Link>
				</div>
			</div>
		</div>
	)
}
