import { Form, Typography } from "antd"

import OperatorForm from "./elements/OperatorForm"
import React from "react"
import { useCreateOperator } from "@shared/services"

const CreateOperator = () => {
	const [form] = Form.useForm()
	const createOperatorMutation = useCreateOperator({
		config: {
			onSuccess: (val) => {
				console.log(
					"🚀 ~ file: CreateOperator.tsx ~ line 12 ~ CreateOperator ~ val",
					val
				)
				if (val.data.success) {
					form.resetFields()
				}
			},
		},
	})

	return (
		<>
			<Typography.Title level={4}>Create new Operator</Typography.Title>
			<OperatorForm
				form={form}
				initialValue={{ name: "" }}
				loading={createOperatorMutation.isLoading}
				onFinish={(val) => {
					createOperatorMutation.mutate({ name: val.name })
				}}
			/>
		</>
	)
}
export default CreateOperator
