import { ARE_YOU_SURE, PACKAGE_UPDATE } from "@shared/constant"
import {
	Button,
	Col,
	Divider,
	InputNumber,
	Modal,
	Row,
	Slider,
	Typography,
} from "antd"
import { CommonTitle, Purify } from "@shared/components"
import React, { useState } from "react"
import { useUser, useUserPackageUpdate } from "@shared/services"

const { Title } = Typography
interface IFProps {
	userId: string
}
const UserPackageUpdate: React.FC<IFProps> = ({ userId }) => {
	const [packInfo, setPackInfo] = useState<any>({
		maskRate: "",
		nonMaskRate: "",
	})
	const { maskRate, nonMaskRate } = packInfo
	const userQuery = useUser({
		id: userId,
		config: {
			onSuccess: (val) => {
				if (val.data?.success) {
					setPackInfo({
						...packInfo,
						maskRate: val?.data?.data?.smsPackages[0]?.maskRate,
						nonMaskRate: val?.data?.data?.smsPackages[0]?.nonMaskRate,
					})
				}
			},
		},
	})
	const packageUpdateMutation = useUserPackageUpdate({})
	const onSubmit = () => {
		Modal.confirm({
			title: ARE_YOU_SURE,
			onOk: () =>
				packageUpdateMutation.mutate({
					user: userId,
					nonMaskRate: Number(nonMaskRate),
					maskRate: Number(maskRate),
				}),
		})
	}
	return (
		<Purify loading={userQuery.isLoading}>
			<CommonTitle title={PACKAGE_UPDATE} />
			<Divider />
			<Title level={5}>Mask Rate</Title>
			<Row>
				<Col span={18}>
					<Slider
						max={2}
						step={0.01}
						onChange={(vl: number) => {
							setPackInfo({ ...packInfo, maskRate: vl })
						}}
						value={typeof maskRate === "number" ? maskRate : 0}
					/>
				</Col>
				<Col span={4}>
					<InputNumber
						max={2}
						style={{ margin: "0 16px" }}
						value={maskRate}
						onChange={(vl: number) => {
							setPackInfo({ ...packInfo, maskRate: vl })
						}}
					/>
				</Col>
			</Row>
			<Title level={5}>NonMask Rate</Title>
			<Row>
				<Col span={18}>
					<Slider
						max={2}
						step={0.01}
						onChange={(vl: number) => {
							setPackInfo({ ...packInfo, nonMaskRate: vl })
						}}
						value={typeof nonMaskRate === "number" ? nonMaskRate : 0}
					/>
				</Col>
				<Col span={4}>
					<InputNumber
						max={2}
						style={{ margin: "0 16px" }}
						value={nonMaskRate}
						onChange={(vl: number) => {
							setPackInfo({ ...packInfo, nonMaskRate: vl })
						}}
					/>
				</Col>
			</Row>
			<Button
				type="primary"
				size="middle"
				loading={packageUpdateMutation.isLoading}
				onClick={onSubmit}>
				Update
			</Button>
		</Purify>
	)
}
export default UserPackageUpdate
