import { Navigate, RouteObject } from "react-router-dom"

import { Paths } from "@shared/constant"
import React from "react"
import SmsForceSuccessPage from "./SmsForceSuccessPage"
import SmsReRoutePage from "./SmsReRoutePage"

export const ReRouteRoutes: RouteObject[] = [
	{ path: "", element: <Navigate to={Paths.PushPullPlayGround} /> },
	{ path: "force-success", element: <SmsForceSuccessPage /> },
	{ path: "force-route", element: <SmsReRoutePage /> },
]
