import PrefixForm from "./elements/PrefixForm"
import React from "react"
import { Typography } from "antd"
import { useCreatePrefix } from "@shared/services"

const CreatePrefix = () => {
	const createPrefixMutation = useCreatePrefix({})

	return (
		<>
			<Typography.Title level={4}>Create new</Typography.Title>
			<PrefixForm
				initialValue={{ name: "", operator: { label: "", value: "" } }}
				loading={createPrefixMutation.isLoading}
				onFinish={(val) => {
					createPrefixMutation.mutate(val)
				}}
			/>
		</>
	)
}
export default CreatePrefix
