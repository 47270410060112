import PatternFrom from "./elements/PatternFrom"
import React from "react"
import { Typography } from "antd"
import { useUpdatePattern } from "@shared/services"

interface IFProps {
	type: string
	pattern: string
	id: string
	onClose?: () => void
}

const UpdatePattern: React.FC<IFProps> = ({ pattern, type, id, onClose }) => {
	const updatePatternMutation = useUpdatePattern({
		config: {
			onSuccess: (val) => {
				if (val.data.success) {
					onClose()
				}
			},
		},
	})

	return (
		<>
			<Typography.Title level={4}>Update Pattern</Typography.Title>
			<PatternFrom
				initialValue={{ type, pattern }}
				loading={updatePatternMutation.isLoading}
				onFinish={(val) => {
					updatePatternMutation.mutate({
						type: val.type,
						pattern: val.type,
						id,
					})
				}}
			/>
		</>
	)
}
export default UpdatePattern
