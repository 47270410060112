import ApiDocsPage from "./ApiDocsPage"
import ComingSoonPage from "./ComingSoonPage"
import HelpAndSupportPage from "./HelpAndSupportPage"
import { Navigate } from "react-router"
import { Paths } from "@shared/constant"
import React from "react"
import TermsAndConditionPage from "./TermsAndConditionPage"

export const CommonRoutes = [
	{ path: "", element: <Navigate to={Paths.ApiDocs} /> },
	{ path: "api-docs", element: <ApiDocsPage /> },
	{ path: "up-coming", element: <ComingSoonPage /> },
	{ path: "help", element: <HelpAndSupportPage /> },
	{ path: "policy", element: <TermsAndConditionPage /> },
]
