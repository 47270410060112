import { Button, Form, Input, Space, Switch } from "antd"

import React from "react"

interface IInitialValue {
	maskingName: string
	status: boolean
	isMasking: boolean
}

interface IFProps {
	initialValue?: IInitialValue
	onFinish?: (val: IInitialValue) => void
	loading?: boolean
}
const MaskForm: React.FC<IFProps> = ({ initialValue, onFinish, loading }) => {
	return (
		<Form
			name="basic"
			initialValues={initialValue}
			layout="vertical"
			onFinish={onFinish}>
			<Form.Item
				label="Masking Name"
				name="maskingName"
				rules={[
					{ required: true, message: "Please input your Masking Name!" },
				]}>
				<Input size="large" placeholder="Enter Your maskingName" />
			</Form.Item>
			<Space>
				<Form.Item
					label="Status"
					name="status"
					rules={[{ required: true, message: "Please input your status!" }]}>
					<Switch defaultChecked={initialValue.status} />
				</Form.Item>
				<Form.Item
					label="Is Masking"
					name="isMasking"
					rules={[{ required: true, message: "Please input your isMasking!" }]}>
					<Switch defaultChecked={initialValue.isMasking} />
				</Form.Item>
			</Space>
			<Form.Item>
				<Button loading={loading} type="primary" htmlType="submit">
					Submit
				</Button>
			</Form.Item>
		</Form>
	)
}

export default MaskForm
