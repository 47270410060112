import React, { useState } from "react"

import AsyncSelect from "react-select/lib/Async"
import { getSession } from "@shared/utils"
import { useUserGateways } from "@shared/services"

interface IFProps {
	onChange?: (e: any) => void
	label?: string
	defaultValue?: {
		label: any
		value: any
	}
}
export const DebounceGatewaySelectField: React.FC<IFProps> = ({
	onChange,
	label = "Gateway",
	defaultValue,
}) => {
	const user: any = getSession()
	const [options, setOptions] = useState({
		searchTerm: "",
	})
	const userGatewaysQuery = useUserGateways({ userId: user?.id })
	const defaultOptions = userGatewaysQuery?.data?.data?.data?.map(
		(edge: any) => ({
			label: edge?.smsGateway?.name,
			value: edge?.smsGateway,
		})
	)
	const loadOptionsCall = (inputValue: any) => {
		setOptions({ ...options, searchTerm: inputValue })
	}
	const filter = (inputValue: string) => {
		loadOptionsCall(inputValue)
		const normalizeUser = userGatewaysQuery?.data?.data?.data?.map(
			(edge: any) => ({
				label: edge?.smsGateway?.name,
				value: edge?.smsGateway,
			})
		)
		return normalizeUser
	}
	const loadOptions = (inputValue: any, callback: any) => {
		setTimeout(() => {
			callback(filter(inputValue))
		}, 1000)
	}
	// ON CHANGE MASK
	const onChangeMask = (arrive: any) => {
		onChange(arrive)
	}
	return (
		<>
			<label className="mb-0">{label}</label>
			<AsyncSelect
				className="async-select-field"
				isLoading={userGatewaysQuery.isLoading}
				loadOptions={loadOptions}
				cacheOptions={false}
				clearValue={true}
				defaultOptions={defaultOptions}
				isMulti={false}
				onChange={onChangeMask}
				isDisabled={false}
				// value={value}
				isClearable={true}
				defaultValue={defaultValue}
				theme={(theme) => ({
					...theme,
					borderRadius: 0,
					border: "none",
					boxShadow: "none",
					colors: {
						...theme.colors,
					},
				})}
			/>
		</>
	)
}
