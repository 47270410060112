import { CoreAxiosInstanceV2 } from "@shared/config/service/core.axios.v2"
import { IChangePassword } from "@shared/interfaces"
import { MutationConfig } from "@shared/config"
import { useMutation } from "react-query"

const END_POINT = "/auth/individualUserForReseller/"

export const IndividualUserForResellerService = {
	login(payload: any): any {
		return CoreAxiosInstanceV2.post(`${END_POINT}login`, payload)
	},
	register(payload: any): any {
		return CoreAxiosInstanceV2.post(`${END_POINT}register`, payload)
	},
	resetPassword(payload: IChangePassword) {
		return CoreAxiosInstanceV2.post(`${END_POINT}resetPassword`, payload)
	},
}

interface IndividualResetPassword {
	config?: MutationConfig<typeof IndividualUserForResellerService.resetPassword>
}
export const useIndividualUserForResellerResetPassword = ({
	config,
}: IndividualResetPassword) => {
	return useMutation({
		...config,
		mutationFn: IndividualUserForResellerService.resetPassword,
	})
}
