import React, { useState } from "react"
import { useResellers, useUser } from "@shared/services"

import AsyncSelect from "react-select/lib/Async"
import { IOption } from "@shared/interfaces"
import { getSession } from "@shared/utils"

interface IFProps {
	parentId?: string
	onChange?: (value: IOption | IOption[]) => void
	disabled?: boolean
}
const sessionUser: any = getSession()

export const DebounceUserSelectField: React.FC<IFProps> = ({
	onChange,
	parentId = sessionUser?.id,
	disabled = false,
}) => {
	const userQuery = useUser({ id: sessionUser?.id })

	const loginUserOption = {
		label: `${userQuery?.data?.data?.data?.userInfo?.name}(${userQuery?.data?.data?.data?.userInfo?.number})`,
		value: userQuery?.data?.data?.data,
	}
	const [searchTerm, setSearchTerm] = useState("")
	const filterResellerUserQuery = useResellers({
		options: { parentId: parentId, take: 20, page: 1, type: "ALL", searchTerm },
		config: { keepPreviousData: true },
	})

	// By Default options
	const defaultOptions = [
		loginUserOption,
		...(filterResellerUserQuery?.data?.data?.data?.map((edge: any) => ({
			label: `${edge?.userInfo?.name}(${edge?.userInfo?.number})`,
			value: edge,
		})) || ""),
	]

	// On Search change
	const onChangeSearch = (inputValue: string) => {
		setSearchTerm(inputValue)
		const fetchedUsers = filterResellerUserQuery?.data?.data?.data?.map(
			(edge: any) => ({
				label: `${edge?.userInfo?.name}(${edge?.userInfo?.number})`,
				value: edge,
			})
		)
		const fetchedAndDefaultUser = fetchedUsers
		return fetchedAndDefaultUser
	}

	// Fetching fire
	const onFetching = (inputValue: any, callback: any) => {
		setTimeout(() => {
			callback(onChangeSearch(inputValue))
		}, 1000)
	}

	return (
		<>
			<label style={{ marginBottom: 0 }}>Users</label>
			<AsyncSelect
				className="async-select-field"
				isLoading={userQuery.isLoading || filterResellerUserQuery.isLoading}
				loadOptions={onFetching}
				loadingMessage={(st) => "Loading..."}
				cacheOptions={false}
				clearValue={false}
				defaultOptions={defaultOptions}
				isMulti={false}
				onChange={onChange}
				isDisabled={false}
				isClearable={false}
				disabled={disabled}
				theme={(theme) => ({
					...theme,
					borderRadius: 0,
					border: "none",
					boxShadow: "none",

					colors: {
						...theme.colors,
					},
				})}
			/>
		</>
	)
}
