import React from "react"
import { Tag } from "antd"

export const statusToJsxUtil = (status: string) => {
	return status?.toLowerCase() === "active" ||
		status?.toLowerCase() !== "pending" ? (
		<Tag color="#87d068">{status}</Tag>
	) : (
		<Tag color="#f50">{status}</Tag>
	)
}
export const boolToJsxUtil = (status: boolean) => {
	return status === true ? (
		<Tag color="#87d068">Yes</Tag>
	) : (
		<Tag color="#f50">No</Tag>
	)
}
export const statusToJsx = (status: string, positive: string) => {
	return status?.toLowerCase() === positive.toLowerCase() ? (
		<Tag color="#87d068">{status}</Tag>
	) : (
		<Tag color="#ef8e07">{status}</Tag>
	)
}
