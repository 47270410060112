import React, { Fragment } from "react"

interface Props {
	icon: React.ReactNode
	title: string
	flatMenu?: boolean
	onClick?: any
}
export const SidebarNav: React.FC<Props> = ({
	onClick,
	icon,
	title,
	flatMenu,
}) => {
	return (
		<Fragment>
			<div
				onClick={onClick}
				className={`left-sidebar-option ${flatMenu && "flat-style"}`}>
				<div className="left-sidebar-option-icon">{icon}</div>
				<div className="left-sidebar-option-title">
					<h4>{title}</h4>
				</div>
			</div>
		</Fragment>
	)
}
