import { AiOutlineAccountBook, AiOutlineClose } from "react-icons/ai"
import {
	CommonTitle,
	DebounceSearchInputField,
	Purify,
} from "@shared/components"
import { Modal, Space, Tag } from "antd"
import React, { useState } from "react"
import {
	useSMSMaskGatewayAssign,
	useSMSMaskGatewayDelete,
	useSMSMaskGetGateways,
	useSmsGatewaysFilterWithSMSMask,
} from "@shared/services"

import { PlusOutlined } from "@ant-design/icons"
import { isEmpty } from "lodash"

interface IFProps {
	maskId?: string
}
const SmsMaskGatewayAssign: React.FC<IFProps> = ({ maskId }) => {
	const smsMaskGetGateways = useSMSMaskGetGateways({ id: maskId })

	const [searchTerm, setSearchTerm] = useState("")
	const smsGatewaysFilterWithSMSMaskQuery = useSmsGatewaysFilterWithSMSMask({
		options: { searchTerm },
	})

	const smsMaskGatewayAssignMutation = useSMSMaskGatewayAssign({})

	const onSubmitAssignAgainstAnMask = (id: string) => {
		Modal.confirm({
			title: "Are You Sure ?",
			onOk: () =>
				smsMaskGatewayAssignMutation.mutate({
					mask: maskId,
					smsGateway: [id],
				}),
		})
	}

	const smsMaskGatewayDelete = useSMSMaskGatewayDelete({})
	const onSubmitUserUnAssignAgainstAnMask = (userId: string) => {
		Modal.confirm({
			title: "Are You Sure ?",
			onOk: () => smsMaskGatewayDelete.mutate([userId]),
		})
	}

	return (
		<>
			<CommonTitle title="Gateway Assign" icon={<AiOutlineAccountBook />} />
			<Space
				style={{
					display: "flex",
					justifyContent: "center",
					marginTop: 30,
					marginBottom: 30,
				}}>
				<DebounceSearchInputField
					style={{ maxWidth: 270 }}
					onChange={(term) => setSearchTerm(term)}
				/>
			</Space>
			<Purify
				hide="empty"
				loading={smsGatewaysFilterWithSMSMaskQuery.isLoading}
				empty={isEmpty(smsGatewaysFilterWithSMSMaskQuery?.data?.data?.data)}>
				{smsGatewaysFilterWithSMSMaskQuery?.data?.data?.data?.map(
					(x: any, i: any) => (
						<Tag style={{ margin: 10 }} color="#2db7f5">
							<PlusOutlined onClick={() => onSubmitAssignAgainstAnMask(x.id)} />
							{x?.name}
						</Tag>
					)
				)}
			</Purify>
			<CommonTitle title="Users List " />
			<Purify
				hide="empty"
				loading={smsMaskGetGateways.isLoading}
				empty={isEmpty(smsMaskGetGateways.data?.data?.data)}>
				{smsMaskGetGateways.data?.data?.data?.map((x: any, i: any) => (
					<Tag
						style={{ margin: 10, display: "inline-flex", alignItems: "center" }}
						color="#108ee9">
						{x?.gateway?.name}
						<AiOutlineClose
							style={{ cursor: "pointer" }}
							onClick={() => onSubmitUserUnAssignAgainstAnMask(x.id)}
						/>
					</Tag>
				))}
			</Purify>
		</>
	)
}
export default SmsMaskGatewayAssign
