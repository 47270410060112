import { Button, Col, Divider, PageHeader, Row, Tabs } from "antd"
import React, { useState } from "react"
import { useNavigate, useParams } from "react-router-dom"

import { AppContent } from "@shared/layout"
import { Paths } from "@shared/constant"
import { Purify } from "@shared/components"
import SmsMaskGatewayAssign from "../components/SmsMaskGatewayAssign"
import SmsMaskOverview from "../components/SmsMaskOverview"
import SmsMaskUserAssign from "../components/SmsMaskUserAssign"
import UpdateSmsMask from "../components/UpdateSmsMask"

enum TabsName {
	UserAssign = "USER_ASSIGN",
	GatewayAssign = "GATEWAY_ASSIGN",
}
const { TabPane } = Tabs

const SmsMaskDetailPage = () => {
	const navigator = useNavigate()
	let { id }: any = useParams()
	const [swipeView, setSwipeView] = useState(false)

	return (
		<AppContent>
			<PageHeader
				onBack={() => navigator(Paths.MaskingList)}
				title="Mask Detail"
			/>
			<Row gutter={{ xl: 40, lg: 40, md: 10, sm: 10 }}>
				<Col xl={8} lg={12} md={24} sm={24} xs={24}>
					{!swipeView ? (
						<>
							<SmsMaskOverview />
							<Divider />
							<Button type="primary" onClick={() => setSwipeView(!swipeView)}>
								Update
							</Button>
						</>
					) : (
						<>
							<Button
								type="dashed"
								danger
								onClick={() => setSwipeView(!swipeView)}>
								Back
							</Button>
							<Divider />
							<UpdateSmsMask />
						</>
					)}
				</Col>
				<Col xl={16} lg={12} md={24} sm={24} xs={24}>
					<Purify
						loading={false}
						empty={false}
						style={{
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
							minHeight: 700,
						}}>
						<Tabs onChange={(name: TabsName) => {}}>
							<TabPane tab="User Assign" key={TabsName.UserAssign}>
								<SmsMaskUserAssign maskId={id} />
							</TabPane>
							<TabPane tab="Gateway Assign" key={TabsName.GatewayAssign}>
								<SmsMaskGatewayAssign maskId={id} />
							</TabPane>
						</Tabs>
					</Purify>
				</Col>
			</Row>
		</AppContent>
	)
}

export default SmsMaskDetailPage
