import { Button, Form, Input, Switch } from "antd"

import React from "react"

interface IInitialValue {
	name: string
	maskRate: number
	nonMaskRate: number
	status: boolean
}

interface IFProps {
	onFinish?: (val: IInitialValue) => void
	initialValue?: IInitialValue
	loading?: boolean
	form?: any
}
const SmsPackageForm: React.FC<IFProps> = ({
	initialValue,
	onFinish,
	loading,
	form,
}) => {
	return (
		<>
			<Form
				form={form}
				name="basic"
				initialValues={initialValue}
				layout="vertical"
				onFinish={onFinish}>
				<Form.Item
					label="Package Name"
					name="name"
					rules={[
						{ required: true, message: "Please input your package name!" },
					]}>
					<Input size="large" placeholder="Enter Your package Name" />
				</Form.Item>
				<Form.Item
					label="Mask Rate"
					name="maskRate"
					rules={[
						{ required: true, message: "Please input your maskRate!" },
						{ min: 0, message: "Invalid value" },
					]}>
					<Input
						type="number"
						min="0"
						size="large"
						placeholder="Enter Your maskRate"
					/>
				</Form.Item>
				<Form.Item
					label="Non Mask Rate"
					name="nonMaskRate"
					rules={[
						{ required: true, message: "Please input your nonMaskRate!" },
						{ min: 0, message: "Invalid value" },
					]}>
					<Input
						size="large"
						type="number"
						min="0"
						placeholder="Enter Your nonMaskRate"
					/>
				</Form.Item>
				<Form.Item label="Status" name="status">
					<Switch defaultChecked={initialValue.status} />
				</Form.Item>
				<Form.Item>
					<Button loading={loading} type="primary" htmlType="submit">
						Submit
					</Button>
				</Form.Item>
			</Form>
		</>
	)
}

export default SmsPackageForm
