import {
	IFilterUserWithMask,
	IGatewayAssign,
	IUserMaskAssign,
} from "./../interfaces/user.interfaces"
import {
	IFilterUsers,
	IGetMask,
	IGetUserMaskX,
	IUpdate,
	IUserGetMaskX,
	IUserPackageUpdate,
	IUserStatusUpdate,
	IUserUpdate,
} from "@shared/interfaces"
import { MutationConfig, QueryConfig, queryClient } from "@shared/config"
import { SmsGatewaysServiceV2, SmsMaskServiceV2 } from "."
import { useMutation, useQuery } from "react-query"

import { CoreAxiosInstanceV2 } from "./../config/service/core.axios.v2"
import { concatQuery } from "@shared/utils"

const END_POINT = "/users/"
export const UsersService = {
	NAME: "UsersService",
	userInfo(id: string) {
		return CoreAxiosInstanceV2.get(`${END_POINT}${id}`)
	},
}
export const UsersServiceV2 = {
	NAME: "UsersService",
	userGatewayDelete(ids: string[]) {
		return CoreAxiosInstanceV2.post(`${END_POINT}gatewayDelete`, { ids })
	},
	filter(options: IFilterUsers) {
		return CoreAxiosInstanceV2.get(`${END_POINT}filter?${concatQuery(options)}`)
	},
	filterWithMask(options: IFilterUserWithMask) {
		return CoreAxiosInstanceV2.get(
			`${END_POINT}filterWithMask?${concatQuery(options)}`
		)
	},
	userGatewayAssign(payload: IGatewayAssign) {
		return CoreAxiosInstanceV2.post(`${END_POINT}gatewayAssign`, payload)
	},
	userGateways(userId: string) {
		return CoreAxiosInstanceV2.get(`${END_POINT}getGateway/${userId}`)
	},
	uploadImage(payload: FormData) {
		return CoreAxiosInstanceV2.post(`${END_POINT}uploadImage`, payload, {
			headers: { "Content-Type": "multipart/form-data" },
		})
	},
	update({ id, payload }: IUpdate<IUserUpdate>) {
		return CoreAxiosInstanceV2.put(`${END_POINT}${id}`, payload)
	},

	packUpdate(payload: IUserPackageUpdate) {
		return CoreAxiosInstanceV2.post(`${END_POINT}packageAssign`, payload)
	},
	getUserMask(id: string, options: IGetUserMaskX) {
		return CoreAxiosInstanceV2.get(
			`${END_POINT}getMask/${id}?${concatQuery(options)}`
		)
	},
	bulkUpdateStatus(payload: IUserStatusUpdate) {
		return CoreAxiosInstanceV2.post(`${END_POINT}bulkUpdateStatus`, payload)
	},
	getMask({ id, options }: IGetMask) {
		return CoreAxiosInstanceV2.get(
			`${END_POINT}getMask/${id}?${concatQuery(options)}}`
		)
	},
	delete(id: string) {
		return CoreAxiosInstanceV2.delete(`${END_POINT}${id}`)
	},
	findById(id: string) {
		return CoreAxiosInstanceV2.get(`${END_POINT}${id}`)
	},

	maskAssign(payload: IUserMaskAssign) {
		return CoreAxiosInstanceV2.post(`${END_POINT}maskAssign`, payload)
	},
}

interface GatewayDelete {
	config?: MutationConfig<typeof UsersServiceV2.userGatewayDelete>
}
export const useGatewayDelete = (config: GatewayDelete) => {
	const INVALID_QUERY_KEY = `${UsersServiceV2.NAME}${UsersServiceV2.userGateways.name}`
	const INVALID_QUERY_KEY2 = `${SmsGatewaysServiceV2.NAME}${SmsGatewaysServiceV2.filterWithUser.name}`
	return useMutation({
		...config,
		mutationFn: UsersServiceV2.userGatewayDelete,
		onSettled: () => {
			queryClient.invalidateQueries(INVALID_QUERY_KEY)
			queryClient.invalidateQueries(INVALID_QUERY_KEY2)
		},
	})
}

interface GatewayAssign {
	config?: MutationConfig<typeof UsersServiceV2.userGatewayAssign>
}
export const useGatewayAssign = (config: GatewayAssign) => {
	const INVALID_QUERY_KEY = `${UsersServiceV2.NAME}${UsersServiceV2.userGateways.name}`
	const INVALID_QUERY_KEY2 = `${SmsGatewaysServiceV2.NAME}${SmsGatewaysServiceV2.filterWithUser.name}`
	return useMutation({
		...config,
		mutationFn: UsersServiceV2.userGatewayAssign,
		onSettled: () => {
			queryClient.invalidateQueries(INVALID_QUERY_KEY)
			queryClient.invalidateQueries(INVALID_QUERY_KEY2)
		},
	})
}
interface MaskAssign {
	config?: MutationConfig<typeof UsersServiceV2.maskAssign>
}
export const useUseMaskAssign = (config: MaskAssign) => {
	const INVALID_QUERY_KEY = `${UsersServiceV2.NAME}${UsersServiceV2.getUserMask.name}`
	const INVALID_QUERY_KEY2 = `${SmsMaskServiceV2.NAME}${SmsMaskServiceV2.filterWithUser.name}`
	return useMutation({
		...config,
		mutationFn: UsersServiceV2.maskAssign,
		onSettled: () => {
			queryClient.invalidateQueries(INVALID_QUERY_KEY)
			queryClient.invalidateQueries(INVALID_QUERY_KEY2)
		},
	})
}

interface UseGateways {
	config?: QueryConfig<typeof UsersServiceV2.userGateways>
	userId?: string
}
export const useUserGateways = ({ config, userId }: UseGateways = {}) => {
	const KEY = `${UsersServiceV2.NAME}${UsersServiceV2.userGateways.name}`
	return useQuery({
		...config,
		queryKey: [KEY, userId],
		queryFn: () => UsersServiceV2.userGateways(userId),
	})
}

interface Filter {
	config?: QueryConfig<typeof UsersServiceV2.filter>
	options?: IFilterUsers
}
export const useUsers = ({ config, options }: Filter = {}) => {
	const KEY = `${UsersServiceV2.NAME}${UsersServiceV2.filter.name}`
	return useQuery({
		...config,
		queryKey: [KEY, Object.values(options)],
		queryFn: () => UsersServiceV2.filter(options),
	})
}

interface FilterWithMask {
	config?: QueryConfig<typeof UsersServiceV2.filterWithMask>
	options?: IFilterUserWithMask
}
export const useUsersFilterWithMask = ({
	config,
	options,
}: FilterWithMask = {}) => {
	const KEY = `${UsersServiceV2.NAME}${UsersServiceV2.filterWithMask.name}`
	return useQuery({
		...config,
		queryKey: [KEY, Object.values(options)],
		queryFn: () => UsersServiceV2.filterWithMask(options),
	})
}

interface Delete {
	config?: MutationConfig<typeof UsersServiceV2.delete>
}
export const useDelete = (config: Delete) => {
	const INVALID_QUERY_KEY = `${UsersServiceV2.NAME}${UsersServiceV2.filter.name}`
	return useMutation({
		...config,
		mutationFn: UsersServiceV2.delete,
		onSettled: () => {
			queryClient.invalidateQueries(INVALID_QUERY_KEY)
		},
	})
}
interface FindById {
	config?: QueryConfig<typeof UsersServiceV2.findById>
	id?: string
}
export const useUser = ({ config, id }: FindById = {}) => {
	const KEY = `${UsersServiceV2.NAME}${UsersServiceV2.findById.name}`
	return useQuery({
		...config,
		queryKey: [KEY, id],
		queryFn: () => UsersServiceV2.findById(id),
	})
}

//?USER UPLOAD IMAGE
type UseUploadImageProps = MutationConfig<typeof UsersServiceV2.uploadImage>
export const useUserUploadImage = (config: UseUploadImageProps) => {
	const INVALID_QUERY_KEY = `${UsersServiceV2.NAME}${UsersServiceV2.findById}`
	return useMutation({
		...config,
		mutationFn: UsersServiceV2.uploadImage,
		onSettled: () => {
			queryClient.invalidateQueries(INVALID_QUERY_KEY)
		},
	})
}
//? USER UPDATE
type UseUserUpdateProps = MutationConfig<typeof UsersServiceV2.update>
export const useUpdateUser = (config: UseUserUpdateProps) => {
	const INVALID_QUERY_KEY = `${UsersServiceV2.NAME}${UsersServiceV2.findById}`
	return useMutation({
		...config,
		mutationFn: UsersServiceV2.update,
		onSettled: () => {
			queryClient.invalidateQueries(INVALID_QUERY_KEY)
		},
	})
}

//? USER PACKAGE UPDATE
type UserPackUpdateProps = MutationConfig<typeof UsersServiceV2.packUpdate>
export const useUserPackageUpdate = (config: UserPackUpdateProps) => {
	const INVALID_QUERY_KEY = `${UsersServiceV2.NAME}${UsersServiceV2.packUpdate.name}`
	return useMutation({
		...config,
		mutationFn: UsersServiceV2.packUpdate,
		onSettled: () => {
			queryClient.invalidateQueries(INVALID_QUERY_KEY)
		},
	})
}
//? GET USER MASK
type UseUserMasksProps = {
	config?: QueryConfig<typeof UsersServiceV2.getUserMask>
	options?: IGetUserMaskX
	id?: string
}
export const useUserMasks = ({ config, options, id }: UseUserMasksProps) => {
	const QUERY_KEY = `${UsersServiceV2.NAME}${UsersServiceV2.getUserMask.name}`
	return useQuery({
		...config,
		queryKey: [QUERY_KEY, Object.values(options), id],
		queryFn: () => UsersServiceV2.getUserMask(id, options),
	})
}
//? USER STATUS UPDATE
type UseStatusUpdateProps = MutationConfig<
	typeof UsersServiceV2.bulkUpdateStatus
>
export const useUseStatusUpdate = (config: UseStatusUpdateProps) => {
	const INVALID_QUERY_KEY = `${UsersServiceV2.NAME}${UsersServiceV2.bulkUpdateStatus.name}`
	return useMutation({
		...config,
		mutationFn: UsersServiceV2.bulkUpdateStatus,
		onSettled: () => {
			queryClient.invalidateQueries(INVALID_QUERY_KEY)
		},
	})
}
//? GET USER AVAILABLE MASKS
type UseUserAvailableMaskProps = {
	config?: QueryConfig<typeof UsersServiceV2.getMask>
	options?: IUserGetMaskX
	id?: string
}
export const useUserAvailableMasks = ({
	config,
	options,
	id,
}: UseUserAvailableMaskProps) => {
	const QUERY_KEY = `${UsersServiceV2.NAME}${UsersServiceV2.getMask.name}`
	return useQuery({
		...config,
		queryKey: [QUERY_KEY, Object.values(options), id],
		queryFn: () => UsersServiceV2.getMask({ id, options }),
	})
}
