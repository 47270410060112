import { Button, Form, Input } from "antd"

import { DebounceOperatorSelectField } from "@shared/components/DebounceOperatorSelectField"
import React from "react"

interface IInitialValue {
	name: string
	operator: { label: string; value: string }
}

interface IFProps {
	onFinish?: (val: any) => void
	initialValue?: IInitialValue
	loading?: boolean
}
const PrefixForm: React.FC<IFProps> = ({ initialValue, onFinish, loading }) => {
	return (
		<>
			<Form
				name="basic"
				initialValues={initialValue}
				layout="vertical"
				onFinish={onFinish}>
				<Form.Item
					label="Prefix Name"
					name="name"
					rules={[
						{ required: true, message: "Please input your Prefix name!" },
					]}>
					<Input size="large" placeholder="Enter Your Prefix Name" />
				</Form.Item>
				<Form.Item
					label="Operator Name"
					name="operator"
					rules={[
						{ required: true, message: "Please input your Operator name!" },
					]}>
					<DebounceOperatorSelectField defaultValue={initialValue.operator} />
				</Form.Item>
				<Form.Item>
					<Button loading={loading} type="primary" htmlType="submit">
						Submit
					</Button>
				</Form.Item>
			</Form>
		</>
	)
}

export default PrefixForm
