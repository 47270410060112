import {
	CoreAxiosInstanceV2,
	MutationConfig,
	QueryConfig,
	queryClient,
} from "@shared/config"
import {
	IContactBulkDelete,
	IContactBulkUpdate,
	IContactFilter,
	ICreateContact,
	IGetSmsDetails,
	IUpdate,
} from "@shared/interfaces"
import { useMutation, useQuery } from "react-query"

import { ContactGroupServiceV2 } from "."
import { concatQuery } from "@shared/utils"

const END_POINT = "/contacts/"
export const ContactServiceV2 = {
	NAME: "ContactService",
	filter(options: IContactFilter) {
		return CoreAxiosInstanceV2.get(`${END_POINT}filter?${concatQuery(options)}`)
	},
	getSmsDetails(options: IGetSmsDetails) {
		return CoreAxiosInstanceV2.get(`${END_POINT}getSmsDetails?${concatQuery(
			options
		)}
		`)
	},
	create(payload: ICreateContact) {
		return CoreAxiosInstanceV2.post(END_POINT, payload)
	},
	delete(id: string) {
		return CoreAxiosInstanceV2.delete(`${END_POINT}${id}`)
	},
	update({ id, payload }: IUpdate<ICreateContact>) {
		return CoreAxiosInstanceV2.post(
			`${END_POINT}
			contactUpdate?
			id=${id}`,
			payload
		)
	},
	bulkUpdate(payload: IContactBulkUpdate) {
		return CoreAxiosInstanceV2.post(
			`${END_POINT}contactGroupBulkUpdate`,
			payload
		)
	},
	contactBulkDelete(payload: IContactBulkDelete) {
		return CoreAxiosInstanceV2.post(`${END_POINT}contactBulkDelete`, payload)
	},
	upload(payload: FormData) {
		return CoreAxiosInstanceV2.post(`${END_POINT}uploadFile`, payload, {
			headers: { "Content-Type": "multipart/form-data" },
		})
	},
	getProperties(user: string) {
		return CoreAxiosInstanceV2.get(
			`${END_POINT}getContactProperties?user=${user || ""}`
		)
	},
}
interface ContactBulkDeleteProps {
	config?: MutationConfig<typeof ContactServiceV2.contactBulkDelete>
}
export const useContactBulkDelete = ({ config }: ContactBulkDeleteProps) => {
	return useMutation({
		...config,
		mutationFn: ContactServiceV2.contactBulkDelete,
		onSettled: () => {
			queryClient.invalidateQueries(
				`${ContactServiceV2.NAME}${ContactServiceV2.filter.name}`
			)
			queryClient.invalidateQueries(
				`${ContactGroupServiceV2.NAME}${ContactServiceV2.filter.name}`
			)
		},
	})
}
interface ContactBulkUpdate {
	config?: MutationConfig<typeof ContactServiceV2.bulkUpdate>
}
export const useContactBulkUpdate = ({ config }: ContactBulkUpdate) => {
	return useMutation({
		...config,
		mutationFn: ContactServiceV2.bulkUpdate,
		onSettled: () => {
			queryClient.invalidateQueries(
				`${ContactServiceV2.NAME}${ContactServiceV2.filter.name}`
			)
			queryClient.invalidateQueries(
				`${ContactGroupServiceV2.NAME}${ContactServiceV2.filter.name}`
			)
		},
	})
}
interface ContactProperties {
	config?: QueryConfig<typeof ContactServiceV2.getProperties>
	user: string
}
export const useContactProperties = ({ config, user }: ContactProperties) => {
	const queryName = `${ContactServiceV2.NAME}${ContactServiceV2.getProperties.name}`
	return useQuery({
		...config,
		queryKey: [queryName, user],
		queryFn: () => ContactServiceV2.getProperties(user),
	})
}
interface Contacts {
	config?: QueryConfig<typeof ContactServiceV2.filter>
	options?: IContactFilter
}
export const useContacts = ({ config, options }: Contacts = {}) => {
	const queryName = `${ContactServiceV2.NAME}${ContactServiceV2.filter.name}`
	return useQuery({
		...config,
		queryKey: [queryName, Object.values(options)],
		queryFn: () => ContactServiceV2.filter(options),
	})
}
interface CreateContact {
	config?: MutationConfig<typeof ContactServiceV2.create>
}
export const useCreateContact = ({ config }: CreateContact) => {
	return useMutation({
		...config,
		mutationFn: ContactServiceV2.create,
		onSettled: () => {
			queryClient.invalidateQueries(
				`${ContactServiceV2.NAME}${ContactServiceV2.filter.name}`
			)
		},
	})
}
interface DeleteContact {
	config?: MutationConfig<typeof ContactServiceV2.delete>
}
export const useDeleteContact = ({ config }: DeleteContact) => {
	return useMutation({
		...config,
		mutationFn: ContactServiceV2.delete,
		onSettled: () => {
			queryClient.invalidateQueries(
				`${ContactServiceV2.NAME}${ContactServiceV2.filter.name}`
			)
			queryClient.invalidateQueries(
				`${ContactGroupServiceV2.NAME}${ContactServiceV2.filter.name}`
			)
		},
	})
}
interface SmsDetails {
	config?: QueryConfig<typeof ContactServiceV2.getSmsDetails>
	options?: IGetSmsDetails
}
export const useSmsDetails = ({ config, options }: SmsDetails = {}) => {
	const queryName = `${ContactServiceV2.NAME}${ContactServiceV2.filter.name}`
	return useQuery({
		...config,
		queryKey: [queryName, Object.values(options)],
		queryFn: () => ContactServiceV2.getSmsDetails(options),
	})
}
interface UpdateContact {
	config?: MutationConfig<typeof ContactServiceV2.update>
}
export const useUpdateContact = ({ config }: UpdateContact) => {
	return useMutation({
		...config,
		mutationFn: ContactServiceV2.update,
		onSettled: () => {
			queryClient.invalidateQueries(
				`${ContactServiceV2.NAME}${ContactServiceV2.filter.name}`
			)
			queryClient.invalidateQueries(
				`${ContactGroupServiceV2.NAME}${ContactServiceV2.filter.name}`
			)
		},
	})
}
interface UploadContactFile {
	config?: MutationConfig<typeof ContactServiceV2.upload>
}
export const useUploadContactFile = ({ config }: UploadContactFile) => {
	return useMutation({
		...config,
		mutationFn: ContactServiceV2.upload,
		onSettled: () => {
			queryClient.invalidateQueries(
				`${ContactServiceV2.NAME}${ContactServiceV2.filter.name}`
			)
			queryClient.invalidateQueries(
				`${ContactGroupServiceV2.NAME}${ContactServiceV2.filter.name}`
			)
		},
	})
}
interface DeleteContactGroup {
	config?: MutationConfig<typeof ContactGroupServiceV2.delete>
}
export const useDeleteContactGroup = ({ config }: DeleteContactGroup) => {
	return useMutation({
		...config,
		mutationFn: ContactGroupServiceV2.delete,
		onSettled: () => {
			queryClient.invalidateQueries(
				`${ContactGroupServiceV2.NAME}${ContactGroupServiceV2.filter.name}`
			)
		},
	})
}
