import AuthPage from "./AuthPage"
import { Navigate } from "react-router-dom"
import { Paths } from "@shared/constant"
import React from "react"
import { RouteObject } from "react-router"

export const AuthRoutes: RouteObject[] = [
	{ path: "", element: <Navigate to={Paths.AuthLoginPage} /> },
	{ path: "login", element: <AuthPage /> },
]
