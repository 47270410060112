import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
	visibilityAppLeftSidebarAction,
	visibilityAppRightSidebarAction,
} from "@shared/redux/layout/app-layout.actions"

import { AppHeader } from "./AppHeader"
import { Layout } from "antd"
import LeftSidebar from "./LeftSidebar"
import { createStructuredSelector } from "reselect"
import { selectAppSidebarToggle } from "@shared/redux/layout/app-layout.selectors"
import { uiEventHandler } from "@shared/utils/uiUtils"
import { useResponsive } from "ahooks"

const { Sider } = Layout

interface IFProps {
	children: any
	className?: string
}
export const AppLayout: React.FC<IFProps> = ({ className, children }) => {
	const responsive = useResponsive()
	const dispatch = useDispatch()
	const { appLayoutSidebar } = useSelector(
		createStructuredSelector({
			appLayoutSidebar: selectAppSidebarToggle,
		})
	)
	const { isOpenLeftSidebar } = appLayoutSidebar
	const isMobileView = responsive.lg === false
	useEffect(() => {
		uiEventHandler()
		if (isMobileView) {
			dispatch(visibilityAppLeftSidebarAction(true))
			dispatch(visibilityAppRightSidebarAction(true))
		}
		// eslint-disable-next-line
	}, [])
	return (
		<>
			<Layout className={`app-layout ${className}`}>
				<Sider
					className="app-sidebar"
					width={100}
					trigger={null}
					defaultCollapsed={true}
					collapsed={isOpenLeftSidebar}>
					<LeftSidebar />
				</Sider>
				<AppHeader />
				{children}
			</Layout>
		</>
	)
}
