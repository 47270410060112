import { applyMiddleware, createStore } from "redux"

import { ENV } from "@ENV"
import logger from "redux-logger"
import { persistStore } from "redux-persist"
import rootReducer from "./sourceReducer"
import thunk from "redux-thunk"

const middleware = [thunk]
if (process.env.NODE_ENV === "development" && ENV.REDUX_LOGGER) {
	middleware.push(logger as any)
}

export const store: any = createStore(
	rootReducer,
	applyMiddleware(...middleware)
)

export const persistor = persistStore(store)
