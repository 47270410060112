import { Button, Space, Table } from "antd"
import React, { useState } from "react"

import UserBalanceAssign from "./UserBalanceAssign"
import UserBalanceDeduct from "./UserBalanceDeduct"
import UserBalanceLog from "./UserBalanceLog"
import { timeFormatUtil } from "@shared/utils"
import { useTransactionLogs } from "@shared/services"

interface IFProps {
	userId: string
}
const UserBalanceUpdate: React.FC<IFProps> = ({ userId }) => {
	const [uiState, setUiState] = useState({
		assignPopup: false,
		deductPopup: false,
	})
	const { assignPopup, deductPopup } = uiState
	const [options, setOptions] = useState({
		page: 1,
		take: 10,
		searchTerm: "",
	})
	const transactionLogs = useTransactionLogs({
		options: {
			...options,
			user: userId,
		},
	})
	const onChangePage = (page: number) => {
		setOptions({
			...options,
			page,
		})
	}
	const tableData: any = transactionLogs?.data?.data?.data?.map(
		(x: any, idx: number) => ({
			key: idx,
			remarks: x?.remarks,
			newAmount: x?.newAmount,
			updatedAt: timeFormatUtil(x?.updatedAt, "DD-MM-YYYY hh:mm:ss A"),
			actions: <UserBalanceLog info={x} />,
		})
	)
	return (
		<>
			<Space wrap>
				<Button
					onClick={() => setUiState({ ...uiState, assignPopup: true })}
					type="primary"
					size="middle">
					Assign Balance{" "}
				</Button>
				<Button
					onClick={() => setUiState({ ...uiState, deductPopup: true })}
					type="primary"
					size="middle">
					Deduct Balance
				</Button>
			</Space>
			<Table
				className="mt-30"
				sticky
				scroll={{ x: 800 }}
				loading={transactionLogs.isLoading}
				pagination={{
					onChange: (e: any) => onChangePage(e),
					pageSize: transactionLogs?.data?.data?.take,
					total: transactionLogs?.data?.data?.total,
					showSizeChanger: false,
				}}
				columns={columns}
				dataSource={tableData}
			/>
			<UserBalanceAssign
				userId={userId}
				open={assignPopup}
				onClose={() => {
					setUiState({ ...uiState, assignPopup: false })
				}}
			/>
			<UserBalanceDeduct
				userId={userId}
				open={deductPopup}
				onClose={() => {
					setUiState({ ...uiState, deductPopup: false })
				}}
			/>
		</>
	)
}
export default UserBalanceUpdate
const columns: any = [
	{
		title: "Remarks",
		dataIndex: "remarks",
		width: 100,
	},
	{
		title: "New Amount",
		dataIndex: "newAmount",
		width: 170,
	},
	{
		title: "Updated At",
		dataIndex: "updatedAt",
		width: 150,
	},
	{
		title: "View",
		dataIndex: "actions",
		align: "right",
		width: 80,
	},
]
