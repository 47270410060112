export const ENV = {
  ANDROMEDA_END_POINT: 'http://45.118.134.38:5555/andromeda/',
  ANTARES_END_POINT: 'https://antares.apxify.com/api/v1/',
  CORE_END_POINT: 'http://45.118.134.38:3011/api/v1',
  SOCKET_API_END_POINT: 'http://45.118.134.38:3011',
  FILE_DOWNLOAD_URL: 'http://45.118.134.38:5555/csv/',
  ANALYTICS_END_POINT: 'https://analytics-api.apxify.com/api/v1/',
  MAC_API_END_POINT: 'https://mca-api-staging.apxify.com/api/v1/',

  REFRESH_TOKEN_EXPIRE_TIME: 300000,
  SESSION_PREFIX: '547h1_',
  RUNNING_TASK_REFETCH_TIME: 4000,
  SMS_CAMPAIGN_PASTE_NUMBER_MAX_LIMIT: 2000,
  SMS_CHARACTER_COUNT: 160,
  SMS_MAX_CHARACTER_LIMIT: 850,
  CURRENCY_SIGN: '৳',

  CONTACT_SAMPLE_FILE_PATH: '/xls/SAMPLE_CONTACT_IMPORT.csv',
  NUMBER_SAMPLE_FILE_PATH: '/xls/SAMPLE_CREATE_CAMPAIGN_NUMBER_ONLY.csv',
  ENTERPRISE_API_SPECIFICATION: '/pdf/api-specification-v2.pdf',
  NUMBER_WITH_BODY_SAMPLE_FILE_PATH:
    '/xls/SAMPLE_CREATE_CAMPAIGN_NUMBER_WITH_BODY.csv',

  NUMBER_FILE_REQUIRED_HEADERS: ['number'],
  NUMBER_WITH_BODY_FILE_REQUIRED_HEADERS: ['number', 'body'],
  CONTACTIMPORTFILEREQUIREDHEADERS: [
    'number',
    'firstName',
    'lastName',
    'company',
    'address',
    'gender',
    'email',
  ],

  ADMIN_UUID: '01f0c4d2-785e-4e3f-b224-96021bb555ce',
  BD_PHONE_REGEX: /^(?:\+?88)?01[3-9]\d{8}$/g,
  REDUX_LOGGER: true,
};
