import { AppContent, AppHeader } from "@shared/layout"
import { Button, Input, PageHeader, Space, Table } from "antd"
import {
	DebounceUserSelectField,
	Purify,
	RangeDatePickerOptions,
	SmsDetails,
} from "@shared/components"
import { IDatePicker, IOption } from "@shared/interfaces"
import React, { useState } from "react"
import {
	getDurationUtil,
	getSession,
	smsEncodedUtil,
	statusToJsx,
} from "@shared/utils"

import { DeliveryStatus } from "@shared/enums"
import SmsOverviewBarChart from "../components/SmsOverviewBarChart"
import moment from "moment"
import { useDebounceFn } from "ahooks"
import { useNavigate } from "react-router-dom"
import { useSmsOverview } from "@shared/services"
const { Search } = Input
const SmsOverviewPage = () => {
	const navigator = useNavigate()
	const loginUser: any = getSession()
	const [hitLoad, setHitLoad] = useState({
		user: loginUser?.id,
		start: moment().format("YYYY-MM-DD"),
		end: moment().format("YYYY-MM-DD"),
		searchTerm: "",
		page: 0,
		take: 0,
	})
	const { end, start, page, take } = hitLoad
	const dateDifference = moment(end).diff(moment(start), "days")
	const smsOverviewQuery = useSmsOverview({
		options: {
			user: hitLoad?.user,
			recipient: hitLoad.searchTerm,
			deliveryStatus: DeliveryStatus.ALL,
			duration: getDurationUtil(dateDifference),
			startDate: moment(start).format("YYYY-MM-DD"),
			endDate: moment(end).format("YYYY-MM-DD"),
			page,
			take,
		},
		config: {
			keepPreviousData: false,
		},
	})
	const { run } = useDebounceFn(
		(term: any) => {
			setHitLoad({
				...hitLoad,
				searchTerm: term,
			})
		},
		{
			wait: 500,
		}
	)
	const tableData: any = smsOverviewQuery?.data?.data?.data?.smsEntries?.map(
		(sms: any, idx: number) => ({
			key: idx,
			sendFrom: sms?.mask?.mask,
			recipient: sms?.recipient,
			body: smsEncodedUtil(sms?.body?.slice(0, 12)) + "...",
			status: statusToJsx(sms?.deliveryStatus, "SUCCESS"),
			actions: <LiveLogTableActions sms={sms} />,
		})
	)
	const successSmsCount =
		smsOverviewQuery?.data?.data?.data?.charts?.success || []
	const pendingSmsCount =
		smsOverviewQuery?.data?.data?.data?.charts?.pending || []
	const smsTimesCount = smsOverviewQuery?.data?.data?.data?.charts?.times || []
	return (
		<>
			<AppHeader />
			<AppContent>
				<PageHeader
					onBack={() => navigator(-1)}
					title="SMS Overview"
					extra={[
						<div style={{ minWidth: 270 }}>
							<DebounceUserSelectField
								onChange={(val: IOption) => {
									setHitLoad({
										...hitLoad,
										user: val?.value?.id,
									})
								}}
							/>
						</div>,
					]}
				/>
				<Space wrap align="center">
					<RangeDatePickerOptions
						onChangeStartEndDate={(range: IDatePicker) => {
							setHitLoad({
								...hitLoad,
								start: range.start,
								end: range.end,
							})
						}}
					/>
					<Search
						placeholder="Search "
						allowClear
						enterButton="Search"
						size="large"
						onChange={(e: any) => {
							run(e.target.value)
						}}
					/>
				</Space>
				<Purify
					style={{
						minHeight: 300,
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
					}}
					loading={smsOverviewQuery.isLoading}
					empty={false}>
					<SmsOverviewBarChart
						success={successSmsCount}
						pending={pendingSmsCount}
						times={smsTimesCount}
					/>
				</Purify>
				<Table
					sticky
					scroll={{ x: 800 }}
					loading={smsOverviewQuery.isLoading}
					pagination={{
						current: smsOverviewQuery?.data?.data?.page,
						pageSize: smsOverviewQuery?.data?.data?.take,
						total: smsOverviewQuery?.data?.data?.total,
						showSizeChanger: true,
					}}
					onChange={(pagination, filter, sorter) => {
						setHitLoad({
							...hitLoad,
							page: pagination.current,
							take: pagination.pageSize,
						})
					}}
					columns={columns}
					dataSource={tableData}
				/>
			</AppContent>
		</>
	)
}
export default SmsOverviewPage
const columns: any = [
	{
		title: "Send From",
		dataIndex: "sendFrom",
		width: 100,
	},
	{
		title: "Recipient",
		dataIndex: "recipient",
		width: 170,
	},
	{
		title: "Body",
		dataIndex: "body",
		width: 100,
	},
	{
		title: "Status",
		dataIndex: "status",
		width: 100,
	},
	{
		title: "Actions",
		dataIndex: "actions",
		align: "right",
		width: 80,
	},
]
interface ISmsProps {
	sms: any
}
const LiveLogTableActions: React.FC<ISmsProps> = ({ sms }) => {
	const [uiState, setUiState] = useState({
		viewDetailsOpen: false,
	})
	const { viewDetailsOpen } = uiState
	return (
		<>
			<Button
				type="primary"
				onClick={() => setUiState({ ...uiState, viewDetailsOpen: true })}>
				Details
			</Button>
			<SmsDetails
				smsId={sms.id}
				open={viewDetailsOpen}
				onClose={() => setUiState({ ...uiState, viewDetailsOpen: false })}
			/>
		</>
	)
}
