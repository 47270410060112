import { Button, Col, PageHeader, Popconfirm, Row, Tabs } from "antd"
import { useNavigate, useParams } from "react-router-dom"

import { AppContent } from "@shared/layout"
import CreateSmpp from "../components/CreateSmpp"
import { Paths } from "@shared/constant"
import React from "react"
import UserApiKey from "../components/UserApiKey"
import UserBalanceUpdate from "../components/UserBalanceUpdate"
import UserGatewayAssign from "../components/UserGatewayAssign"
import UserMaskAssign from "../components/UserMaskAssign"
import UserOverview from "../components/UserOverview"
import UserPackageUpdate from "../components/UserPackageUpdate"
import UserPasswordChange from "../components/UserPasswordChange"
import { useDelete } from "@shared/services"

const { TabPane } = Tabs
enum TabNames {
	Overview = "OVERVIEW",
	MaskAssign = "MaskAssign",
	GatewayAssign = "GatewayAssign",
	PackageUpdate = "PackageUpdate",
	BalanceUpdate = "BalanceUpdate",
	PasswordUpdate = "PasswordUpdate",
	ApiKey = "ApiKey",
	Smpp = "Smpp",
}
const UserDetailPage = () => {
	const navigator = useNavigate()
	let { id }: any = useParams()
	const userDeleteMutation = useDelete({})
	return (
		<AppContent>
			<PageHeader
				onBack={() => navigator(Paths.UsersList)}
				title="User Detail"
				extra={[
					<Popconfirm
						title="Are you sure?"
						okText="Yes"
						cancelText="No"
						onConfirm={() => {
							userDeleteMutation.mutate(id)
						}}>
						<Button
							loading={userDeleteMutation.isLoading}
							type="primary"
							danger>
							Delete
						</Button>
					</Popconfirm>,
				]}
			/>
			<Row gutter={{ xl: 40, lg: 40, md: 10, sm: 10 }}>
				<Col xl={8} lg={12} md={24} sm={24} xs={24}>
					<UserOverview userId={id} />
				</Col>
				<Col xl={16} lg={12} md={24} sm={24} xs={24}>
					<Tabs defaultActiveKey={TabNames.MaskAssign}>
						<TabPane tab="Mask assign" key={TabNames.MaskAssign}>
							<UserMaskAssign userId={id} />
						</TabPane>
						<TabPane tab="Gateway assign" key={TabNames.GatewayAssign}>
							<UserGatewayAssign userId={id} />
						</TabPane>
						<TabPane tab="Package " key={TabNames.PackageUpdate}>
							<UserPackageUpdate userId={id} />
						</TabPane>
						<TabPane tab="Balance " key={TabNames.BalanceUpdate}>
							<UserBalanceUpdate userId={id} />
						</TabPane>
						<TabPane tab="Security" key={TabNames.PasswordUpdate}>
							<UserPasswordChange userId={id} />
						</TabPane>
						<TabPane tab="API" key={TabNames.ApiKey}>
							<UserApiKey userId={id} />
						</TabPane>
						<TabPane tab="Smpp" key={TabNames.Smpp}>
							<CreateSmpp userId={id} />
						</TabPane>
					</Tabs>
				</Col>
			</Row>
		</AppContent>
	)
}
export default UserDetailPage
