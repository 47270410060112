import { AppContent } from "@shared/layout"
import CreatePattern from "../components/CreatePattern"
import { PageHeader } from "antd"
import Patterns from "../components/Patterns"
import React from "react"
import { useNavigate } from "react-router-dom"

const PatternPage = () => {
	const navigator = useNavigate()

	return (
		<AppContent>
			<PageHeader onBack={() => navigator(-1)} title="Analytics Patterns" />
			<CreatePattern />
			<Patterns />
		</AppContent>
	)
}

export default PatternPage
