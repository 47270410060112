import {
	boolToJsxUtil,
	statusToJsxUtil,
} from "@shared/utils/util-jsx-functions"

import React from "react"
import { Typography } from "antd"
import { useParams } from "react-router-dom"
import { useSMSMask } from "@shared/services"

const { Title } = Typography
const SmsMaskOverview = () => {
	let { id }: any = useParams()
	const smsMaskQuery = useSMSMask({ id })
	return (
		<>
			<Title level={4}>Overview</Title>
			<Title level={5}>
				<span style={{ minWidth: 150, display: "inline-block" }}>
					Mask Name:
				</span>{" "}
				{smsMaskQuery?.data?.data?.data?.mask}
			</Title>
			<Title level={5} type="secondary" style={{ fontSize: 14 }}>
				<span style={{ minWidth: 150, display: "inline-block" }}>Status:</span>{" "}
				{statusToJsxUtil(smsMaskQuery?.data?.data?.data?.status)}
			</Title>
			<Title level={5} type="secondary" style={{ fontSize: 14 }}>
				<span style={{ minWidth: 150, display: "inline-block" }}>
					isMasking:
				</span>{" "}
				{boolToJsxUtil(smsMaskQuery?.data?.data?.data?.isMasking)}
			</Title>
			<Title level={5} type="secondary" style={{ fontSize: 14 }}>
				<span style={{ minWidth: 150, display: "inline-block" }}>
					isDefault:
				</span>{" "}
				{boolToJsxUtil(smsMaskQuery?.data?.data?.data?.isDefault)}
			</Title>
		</>
	)
}

export default SmsMaskOverview
