import {
	ICreateSMPP,
	IGetSMPP,
	IUpdateSMPP,
} from "@shared/interfaces/smpp.interfaces"
import { MutationConfig, QueryConfig, queryClient } from "@shared/config"
import { useMutation, useQuery } from "react-query"

import { CoreAxiosInstanceV2 } from "@shared/config/service/core.axios.v2"
import { IUpdate } from "./../interfaces/base.interfaces"
import { concatQuery } from "@shared/utils"
const END_POINT = "/smpps/"
export const SMPPServiceV2 = {
	NAME: "SMPPServiceV2",
	create(payload: ICreateSMPP) {
		return CoreAxiosInstanceV2.post(`${END_POINT}`, payload)
	},
	filter(options: IGetSMPP) {
		return CoreAxiosInstanceV2.get(`${END_POINT}getOne?${concatQuery(options)}`)
	},
	update({ id, payload }: IUpdate<IUpdateSMPP>) {
		return CoreAxiosInstanceV2.put(`${END_POINT}${id}`, payload)
	},
}
interface CreateSMPP {
	config?: MutationConfig<typeof SMPPServiceV2.create>
}
export const useCreateSMPP = (config: CreateSMPP) => {
	const INVALID_QUERY_KEY = `${SMPPServiceV2.NAME}${SMPPServiceV2.filter.name}`
	return useMutation({
		...config,
		mutationFn: SMPPServiceV2.create,
		onSettled: () => {
			queryClient.invalidateQueries(INVALID_QUERY_KEY)
		},
	})
}
interface SMPP {
	config?: QueryConfig<typeof SMPPServiceV2.filter>
	options?: IGetSMPP
}
export const useSMPP = ({ config, options }: SMPP = {}) => {
	const KEY = `${SMPPServiceV2.NAME}${SMPPServiceV2.filter.name}`
	return useQuery({
		...config,
		queryKey: [KEY, Object.values(options)],
		queryFn: () => SMPPServiceV2.filter(options),
	})
}
interface UpdateSMPP {
	config?: MutationConfig<typeof SMPPServiceV2.update>
}
export const useUpdateSMPP = (config: UpdateSMPP) => {
	const INVALID_QUERY_KEY = `${SMPPServiceV2.NAME}${SMPPServiceV2.filter.name}`
	return useMutation({
		...config,
		mutationFn: SMPPServiceV2.update,
		onSettled: () => {
			queryClient.invalidateQueries(INVALID_QUERY_KEY)
		},
	})
}
