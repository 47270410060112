import { AppContent } from "@shared/layout"
import { PageHeader } from "antd"
import React from "react"
import ResellerRequest from "../components/ResellerRequest"
import { useNavigate } from "react-router-dom"

const ResellerRequestPage = () => {
	const navigator = useNavigate()

	return (
		<>
			<AppContent>
				<PageHeader onBack={() => navigator(-1)} title="Reseller Request" />
				<ResellerRequest />
			</AppContent>
		</>
	)
}

export default ResellerRequestPage
