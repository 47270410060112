import {
	Card as ACard,
	Button,
	Divider,
	Input,
	Modal,
	PageHeader,
	Spin,
	Table,
	Tag,
	Typography,
} from "antd"
import {
	useCreateUserAPIKey,
	useGetAPIKey,
	useUserMetaDataByUser,
} from "@shared/services"

import { AppContent } from "@shared/layout"
import CopyToClipboard from "react-copy-to-clipboard"
import { ENV } from "@ENV"
import React from "react"
import { getSession } from "@shared/utils"
import { useNavigate } from "react-router-dom"

const { Title, Text } = Typography

interface IFProps {}
const ApiKeyDocsPage: React.FC<IFProps> = () => {
	const user: any = getSession()
	const navigator = useNavigate()

	const userMetaDataByUser = useUserMetaDataByUser({
		userId: user.id,
	})

	const getAPIKey = useGetAPIKey({})

	const createUserAPIKey = useCreateUserAPIKey({})

	const onSubmit = () => {
		Modal.confirm({
			title: "Are you sure?",
			content: "Once Generate this, you will not be able to recover this ",
			onOk: () => createUserAPIKey.mutate(user?.id),
		})
	}
	const apiUrl =
		userMetaDataByUser?.data?.data?.data?.apiDomainName || "https://doamin.com"

	return (
		<>
			<AppContent>
				<PageHeader
					onBack={() => navigator(-1)}
					title="Documentation"
					extra={[
						<a
							target="_blank"
							href={ENV.ENTERPRISE_API_SPECIFICATION}
							rel="noopener noreferrer">
							<Button key="1" type="primary">
								Download PDF Version
							</Button>
						</a>,
					]}
				/>

				<Title level={4}>API Specification for Sending SMS</Title>
				{userMetaDataByUser?.data?.data?.data?.apiDomainName?.length ? (
					<>
						<Title level={5} type="success">
							Base End Point:
						</Title>
						<Title
							level={5}
							copyable
							code
							style={{ marginTop: 15, marginBottom: 15 }}>
							{`${apiUrl}`}
						</Title>
					</>
				) : (
					""
				)}
				<Text style={{ display: "block" }}>
					This document will help explaining the specifications which needed to
					be followed in order to send messages and get DLR from We platform.
				</Text>

				<Title level={4} style={{ marginTop: 50 }}>
					Quick shortcuts
				</Title>
				<Text>We provides two types of authentication for API services,</Text>
				<div
					style={{
						display: "flex",
						maxWidth: 750,
						marginBottom: 50,
						marginTop: 50,
						flexWrap: "wrap",
						width: "100%",
						justifyContent: "space-between",
					}}>
					<ACard
						title="Username and password based API authentication"
						style={{ flex: "0 0 48%", maxWidth: "48%", minWidth: 350 }}>
						<Text style={{ fontSize: 18 }}>
							Follow the instructions to register as a developer and then setup
							your application(s). When you’re ready, provided your application
							follows the guidelines.
						</Text>
						<a href="#lnkUsrPass">Jump to documentation</a>{" "}
					</ACard>
					<ACard
						title="API key based API authentication"
						style={{ flex: "0 0 48%", maxWidth: "48%", minWidth: 350 }}>
						<Text style={{ fontSize: 18 }}>
							Follow the instructions to register as a developer and then setup
							your application(s). When you’re ready, provided your application
							follows the guidelines.
						</Text>
						<a href="#lnkKey">Jump to documentation</a>{" "}
					</ACard>
				</div>

				<Divider />
				<Title level={4}>Username and Password based API authentication</Title>
				<Title level={5}>Send message API:</Title>

				<Title level={5}>
					<Tag color="#007bff">GET</Tag>
					<Tag color="#49cc90">POST</Tag>{" "}
					<Text code copyable>{`${apiUrl}/api/v1/channels/sms`}</Text>
				</Title>
				<Title level={5}>Parameters</Title>

				<Table
					scroll={{ x: 700 }}
					pagination={false}
					sticky
					columns={columns}
					dataSource={messageApiData}
				/>

				<div id="lnkUsrPass">
					<Title level={4}>DLR API:</Title>
					<Title level={5}>
						<Tag color="#007bff">GET</Tag>
						<Tag color="#49cc90">POST</Tag>{" "}
						<Text code copyable>{`${apiUrl}/api/v1/channels/sms/dlr`}</Text>
					</Title>

					<Title level={4}>Parameters</Title>
					<Table
						scroll={{ x: 700 }}
						pagination={false}
						sticky
						columns={columns}
						dataSource={dlrApiData}
					/>
				</div>
				<Divider />
				<div id="lnkKey">
					<Title level={4}>API key based API authentication</Title>
					{getAPIKey.isLoading ? (
						<Spin />
					) : (
						<div>
							<div className="d-flex justify-content-center">
								<Input
									size="large"
									value={
										getAPIKey?.data?.data?.data
											? getAPIKey?.data?.data?.data?.key
											: "No Key Found"
									}
									defaultValue={
										getAPIKey?.data?.data?.data
											? getAPIKey?.data?.data?.data?.key
											: "No Key Found"
									}
									readOnly={true}
									id="input_copy1"
								/>
							</div>

							<CopyToClipboard
								text={
									getAPIKey?.data?.data?.data
										? getAPIKey?.data?.data?.data?.key
										: "No Key Found"
								}>
								<div className="d-flex justify-content-center mt-20">
									<Button onClick={onSubmit} type="primary" danger>
										Generate
									</Button>
									<Button style={{ marginLeft: 10 }} type="primary">
										Copy text
									</Button>
								</div>
							</CopyToClipboard>
						</div>
					)}
				</div>

				<Title level={4}>Send message API:</Title>
				<Title level={5}>
					<Tag color="#007bff">GET</Tag>
					<Tag color="#49cc90">POST</Tag>{" "}
					<Text
						code
						copyable>{`${apiUrl}/api/v1/channels/sms?apiKey=XXXXXXXXXXXXXXX`}</Text>
				</Title>
				<Title level={4}>Parameters</Title>
				<Table
					scroll={{ x: 700 }}
					pagination={false}
					sticky
					columns={columns}
					dataSource={messageApiKeyBasedData}
				/>

				<br />
				<br />
				<br />
				<Title level={4}>DLR API:</Title>
				<Title level={5}>
					<Tag color="#007bff">GET</Tag>
					<Tag color="#49cc90">POST</Tag>{" "}
					<Text code copyable>{`${apiUrl}/api/v1/channels/sms/dlr`}</Text>
				</Title>
				<Title level={4}>Parameters</Title>
				<Table
					scroll={{ x: 700 }}
					pagination={false}
					sticky
					columns={columns}
					dataSource={dlrApiKeyBasedData}
				/>

				<Title level={4}>Error Code & Meaning:</Title>

				<Table
					scroll={{ x: 700 }}
					pagination={false}
					sticky
					columns={errCodeColumns}
					dataSource={errorCodeApiData}
				/>
			</AppContent>
		</>
	)
}

export default ApiKeyDocsPage
const messageApiData = [
	{
		key: "3",
		Param: "username",
		Meaning: "User Email",
		Description: "User email used for login into dashboard",
		Type: "String",
		Priority: "Required",
	},
	{
		key: "3",
		Param: "password",
		Meaning: "Password",
		Description: "Password used for login into Dashboard	",
		Type: "String",
		Priority: "Required",
	},
	{
		key: "3",
		Param: "recipient",
		Meaning: "Recipient’s mobile number	",
		Description:
			"11 or 13 Digit Bangladeshi mobile number. Exp: 017XXXXXXXX,8 801XXXXXXXXX	",
		Type: "String",
		Priority: "Required",
	},
	{
		key: "3",
		Param: "from",
		Meaning: "Sender Name	",
		Description: "Approved Sender name	",
		Type: "String	",
		Priority: "Required",
	},
	{
		key: "3",
		Param: "message",
		Meaning: "Message body	",
		Description:
			"160 characters will be considered as One SMS. Please use URL encoding to send some special characters like &, $, @ etc	",
		Type: "String",
		Priority: "Required",
	},
]
const messageApiKeyBasedData = [
	{
		key: "3",
		Param: "apiKey",
		Meaning: "API KEY	",
		Description: "28 character API KEY provided by our team	",
		Type: "String",
		Priority: "Required",
	},
	{
		key: "3",
		Param: "recipient",
		Meaning: "Recipient’s mobile number	",
		Description:
			"11 or 13 Digit Bangladeshi mobile number. Exp: 017XXXXXXXX,8 801XXXXXXXXX",
		Type: "String",
		Priority: "Required",
	},
	{
		key: "3",
		Param: "from",
		Meaning: "Sender Name",
		Description: "Approved Sender name	",
		Type: "String",
		Priority: "Required",
	},
	{
		key: "3",
		Param: "message",
		Meaning: "Message body	",
		Description:
			"160 characters will be considered as One SMS. Please use URL encoding to send some special characters like &, $, @ etc",
		Type: "String	",
		Priority: "Required",
	},
]
const dlrApiData = [
	{
		key: "3",
		Param: "username",
		Meaning: "User Email",
		Description: "User email used for login into dashboard",
		Type: "String",
		Priority: "Required",
	},
	{
		key: "3",
		Param: "password",
		Meaning: "Password",
		Description: "Password used for login into Dashboard	",
		Type: "String",
		Priority: "Required",
	},
	{
		key: "3",
		Param: "ref",
		Meaning: "Reference Number	",
		Description: "The message_id returned when SMS submitted via AP	",
		Type: "String",
		Priority: "Required",
	},
]
const dlrApiKeyBasedData = [
	{
		key: "3",
		Param: "apiKey",
		Meaning: "API KEY	",
		Description: "28 character API KEY provided by our team	",
		Type: "String",
		Priority: "Required",
	},
	{
		key: "3",
		Param: "ref",
		Meaning: "Reference Number	",
		Description: "The message_id returned when SMS submitted via AP	",
		Type: "String",
		Priority: "Required",
	},
]
const errorCodeApiData = [
	{
		key: "3",
		Param: "API Key Not Provided	",
		Meaning: "1000",
	},
	{
		key: "3",
		Param: "Recipient Not Provided	",
		Meaning: "1001",
	},
	{
		key: "3",
		Param: "From Not Provided	",
		Meaning: "1002",
	},
	{
		key: "3",
		Param: "Message Not Provided	",
		Meaning: "1003",
	},
	{
		key: "3",
		Param: "Invalid API Key	",
		Meaning: "1005",
	},
	{
		key: "3",
		Param: "Invalid From	",
		Meaning: "1006",
	},
	{
		key: "3",
		Param: "Ref Not Provided	",
		Meaning: "1007",
	},
	{
		key: "3",
		Param: "Invalid Message Id/Ref	",
		Meaning: "1008",
	},
	{
		key: "3",
		Param: "Invalid Ref	",
		Meaning: "Invalid Ref	",
	},

	{
		key: "3",
		Param: "Invalid Mobile Number	",
		Meaning: "1005",
	},
]
const errCodeColumns: any = [
	{
		title: "Error Code ",
		dataIndex: "Param",
		key: "Param",
		width: 100,
	},
	{
		title: "Meaning",
		dataIndex: "Meaning",
		key: "Meaning",
		width: 150,
	},
]
const columns: any = [
	{
		title: "Param",
		dataIndex: "Param",
		key: "Param",
		width: 100,
	},
	{
		title: "Meaning",
		dataIndex: "Meaning",
		key: "Meaning",
		width: 150,
	},
	{
		title: "Description",
		dataIndex: "Description",
		key: "Description",
		width: 350,
	},
	{
		title: "Type",
		dataIndex: "Type",
		key: "Type",
		align: "right",
		width: 150,
	},
	{
		title: "Priority",
		dataIndex: "Priority",
		key: "Priority",
		align: "right",
		width: 150,
	},
]
