import { Button, PageHeader } from "antd"

import { AppContent } from "@shared/layout"
import CreateSmsMask from "../components/CreateSmsMask"
import React from "react"
import { useNavigate } from "react-router-dom"

const CrateSmsMaskPage = () => {
	const navigator = useNavigate()

	return (
		<AppContent>
			<PageHeader
				onBack={() => navigator(-1)}
				title="Create Sms Mask"
				extra={[
					<Button
						type="primary"
						onClick={() => navigator(window.location.pathname)}>
						Reset
					</Button>,
				]}
			/>
			<CreateSmsMask />
		</AppContent>
	)
}

export default CrateSmsMaskPage
