import { MutationConfig, QueryConfig, queryClient } from "@shared/config"
import { useMutation, useQuery } from "react-query"

import { CoreAxiosInstanceV2 } from "@shared/config/service/core.axios.v2"
import { ICreateOperator } from "@shared/interfaces"
import { IUpdate } from "./../interfaces/base.interfaces"

const END_POINT = "/operators/"

export const OperatorServiceV2 = {
	NAME: "OperatorService",

	create(payload: ICreateOperator) {
		return CoreAxiosInstanceV2.post(END_POINT, payload)
	},
	filter() {
		return CoreAxiosInstanceV2.get(`${END_POINT}`)
	},

	delete(id: string) {
		return CoreAxiosInstanceV2.delete(`${END_POINT}${id}`)
	},

	update({ id, payload }: IUpdate<{ name: string }>) {
		return CoreAxiosInstanceV2.put(`${END_POINT}${id}`, payload)
	},
}

interface CreateOperator {
	config?: MutationConfig<typeof OperatorServiceV2.create>
}
export const useCreateOperator = ({ config }: CreateOperator) => {
	const INVALID_QUERY_KEY = `${OperatorServiceV2.NAME}${OperatorServiceV2.filter.name}`
	return useMutation({
		...config,
		mutationFn: OperatorServiceV2.create,
		onSettled: () => {
			queryClient.invalidateQueries(INVALID_QUERY_KEY)
		},
	})
}

interface Operators {
	config?: QueryConfig<typeof OperatorServiceV2.filter>
}
export const useOperators = ({ config }: Operators = {}) => {
	const KEY = `${OperatorServiceV2.NAME}${OperatorServiceV2.filter.name}`
	return useQuery({
		...config,
		queryKey: [KEY],
		queryFn: () => OperatorServiceV2.filter(),
	})
}

interface DeleteOperator {
	config?: MutationConfig<typeof OperatorServiceV2.delete>
}
export const useDeleteOperator = ({ config }: DeleteOperator) => {
	const INVALID_QUERY_KEY = `${OperatorServiceV2.NAME}${OperatorServiceV2.filter.name}`
	return useMutation({
		...config,
		mutationFn: OperatorServiceV2.delete,
		onSettled: () => {
			queryClient.invalidateQueries(INVALID_QUERY_KEY)
		},
	})
}

interface UpdateOperator {
	config?: MutationConfig<typeof OperatorServiceV2.update>
}
export const useUpdateOperator = ({ config }: UpdateOperator) => {
	const INVALID_QUERY_KEY = `${OperatorServiceV2.NAME}${OperatorServiceV2.filter.name}`
	return useMutation({
		...config,
		mutationFn: OperatorServiceV2.update,
		onSettled: () => {
			queryClient.invalidateQueries(INVALID_QUERY_KEY)
		},
	})
}
