import { AppLayoutTypes } from "./app-layout.types"

export const visibilityAppLeftSidebarAction = (status: boolean) => (
	dispatch: any
) => {
	dispatch({
		type: AppLayoutTypes.APP_LEFT_SIDEBAR_TOGGLE,
		payload: status,
	})
}
export const visibilityAppRightSidebarAction = (status: boolean) => (
	dispatch: any
) => {
	dispatch({
		type: AppLayoutTypes.APP_RIGHT_SIDEBAR_TOGGLE,
		payload: status,
	})
}
