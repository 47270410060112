import { DatePicker as ADatePicker, Button, Space } from "antd"
import React, { CSSProperties, useState } from "react"

import { DatePickerOptions } from "@shared/enums"
import { IDatePicker } from "@shared/interfaces"
import moment from "moment"
import { useResponsive } from "ahooks"

const { RangePicker } = ADatePicker
interface IFProps {
	styles?: CSSProperties
	onChangeStartEndDate: (data: IDatePicker) => void
}
export const RangeDatePickerOptions: React.FC<IFProps> = ({
	onChangeStartEndDate,
	styles,
}) => {
	const { md } = useResponsive()
	const [activePicker, setActivePicker] = useState<string>(
		DatePickerOptions.TODAY
	)
	const [date, setDate] = useState<any>({
		start: moment(),
		end: moment(),
	})
	const { start, end } = date
	const onChange = (start: any, end: any, active: DatePickerOptions) => {
		setActivePicker(active)
		onChangeStartEndDate({
			start: start,
			end: end,
			active: active,
		})
		setDate({
			start: start,
			end: end,
		})
	}
	return (
		<Space
			wrap
			align="center"
			style={{
				justifyContent: !md ? "center" : "flex-end",
				display: "flex",
				...styles,
			}}>
			<Button
				size="large"
				type={activePicker === DatePickerOptions.TODAY ? "primary" : "default"}
				onClick={() => {
					onChange(moment(), moment(), DatePickerOptions.TODAY)
				}}>
				Today
			</Button>
			<Button
				size="large"
				type={
					activePicker === DatePickerOptions.YESTERDAY ? "primary" : "default"
				}
				onClick={() => {
					onChange(
						moment().subtract(1, "d"),
						moment().subtract(1, "d"),
						DatePickerOptions.YESTERDAY
					)
				}}>
				Yesterday
			</Button>
			<Button
				size="large"
				type={
					activePicker === DatePickerOptions.LAST7DAY ? "primary" : "default"
				}
				onClick={() => {
					onChange(
						moment().subtract(7, "d"),
						moment(),
						DatePickerOptions.LAST7DAY
					)
				}}>
				Last 7 Days
			</Button>
			<Button
				size="large"
				type={
					activePicker === DatePickerOptions.LAST15DAY ? "primary" : "default"
				}
				onClick={() => {
					onChange(
						moment().subtract(15, "d"),
						moment(),
						DatePickerOptions.LAST15DAY
					)
				}}>
				Last 15 Days
			</Button>
			<Button
				size="large"
				type={
					activePicker === DatePickerOptions.THIS_MONTH ? "primary" : "default"
				}
				onClick={() => {
					onChange(
						moment().clone().startOf("month"),
						moment(),
						DatePickerOptions.THIS_MONTH
					)
				}}>
				This Month
			</Button>{" "}
			<Button
				size="large"
				type={
					activePicker === DatePickerOptions.LAST_MONTH ? "primary" : "default"
				}
				onClick={() => {
					onChange(
						moment().subtract(1, "months").startOf("month"),
						moment().subtract(1, "months").endOf("month"),
						DatePickerOptions.LAST_MONTH
					)
				}}>
				Last Month
			</Button>
			<RangePicker
				defaultValue={[moment(new Date(start)), moment(new Date(end))]}
				size="large"
				ranges={{
					Today: [moment(), moment()],
					"This Month": [moment().startOf("month"), moment().endOf("month")],
				}}
				showTime={false}
				format="YYYY/MM/DD"
				onChange={(dates, dateStrings) => {
					setActivePicker(DatePickerOptions.RANGE)
					onChange(dateStrings[0], dateStrings[1], DatePickerOptions.RANGE)
				}}
			/>
		</Space>
	)
}
