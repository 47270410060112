export { default as NOT_FOUND_LOTTIE } from "@shared/assets/data/404-error.json"
export { default as EMPTY_BOX_LOTTIE } from "@shared/assets/data/emptybox.sketch.json"
export { default as KEYBOARD_INPUT_LOTTIE } from "@shared/assets/data/keyboard-input.json"
export { default as NETWORK_ERROR_LOTTIE } from "@shared/assets/data/network-error.json"
export { default as SERVER_RUNNING_LOTTIE } from "@shared/assets/data/server-ldata.json"
export { default as SmsLoading } from "@shared/assets/data/sms-loading.json"

export const IMAGES = {
	GoogleMap: require("@shared/assets/images/google-map.jpg"),
	Avatar: require("@shared/assets/images/welcome.png"),
	GradientsBackground: require("@shared/assets/images/gredient-blue-bg.jpg"),
	MaLogo: require("@shared/assets/images/malogo.svg"),
	Bkash_payment_instructions: require("@shared/assets/images/bkash-pmt.jpeg"),
}

export const DOCUMENTS = {}
