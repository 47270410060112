import { AiOutlineMessage, AiOutlineWhatsApp } from "react-icons/ai"
import { ChooseYourChannel, ExternalRoutePath } from "@shared/constant"
import { FiCpu, FiMail } from "react-icons/fi"

import { FaViber } from "react-icons/fa"
import { Modal } from "antd"
import React from "react"
import { RiUserVoiceLine } from "react-icons/ri"
import { useNavigate } from "react-router-dom"

interface IFProps {
	open?: boolean
	close?: any
	links?: {
		sms: string
	}
	title?: string
}
const CampaignChannels: React.FC<IFProps> = ({
	title = ChooseYourChannel,
	open,
	close,
	links,
}) => {
	const navigator: any = useNavigate()
	return (
		<Modal footer={null} title={title} visible={open} onCancel={close}>
			<div className="select-channels">
				<div
					className="cnl-crd"
					onClick={() => {
						close()
						navigator(links?.sms)
					}}>
					<ChannelCard
						icon={<AiOutlineMessage />}
						title="SMS"
						variant="royalBlue"
					/>
				</div>
				<div className="cnl-crd">
					<a
						href={ExternalRoutePath.whatsApp}
						target="_blank"
						rel="noopener noreferrer">
						<ChannelCard
							icon={<AiOutlineWhatsApp />}
							title="WhatsApp"
							variant="algalFuel"
						/>
					</a>
				</div>
				<div className="cnl-crd" style={{ opacity: 0.2, cursor: "no-drop" }}>
					<ChannelCard
						icon={<FaViber />}
						title="Viber"
						variant="glommyPurple"
					/>
				</div>
				<div className="cnl-crd" style={{ opacity: 0.2, cursor: "no-drop" }}>
					<ChannelCard icon={<FiMail />} title="Email" variant="fusionRed" />
				</div>
				<div className="cnl-crd" style={{ opacity: 0.2, cursor: "no-drop" }}>
					<ChannelCard
						icon={<RiUserVoiceLine />}
						title="Voice"
						variant="flirtatious"
					/>
				</div>
				<div className="cnl-crd" style={{ opacity: 0.2, cursor: "no-drop" }}>
					<ChannelCard icon={<FiCpu />} title="RSC" variant="reptileGreen" />
				</div>
			</div>
		</Modal>
	)
}

export default CampaignChannels
interface ICardProps {
	icon?: any
	title?: string
	variant?:
		| "royalBlue"
		| "algalFuel"
		| "flirtatious"
		| "fusionRed"
		| "glommyPurple"
		| "reptileGreen"
}

const ChannelCard: React.FC<ICardProps> = ({ variant, icon, title }) => {
	return (
		<div className="channel-item">
			<div
				className={`channel-icon 
             ${variant === "royalBlue" ? "bg-royalBlue" : ""}
             ${variant === "algalFuel" ? "bg-algalFuel" : ""}
             ${variant === "flirtatious" ? "bg-flirtatious" : ""}
             ${variant === "fusionRed" ? "bg-fusionRed" : ""}
             ${variant === "glommyPurple" ? "bg-glommyPurple" : ""}
             ${variant === "reptileGreen" ? "bg-reptileGreen" : ""}
             `}>
				{icon}
			</div>
			<h4 className="channel-title">{title}</h4>
		</div>
	)
}
