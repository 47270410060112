import { Typography, notification } from "antd"

import PatternFrom from "./elements/PatternFrom"
import React from "react"
import { useCreatePattern } from "@shared/services"

const CreatePattern = () => {
	const createPatternMutation = useCreatePattern({
		config: {
			onSuccess: (val) => {
				if (val.data.success) {
					notification.success({ message: "Created" })
				}
			},
		},
	})
	return (
		<>
			<Typography.Title level={4}>Create new </Typography.Title>
			<PatternFrom
				initialValue={{ type: "", pattern: "" }}
				onFinish={(val) => {
					createPatternMutation.mutate({
						type: val.type,
						pattern: val.pattern,
					})
				}}
			/>
		</>
	)
}
export default CreatePattern
