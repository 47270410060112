import { Col, PageHeader, Row, Tabs } from "antd"

import AdminDefaultConfig from "../components/AdminDefaultConfig"
import { AppContent } from "@shared/layout"
import React from "react"
import UpdateUserProfile from "../components/UpdateUserProfile"
import UserOverview from "../components/UserOverview"
import UserPasswordChange from "../components/UserPasswordChange"
import { getSession } from "@shared/utils"
import { useNavigate } from "react-router-dom"

const { TabPane } = Tabs
enum TabNames {
	Preferences = "Preferences",
	Security = "Security",
	DefaultConfig = "DefaultConfig",
}

const ProfileAccountPage = () => {
	const navigator = useNavigate()
	const user: any = getSession()
	return (
		<>
			<AppContent>
				<PageHeader
					className="site-page-header"
					onBack={() => navigator(-1)}
					title="My Account"
				/>
				<Row gutter={50}>
					<Col lg={8} xl={8} md={24} sm={24} xs={24}>
						<UserOverview userId={user?.id} />
					</Col>
					<Col lg={14} xl={14} md={24} sm={24} xs={24}>
						<Tabs
							defaultActiveKey={TabNames.Preferences}
							onChange={(activeTab: TabNames) => {
								// setUrl({ tab: activeTab })
							}}>
							<TabPane tab="Preferences" key={TabNames.Preferences}>
								<UpdateUserProfile />
							</TabPane>
							<TabPane tab="Security" key={TabNames.Security}>
								<UserPasswordChange />
							</TabPane>
							<TabPane tab="Default Config" key={TabNames.DefaultConfig}>
								<AdminDefaultConfig />
							</TabPane>
						</Tabs>
					</Col>
				</Row>
			</AppContent>
		</>
	)
}

export default ProfileAccountPage
