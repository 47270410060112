import { Col, PageHeader, Row } from "antd"

import { AppContent } from "@shared/layout"
import CMPLog from "../components/CMPLog"
import React from "react"
import { useCMPBalance } from "@shared/services/cmpBalance.service"

const CMPActivityPage = () => {
	const cmpBalanceQuery = useCMPBalance({
		config: { refetchInterval: 10000 },
	})
	return (
		<AppContent>
			<PageHeader title="CMP Activity" />
			<Row gutter={16}>
				{cmpBalanceQuery?.data?.data?.data?.map((cmp, i) => (
					<Col lg={8} xs={24} xl={8} key={i}>
						<CMPLog
							title={cmp.CMPName}
							status={Number(cmp.status)}
							balance={Number(cmp.balance)}
							api={cmp.balanceAPICall}
							dataSource={[
								{
									title: "Operator",
									value: cmp.operator,
								},
								{
									title: "Clients",
									value: cmp.clients,
								},
								{
									title: "Originating Number",
									value: cmp.originatingNumber,
								},
							]}
						/>
					</Col>
				))}
			</Row>
		</AppContent>
	)
}
export default CMPActivityPage
