import { AiOutlineAccountBook, AiOutlineClose } from "react-icons/ai"
import { Button, Modal, Space } from "antd"
import {
	CommonTitle,
	DebounceSearchInputField,
	Purify,
} from "@shared/components"
import React, { useState } from "react"
import {
	useGatewayAssign,
	useGatewayDelete,
	useSmsGatewaysFilerWithUser,
	useUserGateways,
} from "@shared/services"

import { BiPlus } from "react-icons/bi"
import { isEmpty } from "lodash"

interface IFProps {
	userId?: string
}
const UserGatewayAssign: React.FC<IFProps> = ({ userId }) => {
	const [searchTerm, setSearchTerm] = useState("")
	const userGatewaysQuery = useUserGateways({
		userId,
	})
	const smsGatewaysFilerWithUserQuery = useSmsGatewaysFilerWithUser({
		options: { searchTerm },
	})
	const onSearchUser = (term: string) => {
		setSearchTerm(term)
	}

	const gatewayAssignMutation = useGatewayAssign({})
	const gatewayDeleteMutation = useGatewayDelete({})

	const onSubmitAssignAgainstAnMask = (id: string) => {
		Modal.confirm({
			title: "Are You Sure ?",
			onOk: () =>
				gatewayAssignMutation.mutate({
					user: userId,
					smsGateways: [String(id)],
				}),
		})
	}

	const onSubmitUnAssign = (userId: string) => {
		Modal.confirm({
			title: "Are You Sure ?",
			onOk: () => gatewayDeleteMutation.mutate([userId]),
		})
	}
	return (
		<>
			<CommonTitle title="Gateway Assign" icon={<AiOutlineAccountBook />} />
			<Space
				style={{
					display: "flex",
					justifyContent: "center",
					marginTop: 30,
					marginBottom: 30,
				}}>
				<DebounceSearchInputField
					style={{ maxWidth: 270 }}
					onChange={onSearchUser}
				/>
			</Space>
			<Purify
				hide="empty"
				loading={smsGatewaysFilerWithUserQuery.isLoading}
				empty={isEmpty(smsGatewaysFilerWithUserQuery?.data?.data?.data)}>
				{smsGatewaysFilerWithUserQuery?.data?.data?.data?.map(
					(x: any, i: any) => (
						<Button
							size="large"
							type="primary"
							onClick={() => onSubmitAssignAgainstAnMask(x.id)}
							className="border-2  m-1 inline-flex items-center bg-blue-300 border-none">
							<BiPlus className="mr-5" />
							{x?.name}
						</Button>
					)
				)}
			</Purify>
			<CommonTitle className="my-5" title="Users List " />
			<Purify
				hide="empty"
				loading={userGatewaysQuery.isLoading}
				empty={isEmpty(userGatewaysQuery?.data?.data?.data)}>
				{userGatewaysQuery?.data?.data?.data?.map((x: any, i: any) => (
					<Button
						size="large"
						onClick={() => onSubmitUnAssign(x.id)}
						type="primary"
						className="inline-flex m-1 items-center bg-green-500 border-none">
						<AiOutlineClose className="cursor-pointer mr-5" />
						{x?.smsGateway?.name}
					</Button>
				))}
			</Purify>
		</>
	)
}
export default UserGatewayAssign
