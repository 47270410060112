import { ACTIVE, AdminConfig } from "@shared/constant"
import { Button, Card, Col, Divider, Row, Slider } from "antd"
import {
	CommonTitle,
	DebounceGatewaySelectField,
	DebounceMaskSelectField,
	Purify,
} from "@shared/components"
import React, { useState } from "react"
import {
	useDefaultSMSMask,
	useGetDefaultSmsGateway,
	useSetDefaultSMSMask,
	useSmsConfigs,
	useSmsGatewayDefaultSet,
	useUpdateSMSConfig,
} from "@shared/services"

const AdminDefaultConfig = () => {
	const [smsConfig, setSmsConfig] = useState({
		defaultBalance: 0,
		defaultMaskRate: 0,
		defaultNonMaskRate: 0,
		defaultSmsCount: 0,
		updatedAt: "",
		bodyParameterLength: 0,
	})
	const [defaultMask, setDefaultMask] = useState<any>({})
	const [defaultGateway, setDefaultGateway] = useState<any>({})
	const {
		defaultBalance,
		defaultMaskRate,
		defaultNonMaskRate,
		defaultSmsCount,
		bodyParameterLength,
	} = smsConfig

	const smsConfigsQuery = useSmsConfigs({
		config: {
			onSuccess: (val) => {
				setSmsConfig({
					...smsConfig,
					defaultBalance: val?.data?.data?.defaultBalance,
					defaultMaskRate: val?.data?.data?.defaultMaskRate,
					defaultNonMaskRate: val?.data?.data?.defaultNonMaskRate,
					defaultSmsCount: val?.data?.data?.defaultSmsCount,
					updatedAt: val?.data?.data?.updatedAt,
					bodyParameterLength: val?.data?.data?.bodyParameterLength,
				})
			},
		},
	})

	const updateSMSConfigMutation = useUpdateSMSConfig({
		config: {
			onSuccess: (val) => {
				setSmsConfig({
					...smsConfig,
					defaultBalance: val?.data?.data?.defaultBalance,
					defaultMaskRate: val?.data?.data?.defaultMaskRate,
					defaultNonMaskRate: val?.data?.data?.defaultNonMaskRate,
					defaultSmsCount: val?.data?.data?.defaultSmsCount,
					updatedAt: val?.data?.data?.updatedAt,
					bodyParameterLength: val?.data?.data?.bodyParameterLength,
				})
			},
		},
	})

	const defaultSMSMask = useDefaultSMSMask({
		config: {
			onSuccess: (response) => {
				setDefaultMask({
					label: response?.data?.data?.data?.mask,
					value: response?.data?.data?.data?.id,
				})
			},
		},
	})

	const getDefaultSmsGateway = useGetDefaultSmsGateway({
		config: {
			onSuccess: (response) => {
				setDefaultGateway({
					label: response?.data?.data?.data?.name,
					value: response?.data?.data?.data?.id,
				})
			},
		},
	})

	const setDefaultSMSMask = useSetDefaultSMSMask({})

	const smsGatewayDefaultSet = useSmsGatewayDefaultSet({})

	const onSubmitHandler = () => {
		updateSMSConfigMutation.mutate({
			defaultMaskRate,
			defaultNonMaskRate,
			defaultSmsCount,
			bodyParameterLength,
			status: ACTIVE,
		})
		setDefaultSMSMask.mutate({
			id: defaultMask.value,
		})
		smsGatewayDefaultSet.mutate(defaultGateway.value)
	}

	const loading =
		defaultSMSMask.isLoading ||
		getDefaultSmsGateway.isLoading ||
		smsConfigsQuery.isLoading ||
		updateSMSConfigMutation.isLoading

	return (
		<Card className="no-shadow">
			<CommonTitle title={AdminConfig} />

			<>
				<Purify loading={loading} empty={false}>
					<label>Default Balance</label>
					<Slider
						tooltipVisible
						max={2}
						value={defaultBalance}
						step={0.01}
						onChange={(number: number) => {
							setSmsConfig({
								...smsConfig,
								defaultBalance: number,
							})
						}}
					/>
					<label>Default Mask Rate</label>
					<Slider
						tooltipVisible
						max={2}
						step={0.01}
						onChange={(number: number) => {
							setSmsConfig({
								...smsConfig,
								defaultMaskRate: number,
							})
						}}
						value={defaultMaskRate}
					/>
					<label>Default Non Mask Rate</label>
					<Slider
						tooltipVisible
						max={2}
						step={0.01}
						onChange={(number: number) => {
							setSmsConfig({
								...smsConfig,
								defaultNonMaskRate: number,
							})
						}}
						value={defaultNonMaskRate}
					/>
					<label>Default SMS Count</label>
					<Slider
						tooltipVisible
						max={400}
						step={1}
						onChange={(number: number) => {
							setSmsConfig({
								...smsConfig,
								defaultSmsCount: number,
							})
						}}
						value={defaultSmsCount}
					/>
					<label>Default Body Length</label>
					<Slider
						tooltipVisible
						max={400}
						step={1}
						onChange={(number: number) => {
							setSmsConfig({
								...smsConfig,
								bodyParameterLength: number,
							})
						}}
						value={bodyParameterLength}
					/>
					<Divider />
					<CommonTitle
						style={{ marginBottom: 30 }}
						title={"Default Mask and Gateway"}
					/>
					<Row gutter={15}>
						<Col span={12}>
							<DebounceMaskSelectField
								label="Default Mask"
								defaultValue={defaultMask}
								onChange={(e: any) => {
									setDefaultMask({
										...defaultMask,
										label: e.smsMask?.mask,
										value: e.id,
									})
								}}
							/>
						</Col>
						<Col span={12}>
							<DebounceGatewaySelectField
								label="Default Gateway"
								defaultValue={defaultGateway}
								onChange={(e) => {
									setDefaultGateway({
										...defaultGateway,
										label: e.label,
										value: e.value?.id,
									})
								}}
							/>
						</Col>
					</Row>{" "}
					<Divider />
					<Button type="primary" loading={loading} onClick={onSubmitHandler}>
						Update
					</Button>
				</Purify>
			</>
		</Card>
	)
}

export default AdminDefaultConfig
