import { CoreAxiosWithOutInstanceV2 } from "@shared/config/service/core.withoutauth.axios.v2"

const END_POINT = "/auth/admin/"
export const AuthAdminServiceV2 = {
	login: (payload: any) => {
		return CoreAxiosWithOutInstanceV2.post(`${END_POINT}login`, payload)
	},
	register: (payload: any) => {
		return CoreAxiosWithOutInstanceV2.post(`${END_POINT}register`, payload)
	},
	resetPassword: (payload: any) => {
		return CoreAxiosWithOutInstanceV2.post(`${END_POINT}resetPassword`, payload)
	},
}
