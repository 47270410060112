import React, { Fragment } from "react"

import { AiOutlineAccountBook } from "react-icons/ai"
import { CommonTitle } from "@shared/components"
import { FiPrinter } from "react-icons/fi"
import moment from "moment"
import { utilsGeneratorPdf } from "@shared/utils"

interface IFProps {
	data: any
}
const IndividualTransInvoice: React.FC<IFProps> = ({ data }) => {
	const bodyRef: any = React.createRef()

	const printProcess = () => {
		utilsGeneratorPdf.createPdf(bodyRef.current, new Date())
	}
	return (
		<Fragment>
			<CommonTitle
				title="Transaction Invoice"
				icon={<AiOutlineAccountBook />}
			/>
			<div
				className="individual-trans-invoice"
				ref={bodyRef}
				id="trans-invoice">
				<div className="tp-title">
					<h1>Invoice</h1>
					<span className="print" onClick={printProcess}>
						<FiPrinter />
					</span>
				</div>
				<div className="invoice">
					<header>
						<section>
							<span>#{data.id}</span>
						</section>

						<section>
							<div className="meta-lists">
								<div className="meta-date">
									<span>Print At</span>
									<span> {moment(new Date()).format("MMM Do YY")}</span>
								</div>
								<div className="meta-date">
									<span>Created At</span>
									<span>{moment(data?.createdAt).format("MMM Do YY")}</span>
								</div>
								<div className="meta-date">
									<span>Updated At</span>
									<span>{moment(data?.updatedAt).format("MMM Do YY")}</span>
								</div>
							</div>
						</section>
					</header>

					<main>
						<section className="title"></section>

						<section>
							{data?.eventType ? (
								<figure>
									<span>
										<strong>Event Type</strong>
									</span>

									<span>{data?.eventType}</span>
								</figure>
							) : (
								""
							)}

							{data?.remarks ? (
								<figure>
									<span>
										<strong>Remarks</strong>
									</span>

									<span>{data?.remarks}</span>
								</figure>
							) : (
								""
							)}
							{data?.status ? (
								<figure>
									<span>
										<strong>Status</strong>
									</span>

									<span>{data?.status}</span>
								</figure>
							) : (
								""
							)}

							{data.trxRef ? (
								<figure>
									<span>
										<strong>TrxRef</strong>
									</span>

									<span>{data?.trxRef}</span>
								</figure>
							) : (
								""
							)}
							{data?.prevAmount ? (
								<figure>
									<span>
										<strong>Previous Amount</strong>
									</span>

									<span>{data?.prevAmount}.00</span>
								</figure>
							) : (
								""
							)}
							{data?.newAmount ? (
								<figure>
									<span>
										<strong>Updated Amount</strong>
									</span>
									<span>{data?.amount}.00</span>
								</figure>
							) : (
								""
							)}
						</section>
					</main>

					<footer>
						<span> New Amount</span>
						<span>{data.newAmount}.00</span>
					</footer>
					<div className="btm-metals">
						{data.description ? (
							<span className="desc">Desc: {data?.description}</span>
						) : (
							""
						)}
					</div>
				</div>
			</div>
		</Fragment>
	)
}

export default IndividualTransInvoice
