import { Navigate, RouteObject } from "react-router"

import { Paths } from "@shared/constant"
import PrefixPage from "./PrefixPage"
import React from "react"

export const PrefixRoutes: RouteObject[] = [
	{ path: "", element: <Navigate to={Paths.PrefixList} /> },
	{ path: "list", element: <PrefixPage /> },
]
