import { useDispatch, useSelector } from "react-redux"

import { AiOutlineBlock } from "react-icons/ai"
import { Layout } from "antd"
import React from "react"
import { createStructuredSelector } from "reselect"
import { selectAppSidebarToggle } from "@shared/redux/layout/app-layout.selectors"
import { visibilityAppRightSidebarAction } from "@shared/redux/layout/app-layout.actions"

const { Sider } = Layout

interface IFProps {
	children?: any
	background?: "none" | "white"
}
export const AppRightSidebar: React.FC<IFProps> = ({
	children,
	background = "white",
}) => {
	const dispatch = useDispatch()
	const { appLayoutSidebar } = useSelector(
		createStructuredSelector({
			appLayoutSidebar: selectAppSidebarToggle,
		})
	)
	const { isOpenRightSidebar } = appLayoutSidebar

	return (
		<>
			<div
				className="app-right-sidebar-toggle-icon"
				style={{ right: isOpenRightSidebar ? 0 : 248 }}
				onClick={() =>
					dispatch(visibilityAppRightSidebarAction(!isOpenRightSidebar))
				}>
				<AiOutlineBlock />
			</div>
			<Sider
				defaultCollapsed={false}
				collapsed={isOpenRightSidebar}
				className="app-right-sidebar"
				style={{ background }}
				width={250}>
				{children}
			</Sider>
		</>
	)
}
