import { Navigate, RouteObject } from "react-router"

import { Paths } from "@shared/constant"
import React from "react"
import SmsOverviewPage from "./SmsOverviewPage"

export const SmsRoutes: RouteObject[] = [
	{ path: "", element: <Navigate to={Paths.SmsOverview} /> },
	{ path: "overview", element: <SmsOverviewPage /> },
]
