import { Form, Typography } from "antd"
import { boolToStatusUtil, getSession } from "@shared/utils"

import React from "react"
import SmsPackageForm from "./elements/SmsPackageForm"
import { useCreateSmsPackage } from "@shared/services"

const CreateSmsPackage = () => {
	const [form] = Form.useForm()
	const user = getSession()
	const createSmsPackageMutation = useCreateSmsPackage({
		config: {
			onSuccess: (val) => {
				if (val.data.success) {
					form.resetFields()
				}
			},
		},
	})
	return (
		<>
			<Typography.Title level={4}>Create new Sms Package</Typography.Title>
			<SmsPackageForm
				form={form}
				initialValue={{ name: "", maskRate: 0, nonMaskRate: 0, status: false }}
				loading={createSmsPackageMutation.isLoading}
				onFinish={(val) => {
					createSmsPackageMutation.mutate({
						name: val.name,
						nonMaskRate: Number(val.nonMaskRate),
						maskRate: Number(val.maskRate),
						status: boolToStatusUtil(val.status),
						user: user.id,
					} as any)
				}}
			/>
		</>
	)
}
export default CreateSmsPackage
