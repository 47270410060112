import CreateInterCloudUser from "./CreateInterCloudUser"
import InterCloudUsers from "./InterCloudUsers"
import React from "react"
import { RouteObject } from "react-router"
import UpdateInterCloudUser from "./UpdateInterCloudUser"

export const McaRoutes: RouteObject[] = [
	{ path: "", element: <InterCloudUsers /> },
	{ path: "create", element: <CreateInterCloudUser /> },
	{ path: ":id", element: <UpdateInterCloudUser /> },
]
