import Lottie from "react-lottie"
import { NOT_FOUND_LOTTIE } from "@shared/assets"
import React from "react"

const NotFoundPage = () => {
	const defaultOptions = {
		loop: true,
		autoplay: true,
		animationData: NOT_FOUND_LOTTIE,
		rendererSettings: {
			preserveAspectRatio: "xMidYMid slice",
		},
	}
	return (
		<div className="error-not-found">
			<Lottie options={defaultOptions} height={350} width={500} />
			<div className="error-404-txt">
				<h4>Oops, the page you are looking for can't be found</h4>
			</div>
		</div>
	)
}

export default NotFoundPage
