import {
	CoreAxiosInstanceV2,
	MutationConfig,
	QueryConfig,
	queryClient,
} from "@shared/config"
import { useMutation, useQuery } from "react-query"

const END_POINT = "/smsConfigs/"

export interface IUpdateSMSConfig {
	defaultMaskRate: number
	defaultNonMaskRate: number
	defaultSmsCount: number
	bodyParameterLength: number
	status: string
}

export const SmsConfigServiceV2 = {
	NAME: "SmsConfigServiceV2",
	get() {
		return CoreAxiosInstanceV2.get(`${END_POINT}`)
	},
	update(payload: IUpdateSMSConfig) {
		return CoreAxiosInstanceV2.put(`${END_POINT}smsConfigUpdate`, payload)
	},
}

interface SmsConfigs {
	config?: QueryConfig<typeof SmsConfigServiceV2.get>
}
export const useSmsConfigs = ({ config }: SmsConfigs = {}) => {
	const KEY = `${SmsConfigServiceV2.NAME}${SmsConfigServiceV2.get.name}`
	return useQuery({
		...config,
		queryKey: [KEY],
		queryFn: () => SmsConfigServiceV2.get(),
	})
}

interface UpdateSMSConfig {
	config?: MutationConfig<typeof SmsConfigServiceV2.update>
}
export const useUpdateSMSConfig = (config: UpdateSMSConfig) => {
	const INVALID_QUERY_KEY = `${SmsConfigServiceV2.NAME}${SmsConfigServiceV2.get.name}`
	return useMutation({
		...config,
		mutationFn: SmsConfigServiceV2.update,
		onSettled: () => {
			queryClient.invalidateQueries(INVALID_QUERY_KEY)
		},
	})
}
