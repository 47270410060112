import { Button, Form, Input } from "antd"

import React from "react"

interface IInitialValue {
	name: string
}

interface IFProps {
	onFinish?: (val: IInitialValue) => void
	initialValue?: IInitialValue
	loading?: boolean
	form?: any
}
const OperatorForm: React.FC<IFProps> = ({
	initialValue,
	onFinish,
	loading,
	form,
}) => {
	return (
		<>
			<Form
				form={form}
				name="basic"
				initialValues={initialValue}
				layout="vertical"
				onFinish={onFinish}>
				<Form.Item
					label="Operator Name"
					name="name"
					rules={[
						{ required: true, message: "Please input your Operator name!" },
					]}>
					<Input size="large" placeholder="Enter Your Operator Name" />
				</Form.Item>
				<Form.Item>
					<Button loading={loading} type="primary" htmlType="submit">
						Submit
					</Button>
				</Form.Item>
			</Form>
		</>
	)
}

export default OperatorForm
