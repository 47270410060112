export const ExternalRoutePath = {
	whatsApp: "http://whatsapp.messageanalytica.com/",
}

class _Paths {
	//? AUTH
	public AuthPrefix = "/auth/"
	public AuthLoginPage = `${this.AuthPrefix}login/`

	//? BILLINGS
	public BillingPrefix = "/billings/"
	public TransactionsLogPage = `${this.BillingPrefix}`

	//? CAMPAIGN
	public CampaignPrefix = "/campaign/"
	public CampaignOverview = `${this.CampaignPrefix}overview`
	public CreateSmsCampaign = `${this.CampaignPrefix}sms/create`
	public SmsCampaignDetail = `${this.CampaignPrefix}sms/`

	//? COMMON
	public CommonPrefix = "/common/"
	public ApiDocs = `${this.CommonPrefix}api-docs`
	public UpComing = `${this.CommonPrefix}up-coming`
	public Help = `${this.CommonPrefix}help`
	public Policy = `${this.CommonPrefix}policy`

	//? Gateways
	public GatewayPrefix = "/gateways/"
	public GatewayList = `${this.GatewayPrefix}list`

	//? CONTACT
	public ContactPrefix = "/contact/"
	public ContactList = `${this.ContactPrefix}`

	//? DASHBOARD
	public DashboardPrefix = "/dashboard/"
	public DefaultDashboard = `${this.DashboardPrefix}default`

	//? INSTANT-SMS
	public InstantSmsPrefix = "/instant/"
	public InstantMessage = `${this.InstantSmsPrefix}sms/01`

	//? MASKINGS
	public MaskingPrefix = "/maskings/"
	public MaskingCreate = `${this.MaskingPrefix}create`
	public MaskingList = `${this.MaskingPrefix}list`

	//? OPERATOR
	public OperatorsPrefix = "/operators/"
	public OperatorsList = `${this.OperatorsPrefix}list`

	//? PACKAGES
	public PackagePrefix = "/package/"

	//? PREFIX
	public Prefix = "/prefix/"
	public PrefixList = `${this.Prefix}list`

	//? PUSH PULL
	public PushPullPrefix = "/push-pull/"
	public PushPullPlayGround = `${this.Prefix}playground`

	//? RE-ROUTES
	public ReRoutePrefix = "/re-routing/"
	public ReRouteForceSuccess = `${this.ReRoutePrefix}force-success`
	public ReRouteForceRoute = `${this.ReRoutePrefix}force-route`

	//? REPORTS
	public ReportPrefix = "/reports/"
	public ReportList = `${this.ReportPrefix}`

	//? SMS
	public Sms = "/sms/"
	public SmsOverview = `${this.Sms}overview`

	//? USERS
	public Users = "/users/"
	public UsersMe = `${this.Users}me`
	public UsersList = `${this.Users}list`
	public UsersResellerRequest = `${this.Users}reseller-request`

	//? TEMPLATES
	public Templates = "/templates/"
	public SmsTemplates = `${this.Templates}sms`
	public SmsTemplatesList = `${this.Templates}sms/list`

	//? PREFERENCE
	public Preference = "/preference/"
	public CMPActivity = `${this.Preference}cmp`

	//? Analytics
	public Analytics = "/analytics/"
	public Patterns = `${this.Analytics}patterns`

	//? Mca
	public Mca = "/mca/"
}

export const Paths = new _Paths()
