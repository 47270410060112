import React, { CSSProperties } from "react"

import { IMAGES } from "@shared/assets"

interface IFProps {
	styles?: CSSProperties
}
const Logo: React.FC<IFProps> = ({ styles = { height: 50 } }) => {
	return (
		<>
			<img style={styles} src={IMAGES.MaLogo} alt={"logo"} />
		</>
	)
}

export default Logo
