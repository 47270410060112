import { Navigate, RouteObject } from "react-router"

import CrateSmsMaskPage from "./CrateSmsMaskPage"
import { Paths } from "@shared/constant"
import React from "react"
import SmsMaskDetailPage from "./SmsMaskDetailPage"
import SmsMaskPage from "./SmsMaskPage"

export const MaskingRoutes: RouteObject[] = [
	{ path: "", element: <Navigate to={Paths.MaskingList} /> },
	{ path: "create", element: <CrateSmsMaskPage /> },
	{ path: ":id", element: <SmsMaskDetailPage /> },
	{ path: "list", element: <SmsMaskPage /> },
]
