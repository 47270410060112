import { API_KEY, ARE_YOU_SURE, NoDataFound } from "@shared/constant"
import { Button, Modal, Space, Typography } from "antd"
import { CommonTitle, Purify } from "@shared/components"
import { useCreateUserAPIKey, useGetAPIKey } from "@shared/services"

import React from "react"

interface IFProps {
	userId: string
}
const UserApiKey: React.FC<IFProps> = ({ userId }) => {
	const createUserAPIKey = useCreateUserAPIKey({})
	const getAPIKey = useGetAPIKey({ options: { single: true, user: userId } })

	const onSubmitHandler = () => {
		Modal.confirm({
			title: ARE_YOU_SURE,
			onOk: () => createUserAPIKey.mutate(userId),
		})
	}
	return (
		<>
			<CommonTitle title={API_KEY} />
			<Purify
				loading={getAPIKey.isLoading || createUserAPIKey.isLoading}
				empty={false}>
				<Space
					direction="vertical"
					style={{ textAlign: "center", width: "100%", marginTop: 30 }}>
					<Typography.Title
						type="success"
						copyable={getAPIKey.data?.data?.data?.key ? true : false}
						level={4}>
						{getAPIKey.data?.data?.data?.key
							? getAPIKey.data?.data?.data?.key
							: NoDataFound}
					</Typography.Title>
					<Button type="primary" onClick={onSubmitHandler}>
						Generate
					</Button>
				</Space>
			</Purify>
		</>
	)
}
export default UserApiKey
