import { Button, PageHeader } from "antd"

import { AppContent } from "@shared/layout"
import Masks from "../components/Masks"
import { Paths } from "@shared/constant"
import React from "react"
import { useNavigate } from "react-router-dom"

const SmsMaskPage = () => {
	const navigator = useNavigate()
	return (
		<AppContent>
			<PageHeader
				onBack={() => navigator(-1)}
				title="Sms Mask"
				extra={[
					<Button
						style={{ marginBottom: 20 }}
						type="primary"
						onClick={() => navigator(Paths.MaskingCreate)}>
						Create Mask
					</Button>,
				]}
			/>
			<Masks />
		</AppContent>
	)
}
export default SmsMaskPage
