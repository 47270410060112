import React, { CSSProperties } from "react"

import { IPrefix } from "@shared/interfaces"
import { Select } from "antd"
import { usePrefixs } from "@shared/services"

const { Option } = Select
interface IFProps {
	onChange?: (e: string) => void
	styles?: CSSProperties
	defaultValue?: { label: string; value: string }
	mode?: "multiple" | "tags"
}
export const DebouncePrefixSelectField: React.FC<IFProps> = ({
	onChange,
	styles = { width: "100%" },
	defaultValue,
	mode = "tags",
}) => {
	const prefixesQuery = usePrefixs({})

	return (
		<Select
			mode={mode}
			placeholder="Select Prefix"
			onChange={onChange}
			defaultValue={defaultValue?.label}
			style={styles}
			size="large">
			{defaultValue?.value ? (
				<Option value={defaultValue?.value}>{defaultValue?.label}</Option>
			) : (
				""
			)}
			{prefixesQuery?.data?.data?.data?.map((x: IPrefix) => (
				<Option value={x.id}>{x.name}</Option>
			))}
		</Select>
	)
}
