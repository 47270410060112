import { Button, Drawer, Form, Input } from "antd"

import React from "react"
import { useCreateTransactionLog } from "@shared/services"
import { useResponsive } from "ahooks"

interface IFProps {
	userId: string
	open: boolean
	onClose: () => void
}
const UserBalanceAssign: React.FC<IFProps> = ({ userId, onClose, open }) => {
	const { lg } = useResponsive()
	const createTransactionLog = useCreateTransactionLog({
		onSuccess: (val) => {
			if (val.data?.success) {
				onClose()
			}
		},
	})

	const onSubmitHandler = (value: any) => {
		createTransactionLog.mutate({
			...value,
			amount: parseInt(value.amount),
			user: userId,
			status: "ACTIVE",
		})
	}
	return (
		<Drawer
			title="Assign Balance"
			placement="right"
			width={!lg ? "100%" : 600}
			closable={true}
			onClose={onClose}
			visible={open}>
			<Form
				name="basic"
				initialValues={{
					amount: "",
					eventType: "",
					trxRef: "",
					description: "",
					remarks: "",
				}}
				layout="vertical"
				onFinish={onSubmitHandler}>
				<Form.Item
					label="Amount"
					name="amount"
					rules={[{ required: true, message: "Please input your amount!" }]}>
					<Input size="large" placeholder="Enter Your amount" />
				</Form.Item>
				<Form.Item
					label="Event Type"
					name="eventType"
					rules={[{ required: true, message: "Please input your eventType!" }]}>
					<Input size="large" placeholder="Enter Your eventType" />
				</Form.Item>
				<Form.Item
					label="Trx Ref"
					name="trxRef"
					rules={[{ required: true, message: "Please input your trxRef!" }]}>
					<Input size="large" placeholder="Enter Your trxRef" />
				</Form.Item>
				<Form.Item
					label="Description"
					name="description"
					rules={[
						{ required: true, message: "Please input your description!" },
					]}>
					<Input size="large" placeholder="Enter Your description" />
				</Form.Item>
				<Form.Item
					label="Remarks"
					name="remarks"
					rules={[{ required: true, message: "Please input your remarks!" }]}>
					<Input size="large" placeholder="Enter Your remarks" />
				</Form.Item>
				<Form.Item>
					<Button
						loading={createTransactionLog.isLoading}
						type="primary"
						htmlType="submit">
						Submit
					</Button>
				</Form.Item>
			</Form>
		</Drawer>
	)
}

export default UserBalanceAssign
