import axios, { AxiosError } from "axios"

import { ENV } from "../../../ENV"
import { message } from "antd"

const headers = {
	Accept: "application/json",
	"Content-Type": "application/json",
	"X-Request-ID": 124,
	"X-Country-Code": "BD",
	"X-Client-Name": "CRM",
	"X-Client-Version": 123,
}
export const CoreAxiosWithOutInstanceV2 = axios.create({
	baseURL: ENV.CORE_END_POINT,
	timeout: 60000,
	headers,
})

CoreAxiosWithOutInstanceV2.interceptors.request.use(
	(config) => {
		config.headers["X-Request-ID"] = "CRM-WEB-STAGING"
		config.headers["X-Country-Code"] = "BD"
		config.headers["X-Client-Name"] = "CRM-WEB"
		config.headers["X-Client-Version"] = "1.0"
		return config
	},
	(error) => {
		try {
			if (error?.response?.data?.errorMessage) {
				message.error(error?.response?.data?.errorMessage)
			} else if (error.response.data?.error) {
				message.error(error?.response?.data?.error)
			}
		} catch (error) {
			return error
		}
	}
)

CoreAxiosWithOutInstanceV2.interceptors.response.use(
	(response) => {
		if (typeof response?.data?.error === "string") {
			message.error(response?.data?.error)
		}
		return response
	},
	(error: AxiosError) => {
		try {
			if (error?.response?.data?.errorMessage) {
				message.error(error?.response?.data?.errorMessage)
			} else if (error.response.data?.error) {
				message.error(error?.response?.data?.error)
			}
		} catch (error) {
			return error
		}
	}
)
