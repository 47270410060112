import { AppLayoutTypes } from "./app-layout.types"

const initialState = {
	isOpenLeftSidebar: false,
	isOpenRightSidebar: false,
}
const AppLayoutReducer = (state = initialState, action: any) => {
	const { payload } = action
	switch (action.type) {
		case AppLayoutTypes.APP_LEFT_SIDEBAR_TOGGLE:
			return {
				...state,
				isOpenLeftSidebar: payload,
			}
		case AppLayoutTypes.APP_RIGHT_SIDEBAR_TOGGLE:
			return {
				...state,
				isOpenRightSidebar: payload,
			}
		default:
			return state
	}
}
export { AppLayoutReducer }
