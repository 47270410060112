import {
	ICreateIntercloudNumber,
	IFilterIntercloudNumber,
	IUpdateIntercloudNumber,
} from "./../interfaces/mca.interfaces"
import { MutationConfig, QueryConfig } from "./../config/react-query/index"
import { useMutation, useQuery } from "react-query"

import { IUpdate } from "./../interfaces/base.interfaces"
import { McaAxiosInstance } from "./../config/service/mca.axios"
import { concatQuery } from "@shared/utils"
import { queryClient } from "@shared/config/react-query"

const END_POINT = "/intercloud-users/"

export const InterCloudUsersService = {
	NAME: "intercloud-users",
	create(payload: ICreateIntercloudNumber): any {
		return McaAxiosInstance.post(END_POINT, payload)
	},
	filter(options: IFilterIntercloudNumber) {
		return McaAxiosInstance.get(`${END_POINT}?${concatQuery(options)}`)
	},
	findById(id: string): any {
		return McaAxiosInstance.get(`${END_POINT}${id}`)
	},
	delete(id: string): any {
		return McaAxiosInstance.delete(`${END_POINT}${id}`)
	},
	update({ id, payload }: IUpdate<IUpdateIntercloudNumber>) {
		return McaAxiosInstance.put(`${END_POINT}${id}`, payload)
	},
}

interface Create {
	config?: MutationConfig<typeof InterCloudUsersService.create>
}
export const useCreateInterCloudUser = ({ config }: Create) => {
	const INVALID_QUERY_KEY = `${InterCloudUsersService.NAME}${InterCloudUsersService.filter.name}`
	return useMutation({
		...config,
		mutationFn: InterCloudUsersService.create,
		onSettled: () => {
			queryClient.invalidateQueries(INVALID_QUERY_KEY)
		},
	})
}

interface Filter {
	config?: QueryConfig<typeof InterCloudUsersService.filter>
	options?: IFilterIntercloudNumber
}
export const useInterCloudUsers = ({ config, options }: Filter = {}) => {
	const KEY = `${InterCloudUsersService.NAME}${InterCloudUsersService.filter.name}`
	return useQuery({
		...config,
		queryKey: [KEY],
		queryFn: () => InterCloudUsersService.filter(options),
	})
}

interface FindById {
	config?: QueryConfig<typeof InterCloudUsersService.filter>
	id?: string
}
export const useInterCloudUsersById = ({ config, id }: FindById = {}) => {
	const KEY = `${InterCloudUsersService.NAME}${InterCloudUsersService.filter.name}`
	return useQuery({
		...config,
		queryKey: [KEY],
		queryFn: () => InterCloudUsersService.findById(id),
	})
}

interface Delete {
	config?: MutationConfig<typeof InterCloudUsersService.delete>
}
export const useDeleteInterCloudUser = ({ config }: Delete) => {
	const INVALID_QUERY_KEY = `${InterCloudUsersService.NAME}${InterCloudUsersService.filter.name}`
	return useMutation({
		...config,
		mutationFn: InterCloudUsersService.delete,
		onSettled: () => {
			queryClient.invalidateQueries(INVALID_QUERY_KEY)
		},
	})
}

interface Update {
	config?: MutationConfig<typeof InterCloudUsersService.update>
}
export const useUpdateInterCloudUser = ({ config }: Update) => {
	const INVALID_QUERY_KEY = `${InterCloudUsersService.NAME}${InterCloudUsersService.filter.name}`
	return useMutation({
		...config,
		mutationFn: InterCloudUsersService.update,
		onSettled: () => {
			queryClient.invalidateQueries(INVALID_QUERY_KEY)
		},
	})
}
