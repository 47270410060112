import React from "react"

interface Props {
	title?: string
	subTitle?: string
	icon?: JSX.Element
	onClick?: () => void
}

export const FluidListCard: React.FC<Props> = ({
	title,
	subTitle,
	icon,
	onClick,
}) => {
	return (
		<>
			<div onClick={onClick} className="recipient-option">
				<div className="recipient-item-title">
					<span className="recipient-icon">{icon}</span>
					<h4>
						{title} <span>{subTitle}</span>
					</h4>
				</div>
			</div>
		</>
	)
}
