import { ICreatePrefix, IPrefixUpdate } from "@shared/interfaces"
import { MutationConfig, QueryConfig } from "./../config/react-query/index"
import { useMutation, useQuery } from "react-query"

import { CoreAxiosInstanceV2 } from "@shared/config/service/core.axios.v2"
import { IUpdate } from "./../interfaces/base.interfaces"
import { queryClient } from "@shared/config/react-query"

const END_POINT = "/prefixs/"

export const PrefixesServiceV2 = {
	NAME: "PrefixesServiceV2",
	create(payload: ICreatePrefix): any {
		return CoreAxiosInstanceV2.post(END_POINT, payload)
	},
	filter() {
		return CoreAxiosInstanceV2.get(END_POINT)
	},
	delete(id: string): any {
		return CoreAxiosInstanceV2.delete(`${END_POINT}${id}`)
	},
	update({ id, payload }: IUpdate<IPrefixUpdate>) {
		return CoreAxiosInstanceV2.put(`${END_POINT}${id}`, payload)
	},
}

interface CreatePrefix {
	config?: MutationConfig<typeof PrefixesServiceV2.create>
}
export const useCreatePrefix = ({ config }: CreatePrefix) => {
	const INVALID_QUERY_KEY = `${PrefixesServiceV2.NAME}${PrefixesServiceV2.filter.name}`
	return useMutation({
		...config,
		mutationFn: PrefixesServiceV2.create,
		onSettled: () => {
			queryClient.invalidateQueries(INVALID_QUERY_KEY)
		},
	})
}

interface Prefixes {
	config?: QueryConfig<typeof PrefixesServiceV2.filter>
}
export const usePrefixs = ({ config }: Prefixes = {}) => {
	const KEY = `${PrefixesServiceV2.NAME}${PrefixesServiceV2.filter.name}`
	return useQuery({
		...config,
		queryKey: [KEY],
		queryFn: () => PrefixesServiceV2.filter(),
	})
}

interface DeletePrefix {
	config?: MutationConfig<typeof PrefixesServiceV2.delete>
}
export const useDeletePrefix = ({ config }: DeletePrefix) => {
	const INVALID_QUERY_KEY = `${PrefixesServiceV2.NAME}${PrefixesServiceV2.filter.name}`
	return useMutation({
		...config,
		mutationFn: PrefixesServiceV2.delete,
		onSettled: () => {
			queryClient.invalidateQueries(INVALID_QUERY_KEY)
		},
	})
}

interface UpdatePrefix {
	config?: MutationConfig<typeof PrefixesServiceV2.update>
}
export const useUpdatePrefix = ({ config }: UpdatePrefix) => {
	const INVALID_QUERY_KEY = `${PrefixesServiceV2.NAME}${PrefixesServiceV2.filter.name}`
	return useMutation({
		...config,
		mutationFn: PrefixesServiceV2.update,
		onSettled: () => {
			queryClient.invalidateQueries(INVALID_QUERY_KEY)
		},
	})
}
