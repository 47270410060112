import AuthFrom from "./elements/AuthForm"
import React from "react"
import { useAuth } from "../hooks/useAuth"

const AdminLogin = () => {
	const { adminLoginFn } = useAuth()

	const onSubmit = (value: any) => {
		adminLoginFn.mutate(value)
	}
	return (
		<>
			<h4>Admin Login</h4>
			<p>{"Enter your email & password to login"}</p>
			<AuthFrom
				loading={adminLoginFn.isLoading}
				initialValues={{ email: "", password: "" }}
				onFinish={onSubmit}
			/>
		</>
	)
}
export default AdminLogin
