import { Button, Popconfirm, Table, Typography } from "antd"
import React, { useState } from "react"
import { useDeleteSMSMask, useSMSMasks } from "@shared/services"

import { DebounceSearchInputField } from "@shared/components"
import { ISmsMask } from "@shared/interfaces"
import { Link } from "react-router-dom"
import { Paths } from "@shared/constant"
import moment from "moment"
import { statusToBoolUtil } from "@shared/utils"

const Masks = () => {
	const [options, setOptions] = useState({
		page: 1,
		take: 10,
		searchTerm: "",
	})
	const smsMasksQuery = useSMSMasks({ options })
	const tableData: any = smsMasksQuery.data?.data?.data?.map(
		(mask: ISmsMask) => ({
			name: (
				<Link to={`${Paths.MaskingPrefix}${mask.id}`}>
					<u style={{ cursor: "pointer", color: "#3391ff" }}>{mask.mask}</u>
				</Link>
			),
			status: (
				<Typography.Text
					type={statusToBoolUtil(mask.status) ? "success" : "secondary"}>
					{mask.status}
				</Typography.Text>
			),
			created: moment(mask?.createdAt).format("DD-MM-YYYY"),
			actions: <TableActions mask={mask} />,
		})
	)
	return (
		<>
			<DebounceSearchInputField
				style={{ marginBottom: 20, maxWidth: 270 }}
				onChange={(term: string) => {
					setOptions({
						...options,
						searchTerm: term,
					})
				}}
			/>
			<Table
				sticky
				pagination={{
					onChange: (page: number) => {
						setOptions({
							...options,
							page,
						})
					},
					pageSize: smsMasksQuery.data?.data?.take,
					total: smsMasksQuery.data?.data?.total,
					showSizeChanger: false,
				}}
				scroll={{ x: 300 }}
				loading={smsMasksQuery.isLoading}
				columns={columns}
				dataSource={tableData}
			/>
		</>
	)
}
const TableActions: React.FC<{ mask: ISmsMask }> = ({ mask }) => {
	const deleteSMSMaskMutation = useDeleteSMSMask({})

	return (
		<Popconfirm
			title="Are you sure?"
			onConfirm={() => deleteSMSMaskMutation.mutate(mask.id)}
			okText="Yes"
			okButtonProps={{
				loading: deleteSMSMaskMutation.isLoading,
			}}
			cancelText="No">
			<Button type="primary" danger>
				Delete
			</Button>
		</Popconfirm>
	)
}
export default Masks
const columns: any = [
	{
		title: "Name",
		dataIndex: "name",
	},
	{
		title: "Status",
		dataIndex: "status",
	},
	{
		title: "Created",
		dataIndex: "created",
	},
	{
		title: "Actions",
		dataIndex: "actions",
		align: "right",
	},
]
