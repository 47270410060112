import "@shared/styles/app.scss"

import * as serviceWorker from "./serviceWorker"

import App from "./App"
import React from "react"
import ReactDOM from "react-dom"
import withClearCache from "@shared/hooks/withCleanCache"

const ClearCacheComponent = withClearCache(App)
ReactDOM.render(<ClearCacheComponent />, document.getElementById("root"))

serviceWorker.unregister()
