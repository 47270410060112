import { Col, PageHeader, Row } from "antd"

import { AppContent } from "@shared/layout"
import CreateSmsPackage from "../components/CreateSmsPackage"
import React from "react"
import SmsPackages from "../components/SmsPackages"
import { useNavigate } from "react-router-dom"

const SmsPackagePage = () => {
	const navigator = useNavigate()

	return (
		<AppContent>
			<PageHeader onBack={() => navigator(-1)} title="Sms Packages" />
			<Row gutter={50}>
				<Col xl={16} lg={14} md={24} sm={24} xs={24}>
					<SmsPackages />
				</Col>
				<Col xl={8} lg={10} md={24} sm={24} xs={24}>
					<CreateSmsPackage />
				</Col>
			</Row>
		</AppContent>
	)
}
export default SmsPackagePage
