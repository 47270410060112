import { Button, Modal, Popconfirm, Space, Table, TableColumnsType } from "antd"
import React, { useState } from "react"
import { useDeletePattern, usePatterns } from "@shared/services"

import { IPattern } from "@shared/interfaces"
import UpdatePattern from "./UpdatePattern"

const Patterns = () => {
	const [options, setOptions] = useState({
		page: 1,
		take: 10,
	})
	const patternsQuery = usePatterns({
		options: { page: options.page, take: options.take },
	})
	const tableData: any = patternsQuery?.data?.data?.payload?.map(
		(pn: IPattern) => ({
			type: pn.type,
			pattern: pn?.pattern,
			provider: pn?.provider || "N/A",
			actions: <TableActions item={pn} />,
		})
	)
	return (
		<Table
			sticky
			pagination={{
				pageSize: patternsQuery?.data?.data?.take,
				total: patternsQuery?.data?.data?.total,
				showSizeChanger: true,
			}}
			scroll={{ x: 300 }}
			loading={patternsQuery.isLoading}
			columns={columns}
			dataSource={tableData}
			onChange={(pagination, filter, sort) => {
				setOptions({
					page: pagination.current,
					take: pagination.pageSize,
				})
			}}
		/>
	)
}
const TableActions: React.FC<{ item: IPattern }> = ({ item }) => {
	const [updateUi, setUpdateUi] = useState(false)
	const deletePatternMutation = useDeletePattern({})
	return (
		<>
			<Space>
				<Button type="primary" onClick={() => setUpdateUi(true)}>
					Update
				</Button>
				<Popconfirm
					title="Are you sure?"
					onConfirm={() => deletePatternMutation.mutate(item.id)}
					okText="Yes"
					okButtonProps={{
						loading: deletePatternMutation.isLoading,
					}}
					cancelText="No">
					<Button type="primary" danger>
						Delete
					</Button>
				</Popconfirm>
			</Space>
			<Modal
				footer={null}
				visible={updateUi}
				title="Update Operator"
				onCancel={() => setUpdateUi(false)}>
				<UpdatePattern
					id={item.id}
					type={item.type}
					pattern={item.pattern}
					onClose={() => setUpdateUi(false)}
				/>
			</Modal>
		</>
	)
}
export default Patterns

const columns: TableColumnsType = [
	{
		title: "Provider",
		dataIndex: "provider",
		width: 100,
	},
	{
		title: "Type",
		dataIndex: "type",
		width: 180,
	},
	{
		title: "Pattern",
		dataIndex: "pattern",
		width: 300,
	},
	{
		title: "Actions",
		dataIndex: "actions",
		align: "center",
		width: 180,
		fixed: "right",
	},
]
