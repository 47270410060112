import { Button, Popconfirm, Space, Table } from "antd"
import React, { useState } from "react"
import { useSmsPackageDelete, useSmsPackages } from "@shared/services"

import { ColumnsType } from "antd/lib/table"
import { DebounceSearchInputField } from "@shared/components"
import { ISmsPackage } from "@shared/interfaces"

const SmsPackages = () => {
	const [options, setOptions] = useState({
		page: 1,
		take: 10,
		searchTerm: "",
	})
	const smsPackagesQuery = useSmsPackages({
		options,
	})
	const tableData: any = smsPackagesQuery?.data?.data?.data?.map(
		(x: ISmsPackage) => ({
			name: x?.name,
			maskRate: x?.maskRate,
			nonMaskRate: x?.nonMaskRate,
			type: x?.type,
			actions: <TableActions packageItem={x} />,
		})
	)
	return (
		<>
			<DebounceSearchInputField
				style={{ marginBottom: 30, maxWidth: 270 }}
				onChange={(searchTerm: string) => {
					setOptions({
						...options,
						page: 1,
						take: 10,
						searchTerm,
					})
				}}
			/>
			<Table
				sticky
				pagination={{
					onChange: (page: number) => {
						setOptions({
							...options,
							page,
						})
					},
					pageSize: smsPackagesQuery?.data?.data?.take,
					total: smsPackagesQuery?.data?.data?.total,
					showSizeChanger: false,
				}}
				scroll={{ x: 700 }}
				loading={smsPackagesQuery.isLoading}
				columns={columns}
				dataSource={tableData}
			/>
		</>
	)
}
interface IFActionProps {
	packageItem: ISmsPackage
}
const TableActions: React.FC<IFActionProps> = ({ packageItem }) => {
	const smsPackageDeleteMutation = useSmsPackageDelete({})
	return (
		<>
			<Space>
				<Popconfirm
					title="Are you sure?"
					onConfirm={() => smsPackageDeleteMutation.mutate(packageItem.id)}
					okText="Yes"
					okButtonProps={{
						loading: smsPackageDeleteMutation.isLoading,
					}}
					cancelText="No">
					<Button type="primary" danger>
						Delete
					</Button>
				</Popconfirm>
			</Space>
		</>
	)
}
export default SmsPackages
const columns: ColumnsType = [
	{
		title: "Name",
		dataIndex: "name",
		width: 170,
	},
	{
		title: "Mask Rate",
		dataIndex: "maskRate",
		width: 150,
	},
	{
		title: "Non Mask Rate",
		dataIndex: "nonMaskRate",
		width: 150,
	},
	{
		title: "Type",
		dataIndex: "type",
		width: 70,
	},
	{
		title: "Actions",
		dataIndex: "actions",
		align: "right",
		width: 100,
		fixed: "right",
	},
]
