import { Button, Form, Input } from "antd"

import React from "react"

interface IInitialValue {
	type: string
	pattern: string
}

interface IFProps {
	initialValue?: IInitialValue
	onFinish?: (val: IInitialValue) => void
	loading?: boolean
}
const PatternFrom: React.FC<IFProps> = ({
	initialValue,
	onFinish,
	loading,
}) => {
	return (
		<Form
			name="basic"
			initialValues={initialValue}
			layout="vertical"
			onFinish={onFinish}>
			<Form.Item
				label="Pattern Type"
				name="type"
				rules={[{ required: true, message: "Please input your type !" }]}>
				<Input
					defaultValue={initialValue.type}
					size="large"
					placeholder="Enter Your type"
				/>
			</Form.Item>
			<Form.Item
				label="Pattern"
				name="pattern"
				rules={[{ required: true, message: "Please input your pattern!" }]}>
				<Input.TextArea
					rows={8}
					style={{ width: "100%" }}
					size="large"
					defaultValue={initialValue.pattern}
					placeholder="Enter Your type pattern"
				/>
			</Form.Item>
			<Form.Item>
				<Button loading={loading} type="primary" htmlType="submit">
					Submit
				</Button>
			</Form.Item>
		</Form>
	)
}

export default PatternFrom
