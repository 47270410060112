import { Button, Modal, Popconfirm, Space, Table, Typography } from "antd"
import React, { useState } from "react"
import {
	useDeleteOperator,
	useOperators,
	useUpdateOperator,
} from "@shared/services"

import { IOperator } from "@shared/interfaces"
import OperatorForm from "./elements/OperatorForm"
import moment from "moment"

const Operators = () => {
	const operatorsQuery = useOperators({})
	const tableData: any = operatorsQuery?.data?.data?.data?.map(
		(operator: IOperator) => ({
			operatorName: operator.name,
			created: moment(operator.createdAt).format("DD-MM-YYYY"),
			actions: <TableActions operator={operator} />,
		})
	)
	return (
		<>
			<Table
				sticky
				pagination={false}
				scroll={{ x: 300 }}
				loading={operatorsQuery.isLoading}
				columns={columns}
				dataSource={tableData}
			/>
		</>
	)
}
const TableActions: React.FC<{ operator: IOperator }> = ({ operator }) => {
	const [updateUi, setUpdateUi] = useState(false)
	const deleteOperatorMutation = useDeleteOperator({})
	const updateOperatorMutation = useUpdateOperator({
		config: {
			onSuccess: (val) => {
				if (val.data?.success) {
					setUpdateUi(false)
				}
			},
		},
	})

	return (
		<>
			<Space>
				<Button type="primary" onClick={() => setUpdateUi(true)}>
					Update
				</Button>
				<Popconfirm
					title="Are you sure?"
					onConfirm={() => deleteOperatorMutation.mutate(operator.id)}
					okText="Yes"
					okButtonProps={{
						loading: deleteOperatorMutation.isLoading,
					}}
					cancelText="No">
					<Button type="primary" danger>
						Delete
					</Button>
				</Popconfirm>
			</Space>
			<Modal
				footer={null}
				visible={updateUi}
				title="Update Operator"
				onCancel={() => setUpdateUi(false)}>
				<Typography.Title level={4}>Update Operator</Typography.Title>
				<OperatorForm
					initialValue={{ name: operator.name }}
					loading={updateOperatorMutation.isLoading}
					onFinish={(val) => {
						updateOperatorMutation.mutate({
							payload: { name: val.name },
							id: operator.id,
						})
					}}
				/>
			</Modal>
		</>
	)
}
export default Operators
const columns: any = [
	{
		title: "Operator Name",
		dataIndex: "operatorName",
	},
	{
		title: "Created",
		dataIndex: "created",
	},
	{
		title: "Actions",
		dataIndex: "actions",
		align: "right",
	},
]
