import { Col, Divider, PageHeader, Row } from "antd"

import { AppContent } from "@shared/layout"
import CreatePrefix from "../components/CreatePrefix"
import Prefixs from "../components/Prefixs"
import React from "react"
import { useNavigate } from "react-router-dom"

const PrefixPage = () => {
	const navigator = useNavigate()
	return (
		<AppContent>
			<PageHeader onBack={() => navigator(-1)} title="Prefix" />
			<Row gutter={50}>
				<Col xl={16} lg={16} md={24} sm={24} xs={24}>
					<Prefixs />
				</Col>
				<Col xl={8} lg={8} md={24} sm={24} xs={24}>
					<Divider />
					<CreatePrefix />
				</Col>
			</Row>
		</AppContent>
	)
}

export default PrefixPage
