import { AppLayoutReducer } from "@shared/redux/layout/app-layout.reducer"
import { smsCampaignReducer as NsmsCampaignReducer } from "@shared/redux/sms-campaign/index"
import { combineReducers } from "redux"
import { contactReducer } from "@shared/redux/contact"
import { instantSmsReducer } from "@shared/redux/insert-sms"
import { persistReducer } from "redux-persist"
import storage from "redux-persist/lib/storage"

const persistConfig = {
	key: "root",
	storage,
	whitelist: [],
}

const appReducer = combineReducers({
	contact: contactReducer,
	appLayout: AppLayoutReducer,
	smsCampaign: NsmsCampaignReducer,
	instantSms: instantSmsReducer,
})

const rootReducer = (state: any, action: any) => {
	if (process.env.NODE_ENV === "development") {
	}
	return appReducer(state, action)
}

export default persistReducer(persistConfig, rootReducer)
