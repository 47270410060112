import { Navigate, RouteObject } from "react-router"

import { Paths } from "@shared/constant"
import ProfileAccountPage from "./user-profile.page.component"
import React from "react"
import ResellerRequestPage from "./ResellerRequestPage"
import UserDetailPage from "./UserDetailPage"
import UsersPage from "./UsersPage"

export const UsersRoutes: RouteObject[] = [
	{ path: "", element: <Navigate to={Paths.UsersList} /> },
	{ path: "list", element: <UsersPage /> },
	{ path: ":id", element: <UserDetailPage /> },
	{ path: "me", element: <ProfileAccountPage /> },
	{ path: "reseller-request", element: <ResellerRequestPage /> },
]
