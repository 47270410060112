import axios, { AxiosError } from "axios"

import { ENV } from "../../../ENV"
import { message } from "antd"
import { storage } from "@shared/utils"

const headers = {
	Accept: "application/json",
	"Content-Type": "application/json",
	"X-Request-ID": 124,
	"X-Country-Code": "BD",
	"X-Client-Name": "CRM",
	"X-Client-Version": 123,
	Authorization: `Bearer ${storage.getToken()}`,
}
export const AnalyticsAxiosInstanceV2 = axios.create({
	baseURL: ENV.ANALYTICS_END_POINT,
	timeout: 60000,
	headers,
})

AnalyticsAxiosInstanceV2.interceptors.response.use(
	(response) => {
		if (response?.data?.success === true) {
			switch (response.config.method.toUpperCase()) {
				case "PUT":
					message.success("Updated Success")
					return response

				case "DELETE":
					message.success("Deleted Success")
					return response
			}
		} else {
			if (typeof response?.data?.error === "string") {
				message.error(response?.data?.error)
			}
		}
		return response
	},
	(error: AxiosError) => {
		try {
			if (error?.response?.status === 401) {
				storage.clear()
				window.location.assign((window.location.origin as unknown) as string)
			} else if (error?.response?.data?.errorMessage) {
				message.error(error?.response?.data?.errorMessage)
			} else if (error.response.data?.error) {
				message.error(error?.response?.data?.error)
			}
		} catch (error) {
			return error
		}
	}
)
