import {
	ICreatePattern,
	IUpdatePattern,
} from "./../interfaces/analytics.interfacs"
import {
	MutationConfig,
	QueryConfig,
	queryClient,
} from "@shared/config/react-query"
import { useMutation, useQuery } from "react-query"

import { AnalyticsAxiosInstanceV2 } from "@shared/config/service/analyticsaxiosV2"
import { IBaseFilterExtended } from "@shared/interfaces"
import { concatQuery } from "./../utils/util-function"

const END_POINT = "/patterns/"
export const PatternServiceV2 = {
	NAME: "PatternService",
	filter(options: IBaseFilterExtended) {
		return AnalyticsAxiosInstanceV2.get(`${END_POINT}?${concatQuery(options)}`)
	},
	delete(id: string) {
		return AnalyticsAxiosInstanceV2.delete(`${END_POINT}${id}`)
	},
	create(payload: ICreatePattern) {
		return AnalyticsAxiosInstanceV2.post(`${END_POINT}`, payload)
	},
	update(payload: IUpdatePattern) {
		const { type, pattern, user } = payload
		return AnalyticsAxiosInstanceV2.put(`${END_POINT}${payload.id}`, {
			type,
			pattern,
			user,
		})
	},
}

interface Patterns {
	config?: QueryConfig<typeof PatternServiceV2.filter>
	options?: IBaseFilterExtended
}
export const usePatterns = ({ config, options }: Patterns = {}) => {
	const KEY = `${PatternServiceV2.NAME}${PatternServiceV2.filter.name}`
	return useQuery({
		...config,
		queryKey: [KEY, Object.keys(options)],
		queryFn: () => PatternServiceV2.filter(options),
	})
}

interface CreatePattern {
	config?: MutationConfig<typeof PatternServiceV2.create>
}
export const useCreatePattern = ({ config }: CreatePattern) => {
	const INVALID_QUERY_KEY = `${PatternServiceV2.NAME}${PatternServiceV2.filter.name}`
	return useMutation({
		...config,
		mutationFn: PatternServiceV2.create,
		onSettled: () => {
			queryClient.invalidateQueries(INVALID_QUERY_KEY)
		},
	})
}

interface DeletePattern {
	config?: MutationConfig<typeof PatternServiceV2.delete>
}
export const useDeletePattern = ({ config }: DeletePattern) => {
	const INVALID_QUERY_KEY = `${PatternServiceV2.NAME}${PatternServiceV2.filter.name}`
	return useMutation({
		...config,
		mutationFn: PatternServiceV2.delete,
		onSettled: () => {
			queryClient.invalidateQueries(INVALID_QUERY_KEY)
		},
	})
}

interface UpdatePattern {
	config?: MutationConfig<typeof PatternServiceV2.update>
}
export const useUpdatePattern = ({ config }: UpdatePattern) => {
	const INVALID_QUERY_KEY = `${PatternServiceV2.NAME}${PatternServiceV2.filter.name}`
	return useMutation({
		...config,
		mutationFn: PatternServiceV2.update,
		onSettled: () => {
			queryClient.invalidateQueries(INVALID_QUERY_KEY)
		},
	})
}
