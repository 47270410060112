export enum SmsCampaignTypes {
	ENTER_NUMBER = "ENTER_NUMBER",
	PASTE_NUMBERS = "PASTE_NUMBERS",
	CHOOSE_CONTACTS = "CHOOSE_CONTACTS",
	CHOOSE_CONTACT_GROUPS = "CHOOSE_CONTACT_GROUPS",
	UPLOAD_CONTACT_FILE = "UPLOAD_CONTACT_FILE",
	UPLOAD_CONTACT_AND_MESSAGE_FILE = "UPLOAD_CONTACT_AND_MESSAGE_FILE",
	UPLOAD_PARAMETERIZED_CONTACT_FILE = "UPLOAD_PARAMETERIZED_CONTACT_FILE",
}

export const SmsCampaignRecipientSliderType = {
	default: "DEFAULT",
	overview: "OVERVIEW",
	enterNumber: SmsCampaignTypes.ENTER_NUMBER,
	pasteNumber: SmsCampaignTypes.PASTE_NUMBERS,
	chooseContacts: SmsCampaignTypes.CHOOSE_CONTACTS,
	chooseContactGroups: SmsCampaignTypes.CHOOSE_CONTACT_GROUPS,
	contactFile: SmsCampaignTypes.UPLOAD_CONTACT_FILE,
	contactAndMessageFile: SmsCampaignTypes.UPLOAD_CONTACT_AND_MESSAGE_FILE,
	parameterizedContactFile: SmsCampaignTypes.UPLOAD_PARAMETERIZED_CONTACT_FILE,
}
export const SmsCampaignRecipientSlide = {
	DEFAULT: "DEFAULT",
	OVERVIEW: "OVERVIEW",
	ENTER_NUMBER: SmsCampaignTypes.ENTER_NUMBER,
	PASTE_NUMBERS: SmsCampaignTypes.PASTE_NUMBERS,
	CHOOSE_CONTACTS: SmsCampaignTypes.CHOOSE_CONTACTS,
	CHOOSE_CONTACT_GROUPS: SmsCampaignTypes.CHOOSE_CONTACT_GROUPS,
	UPLOAD_CONTACT_FILE: SmsCampaignTypes.UPLOAD_CONTACT_FILE,
	UPLOAD_CONTACT_AND_MESSAGE_FILE:
		SmsCampaignTypes.UPLOAD_CONTACT_AND_MESSAGE_FILE,
	UPLOAD_PARAMETERIZED_CONTACT_FILE:
		SmsCampaignTypes.UPLOAD_PARAMETERIZED_CONTACT_FILE,
}
export enum SmsCampaignStepper {
	writeMessage = 0,
	schedule = 1,
}
