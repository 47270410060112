import {
	IFCreateMasking,
	IFFilterWithUser,
	IFGatewayAssign,
	IFMaskingFilter,
	IFUpdateMask,
	IFUserAssign,
} from "@shared/interfaces"
import { MutationConfig, QueryConfig, queryClient } from "@shared/config"
import { useMutation, useQuery } from "react-query"

import { CoreAxiosInstanceV2 } from "./../config/service/core.axios.v2"
import { IUpdate } from "./../interfaces/base.interfaces"
import { UsersServiceV2 } from "."
import { concatQuery } from "@shared/utils"

const END_POINT = "/smsMasks/"
export const SmsMaskServiceV2 = {
	NAME: "SmsMaskServiceV2",
	filter(options: IFMaskingFilter): any {
		return CoreAxiosInstanceV2.get(`${END_POINT}filter?${concatQuery(options)}`)
	},
	findById(id: string) {
		return CoreAxiosInstanceV2.get(`${END_POINT}${id}`)
	},
	filterWithUser(options: IFFilterWithUser): any {
		return CoreAxiosInstanceV2.get(
			`${END_POINT}filterWithUser?${concatQuery(options)}`
		)
	},
	create(payload: IFCreateMasking): any {
		return CoreAxiosInstanceV2.post(END_POINT, payload)
	},
	delete(id: string): any {
		return CoreAxiosInstanceV2.delete(`${END_POINT}${id}`)
	},
	update({ payload, id }: IUpdate<IFUpdateMask>): any {
		return CoreAxiosInstanceV2.put(`${END_POINT}${id}`, payload)
	},
	getGetaways(id: string): any {
		return CoreAxiosInstanceV2.get(`${END_POINT}getGateways/${id}`)
	},
	gatewayAssign(payload: IFGatewayAssign): any {
		return CoreAxiosInstanceV2.post(`${END_POINT}gatewayAssign`, payload)
	},
	gatewayDelete(ids: string[]): any {
		return CoreAxiosInstanceV2.post(`${END_POINT}gatewayDelete`, { ids })
	},
	getUsers(id: string): any {
		return CoreAxiosInstanceV2.get(`${END_POINT}getUsers/${id}`)
	},
	userAssign(payload: IFUserAssign): any {
		return CoreAxiosInstanceV2.post(`${END_POINT}userAssign`, payload)
	},
	userDelete(ids: string[]): any {
		return CoreAxiosInstanceV2.post(`${END_POINT}userDelete`, { ids })
	},
	defaultSet(payload: any): any {
		return CoreAxiosInstanceV2.put(`${END_POINT}defaultSet/${payload.id}`)
	},
	getDefaultMask() {
		return CoreAxiosInstanceV2.get(`${END_POINT}getDefaultMask`)
	},
}
interface GetGateWays {
	config?: QueryConfig<typeof SmsMaskServiceV2.getGetaways>
	id?: string
}
export const useSMSMaskGetGateways = ({ config, id }: GetGateWays = {}) => {
	const KEY = `${SmsMaskServiceV2.NAME}${SmsMaskServiceV2.getGetaways.name}`
	return useQuery({
		...config,
		queryKey: [KEY, id],
		queryFn: () => SmsMaskServiceV2.getGetaways(id),
	})
}
interface GatewayAssign {
	config?: MutationConfig<typeof SmsMaskServiceV2.gatewayAssign>
}
export const useSMSMaskGatewayAssign = (config: GatewayAssign) => {
	const INVALID_QUERY_KEY = `${SmsMaskServiceV2.NAME}${SmsMaskServiceV2.gatewayAssign.name}`
	return useMutation({
		...config,
		mutationFn: SmsMaskServiceV2.gatewayAssign,
		onSettled: () => {
			queryClient.invalidateQueries(INVALID_QUERY_KEY)
		},
	})
}
interface GateWayDelete {
	config?: MutationConfig<typeof SmsMaskServiceV2.gatewayDelete>
}
export const useSMSMaskGatewayDelete = (config: GateWayDelete) => {
	const INVALID_QUERY_KEY = `${SmsMaskServiceV2.NAME}${SmsMaskServiceV2.filter.name}`
	return useMutation({
		...config,
		mutationFn: SmsMaskServiceV2.gatewayDelete,
		onSettled: () => {
			queryClient.invalidateQueries(INVALID_QUERY_KEY)
		},
	})
}
interface GetUsers {
	config?: QueryConfig<typeof SmsMaskServiceV2.getUsers>
	id?: string
}
export const useSMSMaskGetUsers = ({ config, id }: GetUsers = {}) => {
	const KEY = `${SmsMaskServiceV2.NAME}${SmsMaskServiceV2.getUsers.name}`
	return useQuery({
		...config,
		queryKey: [KEY, id],
		queryFn: () => SmsMaskServiceV2.getUsers(id),
	})
}
interface UserAssign {
	config?: MutationConfig<typeof SmsMaskServiceV2.userAssign>
}
export const useSMSMaskUserAssign = (config: UserAssign) => {
	const INVALID_QUERY_KEY = `${UsersServiceV2.NAME}${UsersServiceV2.getUserMask.name}`
	const INVALID_QUERY_KEY2 = `${SmsMaskServiceV2.NAME}${SmsMaskServiceV2.filterWithUser.name}`
	return useMutation({
		...config,
		mutationFn: SmsMaskServiceV2.userAssign,
		onSettled: () => {
			queryClient.invalidateQueries(INVALID_QUERY_KEY)
			queryClient.invalidateQueries(INVALID_QUERY_KEY2)
		},
	})
}
interface UserDelete {
	config?: MutationConfig<typeof SmsMaskServiceV2.userDelete>
}
export const useSMSMaskUserDelete = (config: UserDelete) => {
	const INVALID_QUERY_KEY = `${UsersServiceV2.NAME}${UsersServiceV2.getUserMask.name}`
	const INVALID_QUERY_KEY2 = `${SmsMaskServiceV2.NAME}${SmsMaskServiceV2.filterWithUser.name}`
	return useMutation({
		...config,
		mutationFn: SmsMaskServiceV2.userDelete,
		onSettled: () => {
			queryClient.invalidateQueries(INVALID_QUERY_KEY)
			queryClient.invalidateQueries(INVALID_QUERY_KEY2)
		},
	})
}
interface DefaultSet {
	config?: MutationConfig<typeof SmsMaskServiceV2.defaultSet>
}
export const useSetDefaultSMSMask = (config: DefaultSet) => {
	const INVALID_QUERY_KEY = `${SmsMaskServiceV2.NAME}${SmsMaskServiceV2.filter.name}`
	return useMutation({
		...config,
		mutationFn: SmsMaskServiceV2.defaultSet,
		onSettled: () => {
			queryClient.invalidateQueries(INVALID_QUERY_KEY)
		},
	})
}
interface GetDefaultMask {
	config?: QueryConfig<typeof SmsMaskServiceV2.getDefaultMask>
}
export const useDefaultSMSMask = ({ config }: GetDefaultMask = {}) => {
	const KEY = `${SmsMaskServiceV2.NAME}${SmsMaskServiceV2.getDefaultMask.name}`
	return useQuery({
		...config,
		queryKey: [KEY],
		queryFn: () => SmsMaskServiceV2.getDefaultMask(),
	})
}
interface SMSMasks {
	config?: QueryConfig<typeof SmsMaskServiceV2.filter>
	options?: IFMaskingFilter
}
export const useSMSMasks = ({ config, options }: SMSMasks = {}) => {
	const KEY = `${SmsMaskServiceV2.NAME}${SmsMaskServiceV2.filter.name}`
	return useQuery({
		...config,
		queryKey: [KEY, Object.values(options)],
		queryFn: () => SmsMaskServiceV2.filter(options),
	})
}
interface FindById {
	config?: QueryConfig<typeof SmsMaskServiceV2.findById>
	id?: string
}
export const useSMSMask = ({ config, id }: FindById = {}) => {
	const KEY = `${SmsMaskServiceV2.NAME}${SmsMaskServiceV2.findById.name}`
	return useQuery({
		...config,
		queryKey: [KEY, id],
		queryFn: () => SmsMaskServiceV2.findById(id),
	})
}
interface FilterWithUser {
	config?: QueryConfig<typeof SmsMaskServiceV2.filterWithUser>
	options?: IFFilterWithUser
}
export const useSmsMaskFilterWithUser = ({
	config,
	options,
}: FilterWithUser = {}) => {
	const KEY = `${SmsMaskServiceV2.NAME}${SmsMaskServiceV2.filterWithUser.name}`
	return useQuery({
		...config,
		queryKey: [KEY, Object.values(options)],
		queryFn: () => SmsMaskServiceV2.filterWithUser(options),
	})
}
interface Create {
	config?: MutationConfig<typeof SmsMaskServiceV2.create>
}
export const useCreateSMSMask = ({ config }: Create) => {
	const INVALID_QUERY_KEY = `${SmsMaskServiceV2.NAME}${SmsMaskServiceV2.create.name}`
	return useMutation({
		...config,
		mutationFn: SmsMaskServiceV2.create,
		onSettled: () => {
			queryClient.invalidateQueries(INVALID_QUERY_KEY)
		},
	})
}
interface DeleteSMSMask {
	config?: MutationConfig<typeof SmsMaskServiceV2.delete>
}
export const useDeleteSMSMask = ({ config }: DeleteSMSMask) => {
	const INVALID_QUERY_KEY = `${SmsMaskServiceV2.NAME}${SmsMaskServiceV2.filter.name}`
	return useMutation({
		...config,
		mutationFn: SmsMaskServiceV2.delete,
		onSettled: () => {
			queryClient.invalidateQueries(INVALID_QUERY_KEY)
		},
	})
}
interface UpdateSMSMask {
	config?: MutationConfig<typeof SmsMaskServiceV2.update>
}
export const useUpdateSMSMask = ({ config }: UpdateSMSMask) => {
	const INVALID_QUERY_KEY = `${SmsMaskServiceV2.NAME}${SmsMaskServiceV2.filter.name}`
	return useMutation({
		...config,
		mutationFn: SmsMaskServiceV2.update,
		onSettled: () => {
			queryClient.invalidateQueries(INVALID_QUERY_KEY)
		},
	})
}
