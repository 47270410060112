import { Avatar, List, Typography } from "antd"
import { isArray, isEmpty } from "lodash"
import { timeFormatUtil, userBalanceUtil } from "@shared/utils"

import { CommonTitle } from "@shared/components"
import { ENV } from "@ENV"
import { FaPen } from "react-icons/fa"
import { PERSONAL_INFO } from "@shared/constant"
import React from "react"
import { TimeFormat } from "@shared/enums"
import { useUser } from "@shared/services"

interface IFProps {
	userId: string
}
const UserOverview: React.FC<IFProps> = ({ userId }) => {
	const userQuery = useUser({ id: userId })
	const pack =
		!isEmpty(userQuery?.data?.data?.data?.smsPackages) &&
		isArray(userQuery?.data?.data?.data?.smsPackages)
			? userQuery?.data?.data?.data?.smsPackages[0]
			: {}

	return (
		<>
			<CommonTitle title={PERSONAL_INFO} />
			<List
				loading={userQuery.isLoading}
				header={
					<div style={{ textAlign: "center" }}>
						<div>
							<div className="profile-picture">
								<Avatar
									shape="circle"
									src={userQuery?.data?.data?.data?.userInfo?.image}
									style={{
										height: 120,
										width: 120,
										border: "5px solid #826EE9",
										margin: "10px auto",
									}}
								/>
								<FaPen className="icon" />
							</div>
						</div>
						<h2 className="f-18">
							{userQuery?.data?.data?.data?.userInfo?.name}
						</h2>
						<span>Credit</span>৳{" "}
						{userBalanceUtil(
							userQuery?.data?.data?.data?.accountType,
							userQuery?.data?.data?.data?.balance,
							userQuery?.data?.data?.data?.dueBalance
						)}
					</div>
				}
				bordered={false}
				dataSource={[1]}
				renderItem={(item) => (
					<>
						<List.Item actions={[userQuery?.data?.data?.data?.userInfo?.name]}>
							<Typography.Text>{"Name"}</Typography.Text>
						</List.Item>
						<List.Item actions={[userQuery?.data?.data?.data?.email]}>
							<Typography.Text>Email</Typography.Text>
						</List.Item>
						<List.Item actions={[`${ENV.CURRENCY_SIGN} ${pack?.maskRate}`]}>
							<Typography.Text>Mask Rate</Typography.Text>
						</List.Item>
						<List.Item actions={[`${pack?.type}`]}>
							<Typography.Text>Package</Typography.Text>
						</List.Item>
						<List.Item
							actions={[
								`${userQuery?.data?.data?.data?.userInfo?.designation}`,
							]}>
							<Typography.Text>Designation</Typography.Text>
						</List.Item>
						<List.Item
							actions={[`${userQuery?.data?.data?.data?.userInfo?.company}`]}>
							<Typography.Text>Company</Typography.Text>
						</List.Item>
						<List.Item
							actions={[`${userQuery?.data?.data?.data?.userInfo?.address}`]}>
							<Typography.Text>Address</Typography.Text>
						</List.Item>
						<List.Item
							actions={[`${userQuery?.data?.data?.data?.userInfo?.number}`]}>
							<Typography.Text>Phone</Typography.Text>
						</List.Item>
						<List.Item
							actions={[`${userQuery?.data?.data?.data?.accountType}`]}>
							<Typography.Text>AccountType</Typography.Text>
						</List.Item>
						<List.Item actions={[`${userQuery?.data?.data?.data?.status}`]}>
							<Typography.Text>Status</Typography.Text>
						</List.Item>
						<List.Item
							actions={[
								`${timeFormatUtil(
									userQuery?.data?.data?.data?.userInfo?.createdAt,
									TimeFormat.primary
								)}`,
							]}>
							<Typography.Text>Joining</Typography.Text>
						</List.Item>
					</>
				)}
			/>
		</>
	)
}

export default UserOverview
