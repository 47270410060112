import { Navigate, useRoutes } from "react-router-dom"

import { AuthRoutes } from "@modules/auth/routes"
import React from "react"

export const PublicRoutes = () => {
	return useRoutes([
		{ path: "", element: <Navigate to="/auth" /> },
		{
			path: "auth",
			children: AuthRoutes,
		},
		{
			path: "*",
			element: <Navigate to="/" />,
		},
	])
}
