import { Button, Checkbox, Form, Input, PageHeader, message } from "antd"
import {
	DebounceMaskSelectField,
	DebounceUserSelectField,
} from "@shared/components"
import React, { useState } from "react"

import { AppContent } from "@shared/layout"
import { useCreateInterCloudUser } from "@shared/services/intercloud-users.service"
import { useNavigate } from "react-router-dom"

const CreateInterCloudUser = () => {
	const navigator = useNavigate()
	const [user, setUser] = useState(null)
	const [mask, setMask] = useState(null)
	const [businessLeadActive, setBusinessLeadActive] = useState(false)

	const createInterCloudUserMutation = useCreateInterCloudUser({
		config: {
			onSuccess: (val) => {
				if (val.data.success) {
					message.success("Created Successfully")
					navigator("/mca")
				}
			},
		},
	})

	const onSubmit = (val) => {
		if (businessLeadActive) {
			if (!user?.id) {
				message.error("Invalid user")
			} else if (!mask?.smsMask?.mask) {
				message.error("Invalid Mask")
			} else {
				const payload = {
					intercloudNumber: val?.intercloudNumber,
					user: user?.id,
					smsContent: val?.smsContent,
					maskName: mask?.smsMask?.mask,
					isBusinessLeadActive: true,
				}
				createInterCloudUserMutation.mutate(payload)
			}
		} else {
			const payload = {
				intercloudNumber: val?.intercloudNumber,
				isBusinessLeadActive: false,
			}
			createInterCloudUserMutation.mutate(payload)
		}
	}

	return (
		<AppContent>
			<PageHeader
				onBack={() => navigator(-1)}
				title="Create InterCloud Users"
				extra={[]}
			/>
			<Form
				name="basic"
				initialValues={{
					intercloudNumber: "",
					smsContent: "",
					isBusinessLeadActive: false,
				}}
				layout="vertical"
				onFinish={onSubmit}>
				{businessLeadActive ? (
					<div className="grid grid-cols-2 gap-2">
						<div className="my-3">
							<DebounceUserSelectField
								onChange={(v: any) => {
									setUser(v.value)
								}}
							/>
						</div>
						<div className="my-3">
							<DebounceMaskSelectField
								userId={user?.id}
								disabled={!user}
								label="Mask Name"
								onChange={(v) => {
									setMask(v)
								}}
							/>
						</div>
					</div>
				) : (
					""
				)}

				<Form.Item
					label="InterCloud Number"
					name="intercloudNumber"
					rules={[
						{ required: true, message: "Please input your interCloud Number!" },
					]}>
					<Input size="large" placeholder="Enter Your interCloud Number" />
				</Form.Item>
				{businessLeadActive ? (
					<Form.Item
						label="Sms Content"
						name="smsContent"
						rules={[
							{
								required: true,
								message: "Please input your smsContent Number!",
							},
						]}>
						<Input.TextArea size="large" placeholder="Enter Your smsContent" />
					</Form.Item>
				) : (
					""
				)}
				<Form.Item name="isBusinessLeadActive">
					<Checkbox
						value={businessLeadActive}
						onChange={(v) => {
							setBusinessLeadActive(v.target.checked)
							console.log(v)
						}}>
						Business Lead
					</Checkbox>
				</Form.Item>
				<Form.Item>
					<Button
						loading={createInterCloudUserMutation.isLoading}
						type="primary"
						htmlType="submit">
						Submit
					</Button>
				</Form.Item>
			</Form>
		</AppContent>
	)
}

export default CreateInterCloudUser
