import { Col, Divider, PageHeader, Row } from "antd"

import { AppContent } from "@shared/layout"
import CreateOperator from "../components/CreateOperator"
import Operators from "../components/Operators"
import React from "react"
import { useNavigate } from "react-router-dom"

const OperatorPage = () => {
	const navigator = useNavigate()

	return (
		<AppContent>
			<PageHeader onBack={() => navigator(-1)} title="Operator" />
			<Row gutter={50}>
				<Col xl={16} lg={16} md={24} sm={24} xs={24}>
					<Operators />
				</Col>
				<Col xl={8} lg={8} md={24} sm={24} xs={24}>
					<Divider />
					<CreateOperator />
				</Col>
			</Row>
		</AppContent>
	)
}

export default OperatorPage
