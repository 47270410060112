import { boolToStatusUtil, statusToBoolUtil } from "@shared/utils"
import { useSMSMask, useUpdateSMSMask } from "@shared/services"

import MaskForm from "./elements/MaskForm"
import { Purify } from "@shared/components"
import React from "react"
import { Typography } from "antd"
import { useParams } from "react-router-dom"

const UpdateSmsMask = () => {
	let { id }: any = useParams()
	const smsMaskQuery = useSMSMask({ id })
	const updateSmsMaskMutation = useUpdateSMSMask({})
	return (
		<>
			<Typography.Title level={4}>Update</Typography.Title>
			<Purify empty={false} loading={smsMaskQuery.isLoading}>
				<MaskForm
					loading={smsMaskQuery.isLoading}
					initialValue={{
						maskingName: smsMaskQuery?.data?.data?.data?.mask,
						status: statusToBoolUtil(smsMaskQuery?.data?.data?.data?.status),
						isMasking: smsMaskQuery?.data?.data?.data?.isMasking,
					}}
					onFinish={(val) => {
						updateSmsMaskMutation.mutate({
							id,
							payload: {
								mask: val.maskingName,
								isMasking: val.isMasking,
								status: boolToStatusUtil(val.status),
							},
						})
					}}
				/>
			</Purify>
		</>
	)
}

export default UpdateSmsMask
