import React, { useState } from "react"

import { DeliveryStatus } from "@shared/enums"
import ReactApexChart from "react-apexcharts"

interface IFProps {
	success: number[]
	pending: number[]
	times: string[]
}
const SmsOverviewBarChart: React.FC<IFProps> = ({
	success,
	pending,
	times,
}) => {
	const [data] = useState<any>([
		{
			name: DeliveryStatus.SUCCESS,
			data: success,
		},
		{
			name: DeliveryStatus.PENDING,
			data: pending,
		},
	])
	const [options] = useState<any>({
		options: {
			chart: {
				type: "bar",
				height: 250,
			},
			plotOptions: {
				bar: {
					horizontal: false,
					columnWidth: "55%",
					// endingShape: "",
				},
			},
			dataLabels: {
				enabled: false,
			},
			stroke: {
				show: true,
				width: 2,
				colors: ["transparent"],
			},
			xaxis: {
				categories: times ? times : [],
			},
			fill: {
				opacity: 1,
			},
			tooltip: {
				y: {
					formatter: function (val: any) {
						return val + "SMS"
					},
				},
			},
			legend: {
				fontSize: "18px",
				fontWeight: 700,
				offsetX: 0,
				offsetY: 0,
				labels: {
					useSeriesColors: true,
				},
				itemMargin: {
					horizontal: 50,
					vertical: 10,
				},
			},
		},
	})
	return (
		<div>
			<ReactApexChart
				options={options.options}
				series={data}
				type="bar"
				height={300}
			/>
		</div>
	)
}

export default SmsOverviewBarChart
