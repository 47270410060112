import { SmsCampaignRecipientSlide, SmsCampaignStepper } from "@shared/enums"

import { SmsCampaignTypes } from "."
import moment from "moment"

const INITIAL_STATE = {
	loading: false,
	stepper: {
		strategy: SmsCampaignStepper.writeMessage,
		recipient: SmsCampaignRecipientSlide.DEFAULT,
	},
	create: {
		campaignCreateType: "",
		conatctGroups: [],
		contacts: [],
		recipients: [],
		recipient: "",
		smsMask: "",
		body: "",
		smsCampaignName: "",
		smsCampaignScheduledDate: moment().add(2, "minutes"),
		smsCampaignScheduledTime: moment().add(2, "minutes"),
		smsCampaignStatus: false,
		user: null,
		file: null,
		headers: [],
	},
	costCount: {
		loading: false,
		balance: null,
		duplicateContacts: null,
		maskRate: null,
		nonMaskRate: null,
		totalContacts: null,
		totalMaskCost: null,
		totalNonMaskCost: null,
		uniqueContacts: null,
	},
}
export const smsCampaignReducer = (state = INITIAL_STATE, action: any) => {
	const { payload } = action

	switch (action.type) {
		//! INSERT USER
		case SmsCampaignTypes.SMS_CAMPAIGN_INSERT_USER:
			return {
				...state,
				create: {
					...state.create,
					user: payload,
				},
			}

		//!  CREATE
		case SmsCampaignTypes.SMS_CAMPAIGN_INSERT_BODY:
			return {
				...state,
				create: {
					...state.create,
					body: payload,
				},
			}

		case SmsCampaignTypes.SMS_CAMPAIGN_INSERT_MASK:
			return {
				...state,
				create: {
					...state.create,
					smsMask: payload,
				},
			}
		case SmsCampaignTypes.SMS_CAMPAIGN_INSERT_FILE:
			return {
				...state,
				create: {
					...state.create,
					file: payload,
				},
			}

		case SmsCampaignTypes.SMS_CAMPAIGN_INSERT_DATE:
			return {
				...state,
				create: {
					...state.create,
					smsCampaignScheduledDate: payload,
				},
			}

		case SmsCampaignTypes.SMS_CAMPAIGN_INSERT_TYPE:
			return {
				...state,
				create: {
					...state.create,
					campaignCreateType: payload,
				},
			}
		case SmsCampaignTypes.SMS_CAMPAIGN_INSERT_RECIPIENT:
			return {
				...state,
				create: {
					...state.create,
					recipient: payload,
				},
			}
		case SmsCampaignTypes.SMS_CAMPAIGN_INSERT_RECIPIENTS:
			return {
				...state,
				create: {
					...state.create,
					recipients: payload,
				},
			}

		case SmsCampaignTypes.SMS_CAMPAIGN_INSERT_SEGMENT:
			return {
				...state,
				create: {
					...state.create,
					conatctGroups: payload,
				},
			}

		case SmsCampaignTypes.SMS_CAMPAIGN_INSERT_CONTACTS:
			return {
				...state,
				create: {
					...state.create,
					contacts: payload,
				},
			}
		case SmsCampaignTypes.SMS_CAMPAIGN_INSERT_FILE_HEADER:
			return {
				...state,
				create: {
					...state.create,
					headers: payload,
				},
			}

		//!  STEPPER
		case SmsCampaignTypes.SMS_CAMPAIGN_STRATEGY_STEPS_UPDATE:
			return {
				...state,
				stepper: {
					...state.stepper,
					strategy: payload,
				},
			}
		case SmsCampaignTypes.SMS_CAMPAIGN_RECIPIENT_STEPS_UPDATE:
			return {
				...state,
				stepper: {
					...state.stepper,
					recipient: payload,
				},
			}

		case SmsCampaignTypes.SMS_CAMPAIGN_GET_COUNT_SUCCESS:
			return {
				...state,
				costCount: {
					...state.costCount,
					loading: false,
					balance: payload?.balance,
					duplicateContacts: payload?.duplicateContacts,
					maskRate: payload?.maskRate,
					nonMaskRate: payload?.nonMaskRate,
					totalContacts: payload?.totalContacts,
					totalMaskCost: payload?.totalMaskCost,
					totalNonMaskCost: payload?.totalNonMaskCost,
					uniqueContacts: payload?.uniqueContacts,
				},
			}

		//!CLEAN UP
		case SmsCampaignTypes.SMS_CAMPAIGN_CLEAN_UP:
			return {
				loading: false,
				stepper: {
					strategy: SmsCampaignStepper.writeMessage,
					recipient: SmsCampaignRecipientSlide.DEFAULT,
				},
				create: {
					campaignCreateType: "",
					conatctGroups: [],
					contacts: [],
					recipients: [],
					recipient: "",
					smsMask: "",
					body: "",
					smsCampaignName: "",
					smsCampaignScheduledDate: moment(),
					smsCampaignScheduledTime: moment(),
					smsCampaignStatus: false,
					user: null,
					file: null,
					isMasking: true,
				},
				costCount: {
					loading: false,
					balance: null,
					duplicateContacts: null,
					maskRate: null,
					nonMaskRate: null,
					totalContacts: null,
					totalMaskCost: null,
					totalNonMaskCost: null,
					uniqueContacts: null,
				},
			}

		default:
			return state
	}
}
