import { Avatar, Badge, Card, List, Space, Statistic, Typography } from "antd"

import { ENV } from "@ENV"
import React from "react"

const { Title, Text } = Typography

interface IFProps {
	title?: string
	dataSource?: { title: string; value: string | any }[]
	api?: string
	balance?: number
	status?: number
}
const CMPLog: React.FC<IFProps> = ({
	title,
	dataSource,
	api,
	balance,
	status,
}) => {
	return (
		<Badge.Ribbon text={status || "Status Not Found"}>
			<Card
				style={{ minHeight: 400 }}
				title={
					<Space>
						<Title style={{ fontSize: 22, fontWeight: "bold" }}>{title}</Title>
					</Space>
				}>
				{status === 200 ? (
					<>
						<Statistic
							value={balance}
							style={{ fontWeight: "bold" }}
							valueStyle={{ color: balance < 2000 ? "red" : "#7366ff" }}
							suffix={ENV.CURRENCY_SIGN}
							prefix={"Balance: "}
						/>
						<List
							itemLayout="horizontal"
							dataSource={dataSource}
							renderItem={(item) => (
								<List.Item key={item.title}>
									<List.Item.Meta
										avatar={
											<Avatar
												style={{
													backgroundColor: "#f43e53",
													verticalAlign: "middle",
												}}
												size="small">
												{item.title[0]}
											</Avatar>
										}
										title={item.title}
										description={<Text strong>{item.value}</Text>}
									/>
								</List.Item>
							)}
						/>{" "}
					</>
				) : (
					<h1>Something went wrong</h1>
				)}
			</Card>
		</Badge.Ribbon>
	)
}

export default CMPLog
