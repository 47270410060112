import { CoreAxiosWithOutInstanceV2 } from "../config/service/core.withoutauth.axios.v2"
import { MutationConfig } from "@shared/config/react-query"
import { useMutation } from "react-query"

const END_POINT = "/auth/individual/"
export const AuthIndividualServiceV2 = {
	login: (payload: any) => {
		return CoreAxiosWithOutInstanceV2.post(`${END_POINT}login`, payload)
	},
	register: (payload: any) => {
		return CoreAxiosWithOutInstanceV2.post(`${END_POINT}register`, payload)
	},
	resetPassword: (payload: any) => {
		return CoreAxiosWithOutInstanceV2.post(`${END_POINT}resetPassword`, payload)
	},
}
interface IndividualRegister {
	config?: MutationConfig<typeof AuthIndividualServiceV2.register>
}
export const useIndividualRegister = ({ config }: IndividualRegister) => {
	return useMutation({
		...config,
		mutationFn: AuthIndividualServiceV2.register,
	})
}
interface IndividualResetPassword {
	config?: MutationConfig<typeof AuthIndividualServiceV2.resetPassword>
}
export const useIndividualResetPassword = ({
	config,
}: IndividualResetPassword) => {
	return useMutation({
		...config,
		mutationFn: AuthIndividualServiceV2.resetPassword,
	})
}
