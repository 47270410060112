import { Button, Popconfirm, Space, Table, TableColumnsType } from "antd"
import React, { useState } from "react"
import {
	useDeleteInterCloudUser,
	useInterCloudUsers,
} from "@shared/services/intercloud-users.service"

import { useNavigate } from "react-router"

const InterCloudUsersList = () => {
	const navigator = useNavigate()

	const deleteInterCloudUserMutation = useDeleteInterCloudUser({})
	const [page, setPage] = useState(1)
	const { data, isLoading } = useInterCloudUsers({
		options: { page, take: 10 },
		config: { keepPreviousData: true },
	})

	const tableData: any =
		isLoading === false &&
		data?.data?.payload?.map((x: any, idx: number) => ({
			key: idx,
			intercloudNumber: x?.intercloudNumber || "N/A",
			maskName: x?.maskName || "N/A",
			isBusinessLeadActive: x?.isBusinessLeadActive || "N/A",
			smsContent: x?.smsContent || "N/A",
			data: x,
		}))

	const columns: TableColumnsType = [
		{
			title: "Inter Cloud Number",
			dataIndex: "intercloudNumber",
		},
		{
			title: "User",
			dataIndex: "user",
		},
		{
			title: "Mask Name",
			dataIndex: "maskName",
		},
		{
			title: "Business Lead",
			dataIndex: "isBusinessLeadActive",
		},
		{
			title: "smsContent",
			dataIndex: "smsContent",
		},
		{
			title: "Actions",
			dataIndex: "data",
			align: "right",
			render: (data: any) => {
				return (
					<Space key={data?.id}>
						<Button
							onClick={() => navigator(`/mca/${data?.id}`)}
							type="primary">
							Update
						</Button>
						<Popconfirm
							okButtonProps={{
								loading: deleteInterCloudUserMutation.isLoading,
							}}
							onConfirm={async () =>
								await deleteInterCloudUserMutation.mutateAsync(data?.id)
							}
							title="Are you sure to delete this?"
							okText="Yes"
							cancelText="No">
							<Button danger type="primary">
								Delete
							</Button>
						</Popconfirm>
					</Space>
				)
			},
		},
	]
	return (
		<>
			<Table
				scroll={{ x: 700 }}
				sticky
				loading={isLoading}
				pagination={{
					onChange: (e: any) => setPage(e),
					pageSize: 10,
					total: data?.data?.total,
					showSizeChanger: false,
				}}
				columns={columns}
				dataSource={tableData || []}
			/>
		</>
	)
}

export default InterCloudUsersList
