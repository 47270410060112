import React, { useState } from "react"

import AsyncSelect from "react-select/lib/Async"
import { MaskingTypes } from "@shared/enums"
import { getSession } from "@shared/utils"
import { useUserMasks } from "@shared/services"

const user: any = getSession()
interface IFProps {
	onChange: (e: any) => void
	label?: string
	userId?: string
	disabled?: boolean
	value?: any
	defaultValue?: any
}
export const DebounceMaskSelectField: React.FC<IFProps> = ({
	onChange,
	label = "Send Form",
	userId = user?.id,
	disabled = false,
	value,
	defaultValue,
}) => {
	const [searchTerm, setSearchTerm] = useState("")
	const userMasksQuery = useUserMasks({
		options: {
			maskingType: MaskingTypes.all,
			searchTerm,
		},
		id: userId,
	})
	const defaultOptions = userMasksQuery.data?.data?.data?.map((edge: any) => ({
		label: edge?.smsMask?.mask,
		value: edge,
	}))
	const loadOptionsCall = (inputValue: any) => {
		setSearchTerm(inputValue)
	}
	const filter = (inputValue: string) => {
		loadOptionsCall(inputValue)
		const normalizeUser = userMasksQuery.data?.data?.data?.map((edge: any) => ({
			label: edge?.smsMask?.mask,
			value: edge,
		}))
		return normalizeUser
	}
	const loadOptions = (inputValue: any, callback: any) => {
		setTimeout(() => {
			callback(filter(inputValue))
		}, 1000)
	}
	// ON CHANGE MASK
	const onChangeOption = (arrive: any) => {
		onChange(arrive?.value)
	}
	return (
		<>
			<label className="mb-0">{label}</label>
			<AsyncSelect
				className="async-select-field"
				isLoading={userMasksQuery.isLoading}
				loadOptions={loadOptions}
				cacheOptions={false}
				clearValue={true}
				defaultOptions={defaultOptions}
				isMulti={false}
				onChange={onChangeOption}
				isDisabled={disabled}
				value={value}
				isClearable={true}
				defaultValue={defaultValue}
				theme={(theme) => ({
					...theme,
					borderRadius: 0,
					border: "none",
					boxShadow: "none",
					colors: {
						...theme.colors,
					},
				})}
			/>
		</>
	)
}
