import { Outlet, useRoutes } from "react-router"
import { useInterval, useTitle } from "ahooks"

import { AppLayout } from "@shared/layout"
import { BillingRoutes } from "@modules/billing/routes"
import { CampaignRoutes } from "@modules/campaign/routes"
import { CommonRoutes } from "@modules/common/routes"
import { ContactRoutes } from "@modules/contact/routes"
import DashboardPage from "@modules/dashboard/routes/DashboardPage"
import { DashboardRoutes } from "@modules/dashboard/routes"
import { ENV } from "@ENV"
import { GatewaysRoutes } from "@modules/gateways/routes"
import { InstantSmsRoutes } from "@modules/instant-sms/routes"
import { MaskingRoutes } from "@modules/mask/routes"
import { McaRoutes } from "@modules/mca/routes"
import NotFoundPage from "@modules/common/routes/NotFoundPage"
import { OperatorsRoutes } from "@modules/operator/routes"
import { PackageRoutes } from "@modules/package/routes"
import { Paths } from "@shared/constant/Paths"
import { PatternsRoutes } from "@modules/analytics/routes"
import { PreferenceRoutes } from "@modules/preference/routes"
import { PrefixRoutes } from "@modules/prefix/pages"
import { ReRouteRoutes } from "@modules/re-route/routes"
import React from "react"
import { ReportsRoutes } from "@modules/report/routes"
import { SmsRoutes } from "@modules/sms/routes"
import { TemplatesRoutes } from "@modules/templates/routes"
import { UsersRoutes } from "@modules/user/routes"
import { storage } from "@shared/utils"
import { useAuth } from "@modules/auth/hooks/useAuth"

const routes = [
	{
		path: Paths.BillingPrefix,
		children: BillingRoutes,
	},
	{
		path: Paths.CampaignPrefix,
		children: CampaignRoutes,
	},
	{
		path: Paths.CommonPrefix,
		children: CommonRoutes,
	},
	{
		path: Paths.ContactPrefix,
		children: ContactRoutes,
	},
	{
		path: Paths.DashboardPrefix,
		children: DashboardRoutes,
	},
	{
		path: Paths.GatewayPrefix,
		children: GatewaysRoutes,
	},
	{
		path: Paths.InstantSmsPrefix,
		children: InstantSmsRoutes,
	},
	{
		path: Paths.MaskingPrefix,
		children: MaskingRoutes,
	},
	{
		path: Paths.OperatorsPrefix,
		children: OperatorsRoutes,
	},
	{
		path: Paths.PackagePrefix,
		children: PackageRoutes,
	},

	{
		path: Paths.Prefix,
		children: PrefixRoutes,
	},

	{
		path: Paths.ReRoutePrefix,
		children: ReRouteRoutes,
	},
	{
		path: Paths.ReportPrefix,
		children: ReportsRoutes,
	},
	{
		path: Paths.Sms,
		children: SmsRoutes,
	},
	{
		path: Paths.Templates,
		children: TemplatesRoutes,
	},
	{
		path: Paths.Users,
		children: UsersRoutes,
	},
	{
		path: Paths.Preference,
		children: PreferenceRoutes,
	},
	{
		path: Paths.Analytics,
		children: PatternsRoutes,
	},
	{
		path: Paths.Mca,
		children: McaRoutes,
	},
]

const App = () => {
	useTitle(String("SMS Panel"))
	const { refreshTokenFn } = useAuth()
	const token = storage.getToken()
	useInterval(() => {
		if (token) {
			refreshTokenFn.mutate(token)
		}
	}, ENV.REFRESH_TOKEN_EXPIRE_TIME)
	let pathName = window.location.pathname
	return (
		<AppLayout>
			{pathName === "/" ? <DashboardPage /> : ""}
			<Outlet />
		</AppLayout>
	)
}

export const ProtectedRoutes = () => {
	return useRoutes([
		{
			path: "",
			element: <App />,
			children: routes,
		},
		{
			path: "*",
			element: <NotFoundPage />,
		},
	])
}
