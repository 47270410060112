import { Button, Modal, Popconfirm, Space, Table, Typography } from "antd"
import React, { useState } from "react"
import { useDeletePrefix, usePrefixs, useUpdatePrefix } from "@shared/services"

import { IPrefix } from "@shared/interfaces"
import PrefixForm from "./elements/PrefixForm"
import moment from "moment"

const Prefixs = () => {
	const prefixesQuery = usePrefixs({})
	const tableData: any = prefixesQuery?.data?.data?.data?.map(
		(prefix: IPrefix) => ({
			name: prefix.name,
			operator: prefix?.operator?.name,
			created: moment(prefix?.createdAt).format("DD-MM-YYYY"),
			actions: <TableActions prefix={prefix} />,
		})
	)
	return (
		<Table
			sticky
			pagination={false}
			scroll={{ x: 300 }}
			loading={prefixesQuery.isLoading}
			columns={columns}
			dataSource={tableData}
		/>
	)
}

const TableActions: React.FC<{ prefix: IPrefix }> = ({ prefix }) => {
	const [updateUi, setUpdateUi] = useState(false)
	const deletePrefixMutation = useDeletePrefix({})
	const updatePrefixMutation = useUpdatePrefix({
		config: {
			onSuccess: (val) => {
				if (val.data?.success) {
					setUpdateUi(false)
				}
			},
		},
	})

	return (
		<>
			<Space>
				<Button type="primary" onClick={() => setUpdateUi(true)}>
					Update
				</Button>
				<Popconfirm
					title="Are you sure?"
					onConfirm={() => deletePrefixMutation.mutate(prefix.id)}
					okText="Yes"
					okButtonProps={{
						loading: deletePrefixMutation.isLoading,
					}}
					cancelText="No">
					<Button type="primary" danger>
						Delete
					</Button>
				</Popconfirm>
			</Space>
			<Modal
				footer={null}
				visible={updateUi}
				title="Update Operator"
				onCancel={() => setUpdateUi(false)}>
				<Typography.Title level={4}>Update Prefix</Typography.Title>
				<PrefixForm
					initialValue={{
						name: prefix.name,
						operator: {
							label: prefix.operator.name,
							value: prefix.operator.id,
						},
					}}
					loading={updatePrefixMutation.isLoading}
					onFinish={(val) => {
						updatePrefixMutation.mutate({
							id: prefix.id,
							payload: { name: val.name, operator: val?.operator?.value },
						})
					}}
				/>
			</Modal>
		</>
	)
}
export default Prefixs
const columns: any = [
	{
		title: "Name",
		dataIndex: "name",
	},
	{
		title: "Operator",
		dataIndex: "operator",
	},
	{
		title: "Created",
		dataIndex: "created",
	},
	{
		title: "Actions",
		dataIndex: "actions",
		align: "right",
	},
]
