import { CoreAxiosWithOutInstanceV2 } from "@shared/config/service/core.withoutauth.axios.v2"

const END_POINT = "/auth/"

export const AuthService = {
	refreshToken(token: string): any {
		return CoreAxiosWithOutInstanceV2.post(`${END_POINT}refreshToken`, {
			token,
		})
	},
}
