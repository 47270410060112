import { AiOutlineCheck, AiOutlineClose } from "react-icons/ai"
import { Popconfirm, Switch, Table } from "antd"
import React, { useState } from "react"
import {
	useApproveResellerRequest,
	useResellerRequests,
} from "@shared/services"

import { statusToJsxUtil } from "@shared/utils"

const ResellerRequest = () => {
	const userTypeToBadge = (userType: string) => {
		return userType === "RESELLER" ? true : false
	}
	const [options, setOptions] = useState({ page: 1, take: 10 })

	const resellerRequestQuery = useResellerRequests({
		options: { page: options.page, take: options.take },
		config: { keepPreviousData: true },
	})
	const useApprovedMutation = useApproveResellerRequest({})
	const tableData: any = resellerRequestQuery?.data?.data?.data?.map(
		(x: any, idx: number) => ({
			key: idx,
			email: x?.email,
			company: x?.company,
			status: statusToJsxUtil(x?.user?.type),
			action: (
				<Popconfirm
					title="Are you sure?"
					onConfirm={() => useApprovedMutation.mutate(x?.user?.id)}
					okText="Yes"
					okButtonProps={{
						loading: useApprovedMutation.isLoading,
					}}
					cancelText="No">
					<Switch
						checkedChildren={<AiOutlineCheck />}
						unCheckedChildren={<AiOutlineClose />}
						checked={userTypeToBadge(x?.user?.type)}
					/>
				</Popconfirm>
			),
		})
	)

	return (
		<>
			<Table
				sticky
				scroll={{ x: 800 }}
				loading={resellerRequestQuery.isLoading}
				pagination={{
					onChange: (page: number) => {
						setOptions({
							page,
							take: 10,
						})
					},
					pageSize: 10,
					total: resellerRequestQuery?.data?.data?.total,
					showSizeChanger: false,
					current: resellerRequestQuery?.data?.data?.page,
				}}
				columns={columns}
				dataSource={tableData}
			/>
		</>
	)
}
export default ResellerRequest
const columns: any = [
	{
		title: "Email",
		dataIndex: "email",
		width: 200,
		//@ts-ignore
		sorter: (a: any, b: any) => a.email === b.email,
	},
	{
		title: "Company",
		dataIndex: "company",
		width: 100,
		//@ts-ignore
		sorter: (a: any, b: any) => a.company === b.company,
	},
	{
		title: "Status",
		dataIndex: "status",
		width: 100,
		defaultSortOrder: "descend",
		//@ts-ignore
		sorter: (a: any, b: any) => {
			return a.status.children === b.status.children
		},
	},
	{
		title: "Action",
		dataIndex: "action",
		width: 50,
		align: "center",
		fixed: "right",
	},
]
