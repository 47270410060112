import { Button, PageHeader } from "antd"

import { AppContent } from "@shared/layout"
import InterCloudUsersList from "../components/InterCloudUsersList"
import { Paths } from "@shared/constant"
import React from "react"
import { useNavigate } from "react-router-dom"

const InterCloudUsers = () => {
	const navigator = useNavigate()

	return (
		<AppContent>
			<PageHeader
				onBack={() => navigator(-1)}
				title="MAC InterCloud Users"
				extra={[
					<Button
						type="primary"
						onClick={() => navigator(`${Paths.Mca}create`)}>
						Create User
					</Button>,
				]}
			/>
			<InterCloudUsersList />
		</AppContent>
	)
}

export default InterCloudUsers
