import MaskForm from "./elements/MaskForm"
import React from "react"
import { boolToStatusUtil } from "@shared/utils"
import { useCreateSMSMask } from "@shared/services"
import { useNavigate } from "react-router"

const CreateSmsMask = () => {
	const navigator = useNavigate()

	const createSMSMaskMutation = useCreateSMSMask({
		config: {
			onSuccess: (val) => {
				if (val?.data?.success) {
					navigator(-1)
				}
			},
		},
	})
	return (
		<>
			<MaskForm
				initialValue={{ maskingName: "", status: false, isMasking: true }}
				onFinish={(val) => {
					createSMSMaskMutation.mutate({
						mask: val.maskingName,
						isMasking: val.isMasking,
						status: boolToStatusUtil(val.status),
					})
				}}
			/>
		</>
	)
}
export default CreateSmsMask
