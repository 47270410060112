import {
	AiOutlineAim,
	AiOutlineApartment,
	AiOutlineBell,
	AiOutlineDashboard,
	AiOutlineGateway,
	AiOutlineMail,
	AiOutlineMergeCells,
	AiOutlinePullRequest,
	AiOutlineRetweet,
} from "react-icons/ai"
import { BiLayerPlus, BiPackage, BiReceipt, BiWalletAlt } from "react-icons/bi"
import { BsPeople, BsTextarea } from "react-icons/bs"
import { ChooseYourCampaignChannel, Paths } from "@shared/constant"
import {
	FaRegTimesCircle,
	FaTheaterMasks,
	FaViber,
	FaWhatsapp,
} from "react-icons/fa"
import {
	HiOutlineClipboardList,
	HiOutlineDocumentDuplicate,
} from "react-icons/hi"
import {
	IoIosGitNetwork,
	IoIosInfinite,
	IoMdAlarm,
	IoMdPaperPlane,
} from "react-icons/io"
import { MdAirlineSeatIndividualSuite, MdSpeakerPhone } from "react-icons/md"
import React, { Fragment, useState } from "react"
import { RiDraftLine, RiUserVoiceLine } from "react-icons/ri"

import CampaignChannels from "@modules/campaign/components/overview/CampaignChannels"
import { Link } from "react-router-dom"
import { SidebarNav } from "./elements/sidebar-nav.component"
import { TiStopwatch } from "react-icons/ti"
import { VscSettingsGear } from "react-icons/vsc"

const LeftSidebar: React.FC = () => {
	const [uiState, setUiState] = useState<any>({
		campaignOptionsPopup: false,
	})
	const { campaignOptionsPopup } = uiState

	return (
		<Fragment>
			{/* menus */}
			<div className='left-sidebar-navigation scroll'>
				<Link to={Paths.DefaultDashboard}>
					<SidebarNav title='Dashboard' icon={<AiOutlineDashboard />} />
				</Link>
				<div className='left-sidebar-nav-item ' id='nav-item-campaign'>
					<SidebarNav title='Campaign' icon={<TiStopwatch />} />
				</div>
				<Link to={Paths.ContactList}>
					<SidebarNav title='Contacts' icon={<BsPeople />} />
				</Link>
				<div className='nav-channels-items'>
					<span>Channels</span>
					<div className='left-sidebar-nav-item' id='nav-item-sms'>
						<SidebarNav title='SMS' icon={<TiStopwatch />} flatMenu />
					</div>
					<div className='left-sidebar-nav-item' id='nav-item-voice'>
						<SidebarNav title='Voice' icon={<RiUserVoiceLine />} flatMenu />
					</div>
					<div className='left-sidebar-nav-item'>
						<SidebarNav title='WhatsApp' icon={<FaWhatsapp />} flatMenu />
					</div>
					<div className='left-sidebar-nav-item'>
						<SidebarNav title='Viber' icon={<FaViber />} flatMenu />
					</div>
					<div className='left-sidebar-nav-item'>
						<SidebarNav title='Email' icon={<AiOutlineMail />} flatMenu />
					</div>
				</div>

				<div className='left-sidebar-nav-item ' id='nav-item-analytics'>
					<SidebarNav title='Analytics' icon={<AiOutlineAim />} />
				</div>

				<Link to={Paths.PushPullPlayGround}>
					<SidebarNav title='Push Pull' icon={<AiOutlinePullRequest />} />
				</Link>

				<Link to={Paths.ReportList}>
					<SidebarNav title='Reports' icon={<HiOutlineDocumentDuplicate />} />
				</Link>

				<Link to={Paths.MaskingList}>
					<SidebarNav title='Masking' icon={<FaTheaterMasks />} />
				</Link>

				<Link to={Paths.OperatorsList}>
					<SidebarNav title='Operator' icon={<MdSpeakerPhone />} />
				</Link>
				<Link to={Paths.PrefixList}>
					<SidebarNav title='Prefix' icon={<AiOutlineRetweet />} />
				</Link>
				<Link to={Paths.GatewayList}>
					<SidebarNav title='Gateway' icon={<AiOutlineGateway />} />
				</Link>
				<Link to={Paths.UsersList}>
					<SidebarNav
						title='Individual'
						icon={<MdAirlineSeatIndividualSuite />}
					/>
				</Link>
				<Link to={Paths.UsersResellerRequest}>
					<SidebarNav
						title='Reseller Request'
						icon={<AiOutlinePullRequest />}
					/>
				</Link>
				<Link to={Paths.ApiDocs}>
					<SidebarNav title='API Docs' icon={<IoIosGitNetwork />} />
				</Link>
				<Link to={Paths.Mca}>
					<SidebarNav title='MCA' icon={<AiOutlineMergeCells />} />
				</Link>
				<div className='left-sidebar-nav-item ' id='nav-item-billing'>
					<SidebarNav title='Billing' icon={<BiReceipt />} />
				</div>
				<div className='left-sidebar-nav-item ' id='nav-item-manages'>
					<SidebarNav title='Manages' icon={<VscSettingsGear />} />
				</div>

				<div className='left-sidebar-nav-item' id='nav-item-reRoute'>
					<SidebarNav title='Re-Route' icon={<HiOutlineDocumentDuplicate />} />
				</div>
			</div>
			{/* menus */}
			<div className='left-sidebar-sub-navigation'>
				<span className='sidebar-close'>
					<FaRegTimesCircle />
				</span>
				<div className='campaign-sub-menu' id='campaign-sub-menu'>
					<div className='left-sidebar-nav-item'>
						<Link to={Paths.CampaignOverview}>
							<SidebarNav
								title='Overview'
								icon={<HiOutlineClipboardList />}
								flatMenu
							/>
						</Link>
					</div>
					<div className='left-sidebar-nav-item'>
						<SidebarNav
							onClick={() =>
								setUiState({ ...uiState, campaignOptionsPopup: true })
							}
							title='Create Campaign'
							icon={<BiLayerPlus />}
							flatMenu
						/>
					</div>
					<div className='left-sidebar-nav-item'>
						<SidebarNav title='Scheduled' icon={<IoMdAlarm />} flatMenu />
					</div>
					<div className='left-sidebar-nav-item'>
						<SidebarNav title='Drafts' icon={<RiDraftLine />} flatMenu />
					</div>
					<div className='left-sidebar-nav-item'>
						<SidebarNav title='Sent' icon={<IoMdPaperPlane />} flatMenu />
					</div>
				</div>
				<div className='campaign-sub-menu' id='campaign-sub-sms'>
					<div className='left-sidebar-nav-item'>
						<Link to={Paths.SmsOverview}>
							<SidebarNav
								title='Overview'
								icon={<HiOutlineClipboardList />}
								flatMenu
							/>
						</Link>
					</div>
					<div className='left-sidebar-nav-item'>
						<Link to={Paths.CreateSmsCampaign}>
							<SidebarNav title='Campaigns' icon={<BiLayerPlus />} flatMenu />
						</Link>
					</div>
					<div className='left-sidebar-nav-item'>
						<Link to={Paths.InstantMessage}>
							<SidebarNav title='Instant SMS' icon={<IoMdAlarm />} flatMenu />
						</Link>
					</div>
					<div className='left-sidebar-nav-item'>
						<Link to='/bulk-sms'>
							<SidebarNav title='Bulk SMS' icon={<RiDraftLine />} flatMenu />
						</Link>
					</div>
					<div className='left-sidebar-nav-item'>
						<Link to={Paths.SmsTemplatesList}>
							<SidebarNav title='Templates' icon={<RiDraftLine />} flatMenu />
						</Link>
					</div>
					<div className='left-sidebar-nav-item'>
						<Link to={Paths.PackagePrefix}>
							<SidebarNav title='SMS Package' icon={<BiPackage />} flatMenu />
						</Link>
					</div>
					<div className='left-sidebar-nav-item'>
						<SidebarNav title='Add-Ons' icon={<IoMdPaperPlane />} flatMenu />
					</div>
				</div>
				<div className='campaign-sub-menu' id='campaign-sub-voice'>
					<div className='left-sidebar-nav-item'>
						<SidebarNav
							title='Overview'
							icon={<HiOutlineClipboardList />}
							flatMenu
						/>
					</div>
					<div className='left-sidebar-nav-item'>
						<SidebarNav title='Campaigns' icon={<TiStopwatch />} flatMenu />
					</div>
					<div className='left-sidebar-nav-item'>
						<SidebarNav
							title='Audio Templates'
							icon={<IoIosInfinite />}
							flatMenu
						/>
					</div>
					<div className='left-sidebar-nav-item'>
						<SidebarNav title='Logs' icon={<AiOutlineBell />} flatMenu />
					</div>
					<div className='left-sidebar-nav-item'>
						<SidebarNav title='Add-Ons' icon={<AiOutlineBell />} flatMenu />
					</div>
				</div>
				<div className='campaign-sub-menu' id='campaign-sub-automations'>
					<div className='left-sidebar-nav-item'>
						<SidebarNav
							title='Overview'
							icon={<HiOutlineClipboardList />}
							flatMenu
						/>
					</div>
					<div className='left-sidebar-nav-item'>
						<SidebarNav
							title='Flow Builder'
							icon={<AiOutlineApartment />}
							flatMenu
						/>
					</div>
					<div className='left-sidebar-nav-item'>
						<SidebarNav
							title='Auto Responders'
							icon={<IoIosInfinite />}
							flatMenu
						/>
					</div>
					<div className='left-sidebar-nav-item'>
						<SidebarNav
							title='Alerts and Notification'
							icon={<AiOutlineBell />}
							flatMenu
						/>
					</div>
				</div>
				<div className='campaign-sub-menu' id='campaign-sub-billing'>
					<Link to={Paths.CMPActivity}>
						<div className='left-sidebar-nav-item'>
							<SidebarNav
								title='CMP Activity'
								icon={<BiWalletAlt />}
								flatMenu
							/>
						</div>
					</Link>
					<div className='left-sidebar-nav-item'>
						<SidebarNav
							title='Add Fund'
							icon={<HiOutlineClipboardList />}
							flatMenu
						/>
					</div>

					<div className='left-sidebar-nav-item'>
						<Link to={Paths.BillingPrefix}>
							<SidebarNav
								title='Payment History'
								icon={<AiOutlineApartment />}
								flatMenu
							/>
						</Link>
					</div>
					<div className='left-sidebar-nav-item'>
						<SidebarNav
							title='Subscriptions'
							icon={<IoIosInfinite />}
							flatMenu
						/>
					</div>
					<div className='left-sidebar-nav-item'>
						<SidebarNav
							title='Billing Address'
							icon={<AiOutlineBell />}
							flatMenu
						/>
					</div>
				</div>
				<div className='campaign-sub-menu' id='campaign-sub-manages'>
					<div className='left-sidebar-nav-item'>
						<SidebarNav
							title='Account'
							icon={<HiOutlineClipboardList />}
							flatMenu
						/>
					</div>
					<div className='left-sidebar-nav-item'>
						<SidebarNav
							title='Security'
							icon={<AiOutlineApartment />}
							flatMenu
						/>
					</div>
					<div className='left-sidebar-nav-item'>
						<SidebarNav
							title='Notifications'
							icon={<IoIosInfinite />}
							flatMenu
						/>
					</div>
					<div className='left-sidebar-nav-item'>
						<SidebarNav
							title='Delete Account'
							icon={<AiOutlineBell />}
							flatMenu
						/>
					</div>
				</div>
				{/* Re-Route */}
				<div className='campaign-sub-menu' id='sms-sub-reRoute'>
					<div className='left-sidebar-nav-item'>
						<Link to={Paths.ReRouteForceRoute}>
							<SidebarNav
								title='Sms Re-Route'
								icon={<HiOutlineClipboardList />}
								flatMenu
							/>
						</Link>
					</div>
					<div className='left-sidebar-nav-item'>
						<Link to={Paths.ReRouteForceSuccess}>
							<SidebarNav
								title='Sms Force Success'
								icon={<AiOutlineApartment />}
								flatMenu
							/>
						</Link>
					</div>
				</div>
				{/* Re-Route */}
				{/* Analytics Sub-menus */}
				<div className='campaign-sub-menu' id='analytics-sub-menus'>
					<div className='left-sidebar-nav-item'>
						<Link to={Paths.Patterns}>
							<SidebarNav title='Patterns' icon={<BsTextarea />} flatMenu />
						</Link>
					</div>
				</div>
				{/* Analytics Sub-menus */}
			</div>

			<CampaignChannels
				links={{ sms: Paths.CreateSmsCampaign }}
				open={campaignOptionsPopup}
				close={() => {
					setUiState({ ...uiState, campaignOptionsPopup: false })
				}}
				title={ChooseYourCampaignChannel}
			/>
		</Fragment>
	)
}

export default LeftSidebar
