import { Button, Input, Modal } from "antd"
import { CommonTitle, Purify } from "@shared/components"
import React, { useState } from "react"
import { useCreateSMPP, useSMPP, useUpdateSMPP } from "@shared/services"

import { AiOutlineAccountBook } from "react-icons/ai"

interface IFProps {
	userId: string
}
const CreateSmpp: React.FC<IFProps> = ({ userId }) => {
	const [smpp, setSmpp] = useState<any>({
		id: "",
		systemId: "",
		password: "",
	})

	const smppQuery = useSMPP({
		options: { page: 1, take: 1, user: userId },
		config: {
			onSuccess: (val) => {
				setSmpp({
					id: val?.data?.data?.id,
					systemId: val?.data?.data?.systemId,
					password: val?.data?.data?.password,
				})
			},
		},
	})
	const createSMPPMutation = useCreateSMPP({})
	const updateSMPPMutation = useUpdateSMPP({})
	const onChangeHandler = (e: any) => {
		setSmpp({
			...smpp,
			[e.target.name]: e.target.value,
		})
	}
	const onSubmitHandler = (e: any) => {
		e.preventDefault()
		Modal.confirm({
			title: "Are you sure?",
			onOk: () => {
				!smpp.id
					? createSMPPMutation.mutate({
							user: userId,
							systemId: smpp.systemId,
							password: smpp.password,
					  })
					: updateSMPPMutation.mutate({
							id: smpp.id,
							payload: {
								user: userId,
								systemId: smpp.systemId,
								password: smpp.password,
							},
					  })
			},
		})
	}

	return (
		<>
			<CommonTitle title="SMPP" icon={<AiOutlineAccountBook />} />
			<Purify
				empty={false}
				loading={createSMPPMutation.isLoading || smppQuery.isLoading}>
				<form className="pt-30" onSubmit={onSubmitHandler}>
					<label htmlFor="System Id">System Id</label>
					<Input
						onChange={onChangeHandler}
						name="systemId"
						value={smpp?.systemId}
					/>
					<label htmlFor="Password">Password</label>
					<Input
						onChange={onChangeHandler}
						name="password"
						value={smpp?.password}
					/>
					<Button
						htmlType="submit"
						style={{ marginTop: 30 }}
						disabled={!smpp?.systemId || !smpp?.password}
						type="primary">
						{smpp?.id?.length ? "Update" : "Create"}
					</Button>
				</form>
			</Purify>
		</>
	)
}
export default CreateSmpp
