import { MutationConfig, QueryConfig, queryClient } from "@shared/config"
import { useMutation, useQuery } from "react-query"

import { ContactServiceV2 } from "."
import { CoreAxiosInstanceV2 } from "@shared/config/service/core.axios.v2"
import { IBaseQuery } from "@shared/interfaces"
import { concatQuery } from './../utils/util-function'

const END_POINT = "/resellerRequests/"

export const ResellerRequestServiceV2 = {
	NAME: "ResellerRequestService",
	filter(options: IBaseQuery): any {
		return CoreAxiosInstanceV2.get(`${END_POINT}?${concatQuery(options)}`)
	},
	approved(user: string) {
		return CoreAxiosInstanceV2.post(`${END_POINT}approve`, { user })
	},
}

interface ResellersRequests {
	config?: QueryConfig<typeof ContactServiceV2.filter>
	options?: IBaseQuery
}
export const useResellerRequests = ({
	config,
	options,
}: ResellersRequests = {}) => {
	const KEY = `${ResellerRequestServiceV2.NAME}${ResellerRequestServiceV2.filter.name}`

	return useQuery({
		...config,
		queryKey: [KEY, Object.values(options)],
		queryFn: () => ResellerRequestServiceV2.filter(options),
	})
}

interface ApproveResellerRequest {
	config?: MutationConfig<typeof ResellerRequestServiceV2.approved>
}
export const useApproveResellerRequest = (config: ApproveResellerRequest) => {
	const INVALID_QUERY_KEY = `${ResellerRequestServiceV2.NAME}${ResellerRequestServiceV2.filter.name}`
	return useMutation({
		...config,
		mutationFn: ResellerRequestServiceV2.approved,
		onSettled: () => {
			queryClient.invalidateQueries(INVALID_QUERY_KEY)
		},
	})
}
