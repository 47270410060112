import {
	CoreAxiosInstanceV2,
	MutationConfig,
	QueryConfig,
	queryClient,
} from "@shared/config"
import { useMutation, useQuery } from "react-query"

import { concatQuery } from "@shared/utils"

const END_POINT = "/userApiKeys/"

export interface IGetUserAPIKey {
	user?: string
	single?: boolean
}
export const UserApiKeyServiceV2 = {
	NAME: "UserApiKeyService",

	create(userId: string): any {
		return CoreAxiosInstanceV2.post(`${END_POINT}createApiKey`, { userId })
	},
	filter(options: IGetUserAPIKey) {
		return CoreAxiosInstanceV2.get(`${END_POINT}?${concatQuery(options)}`)
	},
}

interface CreateAPIKey {
	config?: MutationConfig<typeof UserApiKeyServiceV2.filter>
}
export const useCreateUserAPIKey = (config: CreateAPIKey) => {
	const INVALID_QUERY_KEY = `${UserApiKeyServiceV2.NAME}${UserApiKeyServiceV2.filter.name}`
	return useMutation({
		...config,
		mutationFn: UserApiKeyServiceV2.create,
		onSettled: () => {
			queryClient.invalidateQueries(INVALID_QUERY_KEY)
		},
	})
}

interface GetAPIKey {
	config?: QueryConfig<typeof UserApiKeyServiceV2.filter>
	options?: IGetUserAPIKey
}
export const useGetAPIKey = ({ config, options }: GetAPIKey = {}) => {
	const KEY = `${UserApiKeyServiceV2.NAME}${UserApiKeyServiceV2.filter.name}`
	return useQuery({
		...config,
		queryKey: [KEY, Object.values(options)],
		queryFn: () => UserApiKeyServiceV2.filter(options),
	})
}
