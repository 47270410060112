import { Button, Form, Input, Modal, message } from "antd"
import {
	useIndividualResetPassword,
	useIndividualUserForResellerResetPassword,
	useUser,
} from "@shared/services"

import { ARE_YOU_SURE_TO_RESET_PASSWORD } from "@shared/constant"
import React from "react"
import { UserType } from "@shared/enums"
import { getSession } from "@shared/utils"

interface IFProps {
	userId?: string
}
const UserPasswordChange: React.FC<IFProps> = ({ userId }) => {
	const userQuery = useUser({ id: userId || getSession().id })

	const individualResetPassword = useIndividualResetPassword({
		config: {
			onSuccess: (val) => {
				if (val.data.success) {
					message.success("Success")
				}
			},
		},
	})
	const individualUserForResellerResetPassword = useIndividualUserForResellerResetPassword(
		{
			config: {
				onSuccess: (val) => {
					if (val.data.success) {
						message.success("Success")
					}
				},
			},
		}
	)
	const onSubmitHandler = (value: any) => {
		const role = userQuery.data?.data?.data?.type
		if (role === UserType.INDIVIDUAL) {
			Modal.confirm({
				title: ARE_YOU_SURE_TO_RESET_PASSWORD,
				onOk: () => individualResetPassword.mutate(value),
			})
		} else {
			Modal.confirm({
				title: ARE_YOU_SURE_TO_RESET_PASSWORD,
				onOk: () => individualUserForResellerResetPassword.mutate(value),
			})
		}
	}
	return (
		<Form
			name="basic"
			initialValues={{
				email: "",
				oldPassword: "",
				newPassword: "",
			}}
			layout="vertical"
			onFinish={onSubmitHandler}>
			<Form.Item
				label="Email"
				name="email"
				rules={[{ required: true, message: "Please input your email!" }]}>
				<Input size="large" type="email" placeholder="Enter Your email" />
			</Form.Item>
			<Form.Item
				label="Old Password"
				name="oldPassword"
				rules={[
					{ required: true, message: "Please input your old password!" },
				]}>
				<Input.Password size="large" placeholder="Enter Your old password" />
			</Form.Item>
			<Form.Item
				label="New Password"
				name="newPassword"
				rules={[
					{ required: true, message: "Please input your new password!" },
				]}>
				<Input.Password size="large" placeholder="Enter Your new Password" />
			</Form.Item>
			<Form.Item>
				<Button
					loading={individualResetPassword?.isLoading}
					type="primary"
					htmlType="submit">
					Submit
				</Button>
			</Form.Item>
		</Form>
	)
}
export default UserPasswordChange
