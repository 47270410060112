export const ACTIVE = "ACTIVE"

export const NoDataFound = "No Data Found"

export const ARE_YOU_SURE_TO_UPDATE_PROFILE_INFO =
	"Are you sure to Update Profile Info?"

export const ARE_YOU_SURE_TO_RESET_PASSWORD = "Are you sure to reset Password?"

export const PERSONAL_INFO = "Personal Info"

export const PASTE_NUMBER_EXAMPLE =
	"Example:01923534635,01823534635,01823534635"

export const ARE_YOU_SURE = "Are you sure ?"

export const MASK_ASSIGN = "Mask Assign"

export const MASK_LIST = "Mask List"

export const PACKAGE_UPDATE = "Package Update"

export const API_KEY = "Api Key"

export const ChooseYourChannel = "Choose Your Channel"

export const ChooseYourCampaignChannel = "Choose Your Campaign Channel"

export const AdminConfig = "Admin Config"

export const EnterNumber = "Enter Number"

export const FromContactsList = "From Contacts List"

export const ChooseYourRecipientFormExistingContacts =
	"Choose your recipient form existing Contacts"

export const FromSegments = "From Segments"

export const ChooseYourRecipientFormExistingSegments =
	"Choose your recipient form existing segments."

export const UploadContactsFile = "Upload Contacts"

export const ContactFileWithBody = "Upload Contacts with Body"

export const PasteNumber = "Paste Number"

export const ManuallyTypeYourNumber = "Manually Type Your Number"

export const UploadContactWithCustomParameterized =
	"Upload Contact File with Custom Parameter"

export const SendSMSToSingleRecipient = "Send SMS to Single Recipient"

export const UploadYourRecipientUsingCSV = "Upload Your Recipient using CSV"

export const UploadYourRecipientAndBodyUsingCSV =
	"Upload Your Recipient And Body using CSV"

export const UploadYourRecipientWithCustomParameter =
	"Upload Your Recipient with Custom Parameter"
