import { Card, Row, Space } from "antd"

import AdminLogin from "../components/AdminLogin"
import Logo from "@shared/components/Logo"
import React from "react"

const lStyle = {
	minHeight: "100vh",
	minWidth: "100vw",
	backgroundPosition: "center",
	backgroundRepeat: "no-repeat",
}
const AuthPage = () => {
	return (
		<Row align="middle" style={lStyle} justify="center" className="mx-3">
			<Card
				style={{ minWidth: 380 }}
				title={
					<Space style={{ display: "flex", justifyContent: "center" }}>
						<Logo />
					</Space>
				}>
				<AdminLogin />
			</Card>
		</Row>
	)
}

export default AuthPage
