import { Dropdown, Layout, Menu, Space } from "antd"
import { Link, useNavigate } from "react-router-dom"
import {
	MenuFoldOutlined,
	MenuUnfoldOutlined,
	UserOutlined,
} from "@ant-design/icons"
import { useDispatch, useSelector } from "react-redux"

import { BiExit } from "react-icons/bi"
import { IMAGES } from "@shared/assets"
import { Paths } from "@shared/constant"
import React from "react"
import { createStructuredSelector } from "reselect"
import { getSession } from "@shared/utils"
import { selectAppSidebarToggle } from "@shared/redux/layout/app-layout.selectors"
import { useAuth } from "@modules/auth/hooks/useAuth"
import { useUser } from "@shared/services"
import { visibilityAppLeftSidebarAction } from "@shared/redux/layout/app-layout.actions"

const { Header } = Layout
export const AppHeader = () => {
	const userId = getSession()?.id
	const navigator = useNavigate()
	const { logoutFn } = useAuth()
	const userQuery = useUser({ id: userId })
	const user: any = userQuery?.data?.data?.data

	const dispatch = useDispatch()
	const { appLayoutSidebar } = useSelector(
		createStructuredSelector({
			appLayoutSidebar: selectAppSidebarToggle,
		})
	)
	const { isOpenLeftSidebar } = appLayoutSidebar
	const profileMenus = (
		<Menu>
			<Menu.Item
				key="1"
				icon={<UserOutlined />}
				onClick={() => navigator(Paths.UsersMe)}>
				My Profile
			</Menu.Item>
			<Menu.Item key="2" icon={<BiExit />} onClick={logoutFn}>
				Logout
			</Menu.Item>
		</Menu>
	)

	return (
		<Header className="app-header">
			<Space
				align="center"
				style={{
					display: "flex",
					justifyContent: "space-between",
					marginRight: 14,
				}}>
				<div className="d-flex">
					<div
						onClick={() =>
							dispatch(visibilityAppLeftSidebarAction(!isOpenLeftSidebar))
						}
						className="trigger">
						{isOpenLeftSidebar ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
					</div>
					<Link to="/" className="desktop-only">
						<img height="40" src={IMAGES.MaLogo} alt={"HomeLogo"} />
					</Link>
				</div>
				<Space align="center">
					<Dropdown.Button
						onClick={() => navigator(Paths.UsersMe)}
						overlay={profileMenus}
						placement="bottomCenter"
						icon={<UserOutlined />}>
						{userQuery.isLoading ? "loading..." : user?.userInfo?.name}
					</Dropdown.Button>
				</Space>
			</Space>
		</Header>
	)
}
