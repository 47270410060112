import {
	CoreAxiosInstanceV2,
	MutationConfig,
	QueryConfig,
	queryClient,
} from "@shared/config"
import {
	IFilterResellerUser,
	IResellerFilterWithUser,
} from "@shared/interfaces"
import { useMutation, useQuery } from "react-query"

import { concatQuery } from "@shared/utils"

const END_POINT = "/resellers/"
export const ResellersServiceV2 = {
	NAME: "ResellersService",
	filter(options: IFilterResellerUser) {
		return CoreAxiosInstanceV2.get(`${END_POINT}filter?${concatQuery(options)}`)
	},
	maskAssign(payload: any) {
		return CoreAxiosInstanceV2.post(`${END_POINT}maskAssign`, payload)
	},
	filterWithUser(options: IResellerFilterWithUser) {
		return CoreAxiosInstanceV2.get(
			`${END_POINT}filterWithUser?${concatQuery(options)}`
		)
	},
}
interface ResellerMaskAssign {
	config?: MutationConfig<typeof ResellersServiceV2.filter>
}
export const useResellerMaskAssign = ({ config }: ResellerMaskAssign) => {
	return useMutation({
		...config,
		mutationFn: ResellersServiceV2.maskAssign,
		onSettled: () => {
			queryClient.invalidateQueries(
				`${ResellersServiceV2.NAME}${ResellersServiceV2.filter.name}`
			)
		},
	})
}
interface UserAssignedMasks {
	config?: QueryConfig<typeof ResellersServiceV2.filterWithUser>
	options?: IResellerFilterWithUser
}
export const useUserAssignedMasks = ({
	config,
	options,
}: UserAssignedMasks = {}) => {
	const queryName = `${ResellersServiceV2.NAME}${ResellersServiceV2.filterWithUser.name}`
	return useQuery({
		...config,
		queryKey: [queryName, Object.values(options)],
		queryFn: () => ResellersServiceV2.filterWithUser(options),
	})
}
interface Resellers {
	config?: QueryConfig<typeof ResellersServiceV2.filter>
	options?: IFilterResellerUser
}
export const useResellers = ({ config, options }: Resellers = {}) => {
	const queryName = `${ResellersServiceV2.NAME}${ResellersServiceV2.filter.name}`
	return useQuery({
		...config,
		queryKey: [queryName, Object.values(options)],
		queryFn: () => ResellersServiceV2.filter(options),
	})
}
