import { Button, Col, Divider, Row, message } from "antd"
import {
	DebounceGatewaySelectField,
	DebounceUserSelectField,
} from "@shared/components"
import React, { useState } from "react"

import { DateRangePicker } from "react-date-range"
import moment from "moment"
import { useSMSReRoute } from "@shared/services"

const SmsReRoute = () => {
	const [range, setRange] = useState({
		startDate: new Date(),
		endDate: new Date(),
		key: "selection",
	})

	const [hitLoad, setHitLoad] = useState({
		gateway: "",
		newGateway: "",
		user: "",
	})

	const smsReRouteQuery = useSMSReRoute({
		options: {
			startDate: moment(range.startDate).format("YYYY-MM-DD"),
			endDate: moment(range.endDate).format("YYYY-MM-DD"),
			gateway: hitLoad.gateway,
			newGateway: hitLoad.newGateway,
			user: hitLoad.user,
		},
		config: {
			enabled: false,
			onSuccess: (val) => {
				if (val.data.success) {
					message.success("Success")
				}
			},
		},
	})

	return (
		<>
			<Row gutter={20}>
				<Col lg={8}>
					<DebounceUserSelectField
						onChange={(val: any) => {
							setHitLoad({
								...hitLoad,
								user: val?.value?.id,
							})
						}}
					/>
				</Col>
				<Col lg={8}>
					<DebounceGatewaySelectField
						onChange={(val: any) => {
							setHitLoad({
								...hitLoad,
								gateway: val?.value?.id,
							})
						}}
					/>
				</Col>
				<Col lg={8}>
					<DebounceGatewaySelectField
						label="New Gateway"
						onChange={(val: any) => {
							setHitLoad({
								...hitLoad,
								newGateway: val?.value?.id,
							})
						}}
					/>
				</Col>
			</Row>
			<Divider />
			<>
				<DateRangePicker
					onChange={(item: any) => setRange(item.selection)}
					showSelectionPreview={true}
					moveRangeOnFirstSelection={false}
					months={2}
					ranges={[range]}
					direction="horizontal"
					maxDate={new Date()}
				/>
				<div className="flex justify-end pt-5">
					<Button
						size="large"
						type="primary"
						onClick={() => smsReRouteQuery.refetch()}
						loading={smsReRouteQuery.isLoading}>
						Submit
					</Button>
				</div>
			</>
		</>
	)
}

export default SmsReRoute
