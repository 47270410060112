import { Button, Modal, PageHeader } from "antd"
import React, { useState } from "react"

import { AppContent } from "@shared/layout"
import { Paths } from "@shared/constant"
import UserCreateFrom from "../components/UserCreate"
import Users from "../components/Users"
import { getSession } from "@shared/utils"
import { useIndividualRegister } from "@shared/services"
import { useNavigate } from "react-router-dom"

const UsersPage = () => {
	const navigator = useNavigate()
	const [uiState, setUiState] = useState({ createModal: false })

	const user: any = getSession()
	const registerIndividualMutation = useIndividualRegister({
		config: {
			onSuccess: (val) => {
				if (val.data.success) {
					setUiState({ createModal: false })
				}
			},
		},
	})

	const onSubmitHandler = (value: any) => {
		registerIndividualMutation.mutate({ ...value, parentId: user?.id })
	}
	return (
		<>
			<AppContent>
				<PageHeader
					onBack={() => navigator(-1)}
					title="Users"
					extra={[
						<Button
							onClick={() => setUiState({ ...uiState, createModal: true })}
							size="large"
							type="primary">
							Create
						</Button>,
					]}
				/>
				<Users
					onSelectId={(id: string) => {
						navigator(Paths.Users + id)
					}}
				/>
			</AppContent>
			<Modal
				bodyStyle={{ overflowY: "auto", maxHeight: "80vh" }}
				footer={null}
				title="Create User"
				visible={uiState.createModal}
				onCancel={() => setUiState({ ...uiState, createModal: false })}>
				<UserCreateFrom
					onSubmit={onSubmitHandler}
					loading={registerIndividualMutation.isLoading}
					initialValue={{
						name: "",
						email: "",
						designation: "",
						company: "",
						password: "",
						address: "",
						number: "",
					}}
				/>
			</Modal>
		</>
	)
}
export default React.memo(UsersPage)
