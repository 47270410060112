import { FaAngleLeft } from "react-icons/fa"
import React from "react"

interface IFProps {
	mask?: string
	body?: string
}

export const MobileSMSUi: React.FC<IFProps> = ({ mask, body }) => {
	return (
		<div
			className="message-module-right-sidebar-mobile-ui"
			style={{ marginTop: 50 }}>
			<div className="mobilescreen">
				<div className="speaker"></div>
				<div className="maincontainer">
					<div className="mainscreen">
						<div className="mobile-ui-single-msg">
							<div className="title">
								<h4>
									<FaAngleLeft /> {mask}
								</h4>
							</div>
							{body ? (
								<div className="sngl-msg">
									{/* <span>13 Jun 2020 12:00 AM</span> */}
									<p>{body}</p>
								</div>
							) : (
								""
							)}
						</div>
					</div>
				</div>
				<div className="controlder"></div>
			</div>
		</div>
	)
}
