import { ARE_YOU_SURE, MASK_ASSIGN, MASK_LIST } from "@shared/constant"
import { Button, Modal, Space } from "antd"
import {
	CommonTitle,
	DebounceSearchInputField,
	Purify,
} from "@shared/components"
import React, { useState } from "react"
import {
	useSMSMaskUserDelete,
	useSmsMaskFilterWithUser,
	useUseMaskAssign,
	useUserMasks,
} from "@shared/services"

import { AiOutlineClose } from "react-icons/ai"
import { BiPlus } from "react-icons/bi"
import { MaskingTypes } from "@shared/enums"
import { isEmpty } from "lodash"
interface IFProps {
	userId: string
}
const UserMaskAssign: React.FC<IFProps> = ({ userId }) => {
	const [searchTerm, setSearchTerm] = useState("")
	const userAlreadyAssignMasks = useUserMasks({
		options: {
			maskingType: MaskingTypes.all,
		},
		id: userId,
	})
	const userAvailableMask = useSmsMaskFilterWithUser({
		options: {
			take: 10,
			page: 1,
			user: userId,
			searchTerm,
			maskingType: MaskingTypes.all,
		},
	})
	const useMaskAssignMutation = useUseMaskAssign({})
	const assignSubmit = (id: any) => {
		Modal.confirm({
			title: ARE_YOU_SURE,
			onOk: () =>
				useMaskAssignMutation.mutateAsync({
					user: userId,
					smsMasks: [id],
				}),
		})
	}
	const smsMaskUserDelete = useSMSMaskUserDelete({})
	const unAssignSubmit = (id: any) => {
		Modal.confirm({
			title: ARE_YOU_SURE,
			onOk: () => smsMaskUserDelete.mutate([id] as any),
		})
	}
	return (
		<>
			<Space
				style={{
					display: "flex",
					justifyContent: "space-between",
					alignItems: "flex-end",
				}}>
				<CommonTitle title={MASK_ASSIGN} />
			</Space>
			<Space
				style={{
					display: "flex",
					justifyContent: "center",
					marginTop: 30,
					marginBottom: 30,
				}}>
				<DebounceSearchInputField
					style={{ maxWidth: 270 }}
					onChange={(e: any) => {
						setSearchTerm(e)
					}}
				/>
			</Space>
			<Purify
				hide="empty"
				loading={userAvailableMask.isLoading}
				empty={isEmpty(userAvailableMask?.data?.data?.data)}>
				{userAvailableMask?.data?.data?.data?.map((mask: any, i: any) => (
					<Button
						onClick={() => assignSubmit(mask.id)}
						size="large"
						type="primary"
						className="border-2  m-1 inline-flex items-center bg-blue-300 border-none">
						<BiPlus className="mr-5" />
						{mask?.mask}
					</Button>
				))}
			</Purify>
			<CommonTitle title={MASK_LIST} className="my-7" />
			<Purify
				loading={userAlreadyAssignMasks.isLoading}
				empty={isEmpty(userAlreadyAssignMasks?.data?.data?.data)}>
				{userAlreadyAssignMasks?.data?.data?.data?.map(
					(mask: any, i: number) => (
						<Button
							onClick={() => unAssignSubmit(mask.id)}
							type="primary"
							size="large"
							className="inline-flex m-1 items-center bg-green-500 border-none">
							<AiOutlineClose className="cursor-pointer mr-5" />
							{mask?.smsMask?.mask}
						</Button>
					)
				)}
			</Purify>
		</>
	)
}
export default UserMaskAssign
