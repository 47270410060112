import React from "react"
import { Typography } from "antd"

const { Title, Text } = Typography
const TermsAndCondition = () => {
	const blkSpace = { marginTop: 50 }
	return (
		<>
			<Title level={5}>
				SERVICE PORTAL (DIGITAL COMMUNICATIONS PLATFORM) TERMS AND CONDITIONS
			</Title>
			<Text>
				These terms and conditions herein stipulated govern the provision of the
				services of the Digital Communication Platform by Pine Solutions Limited
				(“PSL”). Appropriate Completion of this application form and the
				submission of the required documents herein indicate that the Client has
				read, understood and accepted these terms and conditions
			</Text>
			<Title level={5}>1. DEFINITIONS</Title>
			<Text>
				Account means the Client’s user profile where the credit amount is
				available in the account of the Client with PSL. Advertisement means a
				promotional and creative programme and/or announcement specifically
				designed for mobile telecommunications devices, such as cellular phones.
				Client means an individual over 18 years of age or a company whose
				application to subscribe for the Digital Communication Platform has been
				accepted. Communication Charges means the rates and charges payable by
				the Client in connection with the usage of the Digital Communication
				Platform, calculated based upon Short Message Services (SMS), Multimedia
				Message Services (MMS), Unstructured Supplementary Service Data (USSD),
				Insertions, Interactive Voice Response (IVR), In-App Communication,
				Banner Communication and/or other Communication solutions disseminated
				by PSL in the PSL/Other Network on behalf of the Client. Content means
				all data, information, material and content, including but not limited
				to text, pictures, photographs, software, video, music, sound and
				graphics contained in the Advertisements. PSL Network means PSL’s Mobile
				Telecommunications Network service providers. PSL Services means the
				telecommunication services, including the Digital Communication
				Platform, offered by PSL to its Subscribers. Digital Communication
				Platform means the service provided by PSL to Clients to run
				‘self-service campaigns’ which are handled by the Clients themselves
				and/or ‘managed campaigns’ carried out by the PSL staff on behalf of
				Clients targeting Subscribers. Insertions means Content incorporated
				into missed call alerts, budget SMS, end call notifications and any
				other such PSL Services offered to Subscribers on a free of charge
				basis. Parties means PSL and the Client, and party means either of them.
				Subscriber means a person over 18 years of age who has registered to
				subscribe for the PSL Services.
			</Text>
			<Title level={5} style={blkSpace}>
				2. PROHIBITED CONTENT
			</Title>
			<Text style={txtStyle}>
				<b>2.1.</b> All Advertisements/Content and/or Communication related
				programmes submitted to PSL Communication Platform shall not include but
				limited to the following document;
			</Text>
			<Text style={txtStyle}>
				<b>2.1.2.</b> infringe the intellectual property rights of any third
				party;
			</Text>
			<Text style={txtStyle}>
				<b>2.1.3.</b> contain personal testimonial with specific reference to
				the character of a person;
			</Text>
			<Text style={txtStyle}>
				<b>2.1.4.</b> contain anything which clearly offends against generally
				prevailing community standards relating to decency;
			</Text>
			<Text style={txtStyle}>
				<b>2.1.5.</b> not libel, defame, cause injury to, invade the privacy of
				or otherwise infringe or violate the rights of any person or third
				party;
			</Text>
			<Text style={txtStyle}>
				<b>2.1.6.</b> contain false or unwarranted claims for any products or
				services or any defamatory statements;
			</Text>
			<Text style={txtStyle}>
				<b>2.1.7.</b> include any statement, picture, audio or video that
				promotes the services of PSL’s competitors.
			</Text>
			<Text style={txtStyle}>
				PSL reserves the right to at its sole discretion regard any
				Advertisement or Content as prohibited content and refuse to disseminate
				such Advertisement.
			</Text>

			<Title level={5} style={blkSpace}>
				3 RIGHTS AND RESPONSIBILITIES OF PSL
			</Title>
			<Text style={txtStyle}>
				<b>3.1.</b> PSL reserves the right to:
				<Text style={txtStyle}>
					<b>3.1.1</b>reject any application or Advertisement submitted for the
					Digital Communication Platform for any reason whatsoever without
					assigning any reason and without any liability;
				</Text>
				<Text style={txtStyle}>
					<b>3.1.2.</b>
					make any alteration to the Digital Communication Platform and such
					alteration shall be informed to the Client in writing prior to
					dissemination. PSL shall not be liable for any loss or inconvenience
					caused to the Client resulting therefrom;
				</Text>
				<Text style={txtStyle}>
					<b>3.1.3.</b>
					vary, add to or amend the terms and conditions applicable to the
					Digital Communication Platform;
				</Text>
				<Text style={txtStyle}>
					<b>3.1.4.</b>
					at any time, share their database/ specific Client information with
					any one of its subsidiaries and/or holding companies in order to
					provide an integrated service to the Subscribers and/or any Government
					authority in connection with any criminal investigation or for the
					purpose of any criminal proceedings
				</Text>
			</Text>
			<Title level={5} style={blkSpace}>
				3 RIGHTS AND RESPONSIBILITIES OF PSL
			</Title>

			<Text style={txtStyle}>
				<b> 3.2.</b>
				PSL shall at its sole discretion be responsible for the implementation
				and maintenance of the technical infrastructure to allow Subscribers to
				receive the Advertisements.
			</Text>
			<Text style={txtStyle}>
				<b> 3.3.</b>
				PSL shall not be liable to the Client and/or any third party for any
				loss, damage or injury caused to or suffered by a person or property,
				whether it be direct, indirect, special, consequential, loss of business
				revenue or profits, or of any nature suffered by any Client or any
				person authorized by any Client in relation to the Digital Communication
				Platform.
			</Text>
			<Title level={5} style={blkSpace}>
				4. RESPONSIBILITIES OF THE CLIENT
			</Title>

			<Text style={blkSpace}>4. RESPONSIBILITIES OF THE CLIENT</Text>

			<Text style={txtStyle}>
				<b>4.1.</b> not use the Digital Communication Platform to transmit
				Prohibited Content as set out in Clause 2 herein
			</Text>
			<Text style={txtStyle}>
				<b> 4.2.</b> not use the Digital Communication Platform to commit acts
				of terror, immoral, unauthorised or unlawful activities and/or for any
				improper, unlawful or abusive purpose and/or to send obscene or
				threatening messages and/or to defame, cause injury to, invade the
				privacy of or otherwise infringe or violate the rights of any Subscriber
				or third party;
			</Text>
			<Text style={txtStyle}>
				<b> 4.3.</b> keep all Subscriber information confidential and shall not
				use such information for any other purpose except for the purpose of
				fulfilling its obligations hereunder;
			</Text>
			<Text style={txtStyle}>
				<b>4.4.</b> strictly ensure that Advertisements disseminated as SMS
				and/or MMS through the Digital Communication Platform are ONLY used to
				contact Subscribers and/or any third parties who have expressly
				consented to be contacted via the Digital Communication Platform. The
				Client agrees that PSL may discontinue the Client from using the Digital
				Communication Platform for breaching this Clause;
			</Text>
			<Text style={txtStyle}>
				<b>4.5.</b> ensure that each and every Advertisement/Content transmitted
				through the Digital Communication Platform is approved and monitored by
				PSL prior to such release;
			</Text>
			<Text style={txtStyle}>
				<b> 4.6.</b> bear all stamp duty, service tax charges and any other cost
				or charge imposed by law from time to time with regard to the usage of
				the Digital Communication Platform;
			</Text>
			<Text style={txtStyle}>
				<b> 4.7.</b> not resell, transfer and/or reassign the Advertisement
				space in any circumstances and/or use the Digital Communication Platform
				for any other purpose than that provided hereunder unless otherwise with
				the prior written consent of PSL;
			</Text>
			<Text style={txtStyle}>
				<b> 4.8.</b> not use, reproduce, and/or display any trademarks, service
				marks, logos and brand names of PSL for any other promotional or
				Communication material without the prior written consent of PSL;
			</Text>
			<Text style={txtStyle}>
				<b> 4.9.</b> inform PSL in writing of any changes in any information
				provided to PSL.
			</Text>

			<Text style={blkSpace}>5. PAYMENT TERMS</Text>

			<Text style={txtStyle}>
				<b>5.1.</b> Payment for the Digital Communication Platform shall be by
				Credit Card or Bank to Bank Transfer or Cash deposit to PSL Bank A/C or
				PO or Cheque payment to PSL.
			</Text>
			<Text style={txtStyle}>
				<b>5.2</b>
				The maximum amount the Client may have in the Account at any one time is
				TK. 1,000,000.00 and any balance in the Account shall not be refundable
				under any circumstances whatsoever save and except if the Digital
				Communication Platform is permanently discontinued
			</Text>
			<Text style={txtStyle}>
				<b> 5.3.</b>
				Any unutilized amount remaining in the Client’s Account after the expiry
				of One (1) year from the date the Account was last credited will be
				forfeited and will not be available for acquiring further or additional
				services from the Digital Communication Platform after that date.
			</Text>
			<Text style={txtStyle}>
				<b>5.4.</b>
				The Communication Charges may be varied by PSL from time to time and
				such variation shall be informed to the Client in writing within a
				reasonable time period in advance.
			</Text>
			<Text style={txtStyle}>
				<b>5.5. </b>
				The Client shall not be able to access the services provided by the
				Digital Communication Platform without a sufficient balance in the
				Account.
			</Text>

			<Title level={5} style={blkSpace}>
				6. INDEMNIFICATION
			</Title>

			<Text style={txtStyle}>
				<b>6.1.</b> Client hereby agrees to defend, settle and pay damages on
				behalf of PSL and its subsidiaries with regard to any and all claims,
				actions, liabilities, losses, expenses, damages, and costs (including,
				without limitation, reasonable attorneys' fees) that may at any time be
				incurred by any of them by reason of any claims, suits or proceedings
				made by a third party for libel, defamation, violation of right of
				privacy or publicity, breach of contract, copyright infringement,
				trademark infringement or other infringement of any third party right,
				fraud, false Communication, misrepresentation, product liability or
				violation of any law, statute, ordinance, rule or regulation
			</Text>

			<Title level={5} style={blkSpace}>
				7. CONFIDENTIALITY
			</Title>

			<Text style={txtStyle}>
				<b>7.1.</b> Each Party hereto shall use its best efforts to keep in
				strict confidence, and shall bind all of its employees and agents to
				keep in strict confidence, all commercial and technical information in
				whatever form acquired by it (whether directly or indirectly) from or
				concerning the other Party or in connection with the performance of the
				Agreement (hereinafter called "Confidential Information"). No Party
				shall utilize such Confidential Information or at any time disclose any
				Confidential Information to any third party for any purposes other than
				those contemplated herein
			</Text>
			<Text style={txtStyle}>
				<b> 7.2.</b> The following information shall be excluded from the
				foregoing scope of Confidential Information:
				<Text style={txtStyle}>
					<b>7.2.1. </b> information which at the time of disclosure is
					generally available to the public;
				</Text>
				<Text style={txtStyle}>
					<b>7.2.2.</b> information which after disclosure becomes generally
					available to the public through no fault of the receiving party;
				</Text>
				<Text style={txtStyle}>
					<b>7.2.3.</b> information which the receiving party can show was in
					its possession prior to disclosure and which was not acquired directly
					or indirectly from the other Party;
				</Text>
				<Text style={txtStyle}>
					<b>7.2.4.</b> information which the receiving party can show was
					received by it after the time of disclosure from any third Party
					without any obligation of confidentiality and which was not acquired
					directly or indirectly from the other Party. The confidentiality
					obligations set out in this Clause shall survive the termination of
					this Agreement; or
				</Text>
				<Text style={txtStyle}>
					<b>7.2.5.</b> Information which the Party concerned shall be compelled
					to divulge if required by Law.
				</Text>
			</Text>
			<Text style={txtStyle}>
				<b>7.3. </b> The Subscriber information shall be the sole and exclusive
				property of PSL and the Client shall not in any event whatsoever derive
				any right or license to use such information for such other purpose
				other than as specifically set out herein.
			</Text>

			<Title level={5} style={blkSpace}>
				8. FORCE MAJEURE
			</Title>

			<Text style={txtStyle}>
				<b> 8.1.</b> If either Party is temporarily rendered unable, wholly or
				in part, by Force Majeure to perform its duties or accept performance by
				the other Party under this Agreement it is agreed that the affected
				Party shall give notice to the other Party with immediate effect giving
				full particulars of such Force Majeure.
			</Text>
			<Text style={txtStyle}>
				<b> 8.2.</b> The duties of such Party as are affected by such Force
				Majeure shall, with the approval of the other Party, be suspended during
				the period of disability so caused, but for no longer period, and such
				cause shall be removed with all reasonable dispatch.
			</Text>
			<Text style={txtStyle}>
				<b>8.3.</b>
				The term "Force Majeure" as employed herein shall mean act of God,
				strikes, lockouts, industrial disturbances, war, blockades,
				insurrections, riots, epidemics, civil disturbances, explosions, fire,
				floods, earthquakes, storms, lightning and any other causes similar to
				the kind herein enumerated which are beyond the control of any Party and
				which by the exercise of due care and diligence neither Party is unable
				to overcome.
			</Text>

			<Title level={5} style={blkSpace}>
				9. MISCELLANEOUS
			</Title>

			<Text style={txtStyle}>
				<b>9.1.</b> In the event of a dispute or difference in connection with
				the usage of the Digital Communication Platform, PSL and the Client
				shall use their best endeavours to resolve such dispute or difference
				amicably. Failing amicable resolution of such dispute or difference by
				the Parties, the dispute or difference shall then be finally resolved by
				reference to the courts of Law of Bangladesh.
			</Text>
			<Text style={txtStyle}>
				<b>9.2.</b> All conditions, warranties and representations implied by
				law in relation to the Digital Communication Platform and PSL Network
				are excluded to the extent permitted by law and except as expressly
				provided in these terms and conditions.
			</Text>
			<Text style={txtStyle}>
				<b> 9.3.</b> These terms and conditions shall be governed by the Laws of
				Bangladesh and subject to the exclusive jurisdiction of the courts of
				Bangladesh.
			</Text>
		</>
	)
}

export default TermsAndCondition
const txtStyle = { display: "block", marginLeft: 10, marginBottom: 10 }
