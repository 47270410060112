import {
	CoreAxiosInstanceV2,
	MutationConfig,
	QueryConfig,
	queryClient,
} from "@shared/config"
import { useMutation, useQuery } from "react-query"

import { IBaseQuery } from "./../interfaces/base.interfaces"
import { ICreateSmsPackage } from "@shared/interfaces"
import { concatQuery } from "@shared/utils"

const END_POINT = "/smsPackages/"

export interface IFilterSmsPackages extends IBaseQuery {
	user?: string
}

export const SmsPackageServiceV2 = {
	NAME: "SmsPackageServiceV2",
	create(payload: ICreateSmsPackage): any {
		return CoreAxiosInstanceV2.post(END_POINT, payload)
	},
	filter(options: IFilterSmsPackages): any {
		return CoreAxiosInstanceV2.get(`${END_POINT}filter?${concatQuery(options)}`)
	},
	delete(id: string) {
		return CoreAxiosInstanceV2.delete(`${END_POINT}${id}`)
	},
}

interface CreateSmsPackage {
	config?: MutationConfig<typeof SmsPackageServiceV2.create>
}
export const useCreateSmsPackage = ({ config }: CreateSmsPackage) => {
	const INVALID_QUERY_KEY = `${SmsPackageServiceV2.NAME}${SmsPackageServiceV2.filter.name}`
	return useMutation({
		...config,
		mutationFn: SmsPackageServiceV2.create,
		onSettled: () => {
			queryClient.invalidateQueries(INVALID_QUERY_KEY)
		},
	})
}

interface SmsPackages {
	config?: QueryConfig<typeof SmsPackageServiceV2.filter>
	options?: IFilterSmsPackages
}
export const useSmsPackages = ({ config, options }: SmsPackages) => {
	const KEY = `${SmsPackageServiceV2.NAME}${SmsPackageServiceV2.filter.name}`
	return useQuery({
		...config,
		queryKey: [KEY, Object.values(options)],
		queryFn: () => SmsPackageServiceV2.filter(options),
	})
}

interface SMSPackageDelete {
	config?: MutationConfig<typeof SmsPackageServiceV2.delete>
}
export const useSmsPackageDelete = ({ config }: SMSPackageDelete) => {
	const INVALID_QUERY_KEY = `${SmsPackageServiceV2.NAME}${SmsPackageServiceV2.filter.name}`
	return useMutation({
		...config,
		mutationFn: SmsPackageServiceV2.delete,
		onSettled: () => {
			queryClient.invalidateQueries(INVALID_QUERY_KEY)
		},
	})
}
