import { Button, Checkbox, Form, Input, PageHeader, message } from "antd"
import {
	DebounceMaskSelectField,
	DebounceUserSelectField,
} from "@shared/components"
import React, { useState } from "react"
import {
	useInterCloudUsersById,
	useUpdateInterCloudUser,
} from "@shared/services/intercloud-users.service"
import { useNavigate, useParams } from "react-router-dom"

import { AppContent } from "@shared/layout"

const UpdateInterCloudUser = () => {
	const [form] = Form.useForm()
	const [businessLeadActive, setBusinessLeadActive] = useState(false)
	const navigator = useNavigate()
	let { id } = useParams()
	const [user, setUser] = useState(null)
	const [mask, setMask] = useState(null)
	const { isLoading } = useInterCloudUsersById({
		id,
		config: {
			onSuccess: (val) => {
				if (val.data.success) {
					setUser(val.data.payload.userDetails)
					setMask(val.data.payload.maskName)
					setBusinessLeadActive(val?.data?.payload?.isBusinessLeadActive)
					form.setFieldsValue({
						intercloudNumber: val?.data?.payload?.intercloudNumber,
						smsContent: val?.data?.payload?.smsContent,
						isBusinessLeadActive: val?.data?.payload?.isBusinessLeadActive,
					})
				}
			},
		},
	})

	const updateInterCloudUserMutation = useUpdateInterCloudUser({
		config: {
			onSuccess: (val) => {
				if (val.data.success) {
					message.success("Updated Successfully")
					window.location.pathname = "/mca"
				}
			},
		},
	})
	const onSubmit = (val) => {
		if (businessLeadActive) {
			if (!user?.id) {
				message.error("Invalid user")
			} else if (!mask?.smsMask?.mask) {
				message.error("Invalid Mask")
			} else {
				const payload = {
					intercloudNumber: val?.intercloudNumber,
					user: user?.id,
					smsContent: val?.smsContent,
					maskName: mask?.smsMask?.mask,
					isBusinessLeadActive: true,
				}
				updateInterCloudUserMutation.mutate({
					id,
					payload,
				})
			}
		} else {
			const payload = {
				intercloudNumber: val?.intercloudNumber,
				isBusinessLeadActive: false,
			}
			updateInterCloudUserMutation.mutate({
				id,
				payload,
			})
		}
	}
	return (
		<AppContent>
			<PageHeader
				onBack={() => navigator(-1)}
				title="Update InterCloud Users"
				extra={[]}
			/>
			{isLoading ? (
				"Loading..."
			) : (
				<Form
					name="basic"
					form={form}
					initialValues={{
						intercloudNumber: "",
						smsContent: "",
						isBusinessLeadActive: false,
					}}
					layout="vertical"
					onFinish={onSubmit}>
					{businessLeadActive ? (
						<div className="grid grid-cols-2 gap-2">
							<div className="my-3">
								<DebounceUserSelectField
									onChange={(v: any) => {
										setUser(v.value)
									}}
								/>
							</div>
							<div className="my-3">
								<DebounceMaskSelectField
									userId={user?.id}
									disabled={!user}
									label="Mask Name"
									onChange={(v) => {
										setMask(v)
									}}
								/>
							</div>
						</div>
					) : (
						""
					)}
					<Form.Item
						label="InterCloud Number"
						name="intercloudNumber"
						rules={[
							{
								required: true,
								message: "Please input your interCloud Number!",
							},
						]}>
						<Input size="large" placeholder="Enter Your interCloud Number" />
					</Form.Item>
					{businessLeadActive ? (
						<Form.Item
							label="Sms Content"
							name="smsContent"
							rules={[
								{
									required: true,
									message: "Please input your smsContent Number!",
								},
							]}>
							<Input.TextArea
								size="large"
								placeholder="Enter Your smsContent"
							/>
						</Form.Item>
					) : (
						""
					)}
					<Form.Item name="isBusinessLeadActive">
						<Checkbox
							value={businessLeadActive}
							checked={businessLeadActive}
							onChange={(v) => {
								setBusinessLeadActive(v.target.checked)
							}}>
							Business Lead
						</Checkbox>
					</Form.Item>
					<Form.Item>
						<Button
							loading={updateInterCloudUserMutation.isLoading}
							type="primary"
							htmlType="submit">
							Submit
						</Button>
					</Form.Item>
				</Form>
			)}
		</AppContent>
	)
}
export default UpdateInterCloudUser
