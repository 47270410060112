import { Button, Form, Input } from "antd"

import React from "react"

interface IInitialValue {
	name?: string
	email?: string
	designation?: string
	company?: string
	password?: string
	address?: string
	number?: string
}
interface IFProps {
	initialValue: IInitialValue
	loading: boolean
	onSubmit: (val: IInitialValue) => void
}
const UserCreateFrom: React.FC<IFProps> = ({
	initialValue,
	onSubmit,
	loading,
}) => {
	return (
		<Form
			name="basic"
			initialValues={initialValue}
			layout="vertical"
			onFinish={onSubmit}>
			<Form.Item
				label="Email"
				name="email"
				rules={[{ required: true, message: "Please input your email!" }]}>
				<Input size="large" type="email" placeholder="Enter Your email" />
			</Form.Item>
			<Form.Item
				label="Password"
				name="password"
				rules={[{ required: true, message: "Please input your password!" }]}>
				<Input.Password size="large" placeholder="Enter Your password" />
			</Form.Item>
			<Form.Item
				label="Name"
				name="name"
				rules={[{ required: true, message: "Please input your name!" }]}>
				<Input size="large" placeholder="Enter Your name" />
			</Form.Item>
			<Form.Item
				label="Designation"
				name="designation"
				rules={[{ required: true, message: "Please input your designation!" }]}>
				<Input size="large" placeholder="Enter Your designation" />
			</Form.Item>
			<Form.Item
				label="Company"
				name="company"
				rules={[{ required: true, message: "Please input your company!" }]}>
				<Input size="large" placeholder="Enter Your company" />
			</Form.Item>
			<Form.Item
				label="Address"
				name="address"
				rules={[{ required: true, message: "Please input your address!" }]}>
				<Input size="large" placeholder="Enter Your address" />
			</Form.Item>
			<Form.Item
				label="Number"
				name="number"
				rules={[{ required: true, message: "Please input your number!" }]}>
				<Input size="large" type="number" placeholder="Enter Your number" />
			</Form.Item>
			<Form.Item>
				<Button loading={loading} type="primary" htmlType="submit">
					Submit
				</Button>
			</Form.Item>
		</Form>
	)
}
export default UserCreateFrom
