import { Navigate, RouteObject } from "react-router"

import CMPActivityPage from "./CMPActivityPage"
import { Paths } from "@shared/constant/Paths"
import React from "react"

export const PreferenceRoutes: RouteObject[] = [
	{ path: "", element: <Navigate to={Paths.TransactionsLogPage} /> },
	{ path: "cmp", element: <CMPActivityPage /> },
]
