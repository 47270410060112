import { Button, Form, Input, Modal } from "antd"
import { useUpdateUser, useUser } from "@shared/services"

import { ARE_YOU_SURE_TO_UPDATE_PROFILE_INFO } from "@shared/constant"
import { Purify } from "@shared/components"
import React from "react"
import { getSession } from "@shared/utils"

const UpdateUserProfile = () => {
	const user: any = getSession()
	const userQuery = useUser({ id: user?.id })

	const updateUserMutation = useUpdateUser({})

	//! submit
	const onSubmitHandler = (value: any) => {
		const normalize = {
			...value,
			status: "ACTIVE",
		}
		Modal.confirm({
			title: ARE_YOU_SURE_TO_UPDATE_PROFILE_INFO,
			onOk: () =>
				updateUserMutation.mutate({ payload: normalize, id: user?.id }),
		})
	}
	return (
		<Purify empty={false} loading={userQuery.isLoading}>
			<Form
				name="basic"
				initialValues={{
					name: userQuery?.data?.data?.data?.userInfo?.name,
					email: userQuery?.data?.data?.data?.email,
					designation: userQuery?.data?.data?.data?.userInfo?.designation,
					company: userQuery?.data?.data?.data?.userInfo?.company,
					address: userQuery?.data?.data?.data?.userInfo?.address,
					number: userQuery?.data?.data?.data?.userInfo?.number,
				}}
				layout="vertical"
				onFinish={onSubmitHandler}>
				<Form.Item
					label="Name"
					name="name"
					rules={[{ required: true, message: "Please input your name!" }]}>
					<Input size="large" placeholder="Enter Your name" />
				</Form.Item>

				<Form.Item
					label="Email"
					name="email"
					rules={[{ required: true, message: "Please input your email!" }]}>
					<Input size="large" placeholder="Enter Your email" />
				</Form.Item>
				<Form.Item
					label="Designation"
					name="designation"
					rules={[
						{ required: true, message: "Please input your designation!" },
					]}>
					<Input size="large" placeholder="Enter Your designation" />
				</Form.Item>
				<Form.Item
					label="Company"
					name="company"
					rules={[{ required: true, message: "Please input your company!" }]}>
					<Input size="large" placeholder="Enter Your company" />
				</Form.Item>
				<Form.Item
					label="Address"
					name="address"
					rules={[{ required: true, message: "Please input your address!" }]}>
					<Input size="large" placeholder="Enter Your address" />
				</Form.Item>
				<Form.Item
					label="Number"
					name="number"
					rules={[{ required: true, message: "Please input your number!" }]}>
					<Input size="large" placeholder="Enter Your number" />
				</Form.Item>

				<Form.Item>
					<Button loading={false} type="primary" htmlType="submit">
						Submit
					</Button>
				</Form.Item>
			</Form>
		</Purify>
	)
}

export default UpdateUserProfile
