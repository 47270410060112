//Activation
export const uiEventHandler = () => {
	dropDownToggleActive()
	sideBarTogglerActive()
}
//Dropdown toggler
const dropDownToggleActive = () => {
	const dropDownTrigger = document.querySelectorAll(".custom-dropdown-trigger")
	const dropDownContent = document.querySelectorAll(".custom-dropdown-content")
	const pxOverlay = document.querySelector(".dropdown-overlay")
	if (dropDownTrigger) {
		for (let i = 0; i < dropDownTrigger.length; i++) {
			const getElem = dropDownTrigger[i] as HTMLElement
			getElem.onclick = function (e: any) {
				e.currentTarget.nextSibling.classList.toggle("active")
				if (pxOverlay) {
					pxOverlay.classList.toggle("active")
				}
			}
		}
	}
	if (pxOverlay) {
		const getElem = pxOverlay as HTMLElement
		getElem.onclick = function (e: any) {
			e.currentTarget.classList.remove("active")
			dropDownContent.forEach((elem) => {
				return elem.classList.remove("active")
			})
		}
	}
}

//Sidebar toggler
const sideBarTogglerActive = () => {
	const layoutSidebarWrapper = document.querySelector(".layout-sidebar")
	const rightSidebar = document.querySelector(".right-sidebar-wrapper")
	const layoutContent = document.querySelector(".layout-content")
	const leftSidebarToggler = document.querySelector(".left-sidebar-toggler-btn")
	const rightSidebarToggler = document.querySelector(
		".right-sidebar-toggler-btn"
	)
	const rightSideOffCanvasOverlay = document.querySelector(
		".right-sidebar-canvas-overlay"
	)
	const sidebarNavWrapper = document.querySelector(".left-sidebar-navigation")
	const navSubMenuWrapper = document.querySelector(
		".left-sidebar-sub-navigation"
	)
	const navOptionsWrapper = document.querySelectorAll(".left-sidebar-nav-item")
	const navOptionsSubMenuWrapper = document.querySelectorAll(
		".campaign-sub-menu"
	)
	const sidebarCloseToggler = document.querySelector(
		".left-sidebar-sub-navigation"
	)
	const leftSubMenuOverlay = document.querySelector(
		".left-sidebar-sub-menu-overlay"
	)
	leftSubMenuOverlay?.addEventListener("click", (e: any) => {
		navSubMenuWrapper?.classList.remove("active")
		leftSubMenuOverlay?.classList.remove("active")
	})
	sidebarCloseToggler?.addEventListener("click", (e: any) => {
		navSubMenuWrapper?.classList.remove("active")
		leftSubMenuOverlay?.classList.remove("active")
	})
	//===Left sidebar toggler
	leftSidebarToggler?.addEventListener("click", (e: any) => {
		if (navSubMenuWrapper?.className === "left-sidebar-sub-navigation active") {
			navSubMenuWrapper?.classList.remove("active")
			return
		} else {
			layoutSidebarWrapper?.classList.toggle("active")
			layoutContent?.classList.toggle("active")
		}
	})
	//===Right sidebar toggler
	rightSidebarToggler?.addEventListener("click", (e: any) => {
		rightSidebar?.classList.toggle("active")
		rightSideOffCanvasOverlay?.classList.toggle("active")
		rightSidebarToggler.classList.toggle("active")
	})
	rightSideOffCanvasOverlay?.addEventListener("click", (e: any) => {
		rightSidebar?.classList.remove("active")
		rightSidebarToggler?.classList.remove("active")
		rightSideOffCanvasOverlay?.classList.remove("active")
	})

	//===Left sidebar Sub menu toggler using unique ID attribute
	const subMenuActive = (triggerElem: any, contentElem: any) => {
		const getTriggerElem = document.querySelector(triggerElem)
		const getContentElem = document.querySelector(contentElem)
		getTriggerElem?.addEventListener("click", function (e: any) {
			navSubMenuWrapper?.classList.add("active")
			leftSubMenuOverlay?.classList.add("active")
			if (
				navSubMenuWrapper?.className === "left-sidebar-sub-navigation active"
			) {
				sidebarNavWrapper?.classList.add("sub-meu-active")
			}
			getContentElem.classList.add("active")
			e.currentTarget.classList.add("active")
			navOptionsWrapper.forEach((elem: any) => {
				elem.classList.remove("active")
				e.currentTarget.classList.add("active")
			})
			navOptionsSubMenuWrapper.forEach((elem: any) => {
				elem.classList.remove("active")
				getContentElem.classList.add("active")
			})
		})
	}

	//Left sidebar toggler activation
	subMenuActive("#nav-item-campaign", "#campaign-sub-menu")
	subMenuActive("#nav-item-sms", "#campaign-sub-sms")
	subMenuActive("#nav-item-voice", "#campaign-sub-voice")
	subMenuActive("#nav-item-reports", "#campaign-sub-reports")
	subMenuActive("#nav-item-billing", "#campaign-sub-billing")
	subMenuActive("#nav-item-manages", "#campaign-sub-manages")
	subMenuActive("#nav-item-automations", "#campaign-sub-automations")
	subMenuActive("#nav-item-reRoute", "#sms-sub-reRoute")
	subMenuActive("#nav-item-analytics", "#analytics-sub-menus")
}
