import { AppContent } from "@shared/layout"
import { PageHeader } from "antd"
import React from "react"
import TermsAndCondition from "../components/TermsAndCondition"
import { useNavigate } from "react-router-dom"

const TermsAndConditionPage = () => {
	const navigator = useNavigate()
	return (
		<>
			<AppContent>
				<PageHeader onBack={() => navigator(-1)} title="Terms & Conditions" />
				<TermsAndCondition />
			</AppContent>
		</>
	)
}

export default TermsAndConditionPage
