import { Button, Popconfirm, Table } from "antd"
import React, { Fragment, useState } from "react"
import { useDeleteSMSGateway, useSmsGateways } from "@shared/services"

import { DebounceSearchInputField } from "@shared/components"
import { ISmsGateway } from "@shared/interfaces"

const Gateways = () => {
	const deleteSMSGateway = useDeleteSMSGateway({})
	const [options, setOptions] = useState({
		page: 1,
		take: 10,
		searchTerm: "",
	})
	const smsGatewaysQuery = useSmsGateways({ options })
	const tableData: any = smsGatewaysQuery?.data?.data?.data?.map(
		(gateway: ISmsGateway) => ({
			username: gateway.username,
			operator: gateway.operator,
			originatingNumber: gateway.originatingNumber,
			prefixes: gateway?.smsGatewayPrefixes?.map((prefix: any, idx: number) => (
				<Fragment key={idx}>
					{prefix?.prefix?.name} <span>,</span>
				</Fragment>
			)),
			actions: (
				<Popconfirm
					title="Are you sure?"
					onConfirm={() => deleteSMSGateway.mutate(gateway.id)}
					okText="Yes"
					okButtonProps={{
						loading: deleteSMSGateway.isLoading,
					}}
					cancelText="No">
					<Button type="primary" danger>
						Delete
					</Button>
				</Popconfirm>
			),
		})
	)
	return (
		<>
			<DebounceSearchInputField
				style={{ maxWidth: 270, marginBottom: 20 }}
				onChange={(term) => {
					setOptions({
						...options,
						searchTerm: term,
					})
				}}
			/>
			<Table
				sticky
				scroll={{ x: 900 }}
				loading={smsGatewaysQuery.isLoading}
				columns={columns}
				dataSource={tableData}
				pagination={{
					onChange: (page: number) => {
						setOptions({
							...options,
							page,
						})
					},
					pageSize: smsGatewaysQuery?.data?.data?.take,
					total: smsGatewaysQuery?.data?.data?.total,
					showSizeChanger: false,
				}}
			/>
		</>
	)
}
export default Gateways
const columns: any = [
	{
		title: "Username",
		dataIndex: "username",
	},
	{
		title: "Operator",
		dataIndex: "operator",
	},
	{
		title: "Originating Number",
		dataIndex: "originatingNumber",
	},
	{
		title: "Prefixes",
		dataIndex: "prefixes",
	},
	{
		title: "Actions",
		dataIndex: "actions",
		align: "right",
	},
]
