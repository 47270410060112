import {
	CoreAxiosInstanceV2,
	MutationConfig,
	QueryConfig,
	queryClient,
} from "@shared/config"
import {
	IFilterSms,
	IInstantSms,
	ISMSForceSuccess,
	ISMSReRoute,
	ISmsOverviewForToday,
} from "@shared/interfaces"
import { useMutation, useQuery } from "react-query"

import { concatQuery } from "@shared/utils"

const END_POINT = "/sms/"

export const SmsServiceV2 = {
	NAME: "SmsService",
	smsOverviewForToday(options: ISmsOverviewForToday) {
		return CoreAxiosInstanceV2.get(
			`${END_POINT}smsOverviewForToday?${concatQuery(options)}			`
		)
	},
	smsOverview(options: IFilterSms) {
		return CoreAxiosInstanceV2.get(
			`${END_POINT}smsOverview?${concatQuery(options)}`
		)
	},
	sendInstantSms(payload: IInstantSms) {
		return CoreAxiosInstanceV2.post(`${END_POINT}sendInstantSms`, payload)
	},
	smsDetails(id: string) {
		return CoreAxiosInstanceV2.get(`${END_POINT}smsDetails/${id}`)
	},

	smsReRoute(options: ISMSReRoute) {
		return CoreAxiosInstanceV2.get(
			`${END_POINT}smsReRoute?${concatQuery(options)}`
		)
	},
	smsForceSuccess(options: ISMSForceSuccess) {
		return CoreAxiosInstanceV2.get(
			`${END_POINT}smsForceSuccess?${concatQuery(options)}`
		)
	},
}

interface ReRoute {
	config?: QueryConfig<typeof SmsServiceV2.smsReRoute>
	options?: ISMSReRoute
}
export const useSMSReRoute = ({ config, options }: ReRoute = {}) => {
	const KEY = `${SmsServiceV2.NAME}${SmsServiceV2.smsReRoute.name}`
	return useQuery({
		...config,
		queryKey: [KEY, Object.values(options)],
		queryFn: () => SmsServiceV2.smsReRoute(options),
	})
}

interface ForceSuccess {
	config?: QueryConfig<typeof SmsServiceV2.smsForceSuccess>
	options?: ISMSForceSuccess
}
export const useSMSForceSuccess = ({ config, options }: ForceSuccess = {}) => {
	const KEY = `${SmsServiceV2.NAME}${SmsServiceV2.smsForceSuccess.name}`
	return useQuery({
		...config,
		queryKey: [KEY, Object.values(options)],
		queryFn: () => SmsServiceV2.smsForceSuccess(options),
	})
}

interface SmsOverviewForToday {
	config?: QueryConfig<typeof SmsServiceV2.smsOverviewForToday>
	options?: ISmsOverviewForToday
}

export const useSmsOverviewForToday = ({
	config,
	options,
}: SmsOverviewForToday = {}) => {
	const queryName = `${SmsServiceV2.NAME}${SmsServiceV2.smsOverviewForToday}`
	return useQuery({
		...config,
		queryKey: [queryName, Object.values(options)],
		queryFn: () => SmsServiceV2.smsOverviewForToday(options),
	})
}
interface SmsOverview {
	config?: QueryConfig<typeof SmsServiceV2.smsOverview>
	options?: IFilterSms
}

export const useSmsOverview = ({ config, options }: SmsOverview = {}) => {
	const queryName = `${SmsServiceV2.NAME}${SmsServiceV2.smsOverview.name}`
	return useQuery({
		...config,
		queryKey: [queryName, Object.values(options)],
		queryFn: () => SmsServiceV2.smsOverview(options),
	})
}
interface SendInstantSms {
	config?: MutationConfig<typeof SmsServiceV2.sendInstantSms>
}

export const useSendInstantSms = ({ config }: SendInstantSms) => {
	return useMutation({
		...config,
		mutationFn: SmsServiceV2.sendInstantSms,
		onSettled: () => {
			queryClient.invalidateQueries(
				`${SmsServiceV2.NAME}${SmsServiceV2.smsOverview}`
			)
		},
	})
}
interface SmsDetail {
	config?: QueryConfig<typeof SmsServiceV2.smsDetails>
	id?: string
}

export const useSmsDetail = ({ config, id }: SmsDetail = {}) => {
	const queryName = `${SmsServiceV2.NAME}${SmsServiceV2.smsDetails.name}`
	return useQuery({
		...config,
		queryKey: [queryName, id],
		queryFn: () => SmsServiceV2.smsDetails(id),
	})
}
